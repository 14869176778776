import React from "react";
import ChevronRightIcon from "mdi-react/ChevronRightIcon";
import EditIcon from "mdi-react/EditIcon";
import PlusIcon from "mdi-react/PlusIcon";
import CheckIcon from "mdi-react/CheckIcon";
import CloseCircleIcon from "mdi-react/CloseCircleIcon";
import DeleteIcon from "mdi-react/DeleteIcon";

import styles from "./ListItem.module.scss";
import classNames from "classnames";
import InlineHelp from "../InlineHelp/InlineHelp";

export const List = ({
  title,
  rightSideTitle,
  icon,
  children,
  className = "",
  noMargin,
  noTitle,
  noRadius,
  greenBorder,
}) => {
  const Icon = icon;
  const listClassNames = classNames(styles.list, {
    [styles.noMargin]: noMargin,
    [styles.noRadius]: noRadius,
    [styles.greenBorder]: greenBorder,
  });
  return (
    <div className={listClassNames}>
      {!noTitle && (
        <div className={styles.listTitleContainer}>
          <div className={styles.listTitle}>
            {icon && <Icon className={styles.listIcon} />}
            {title}
          </div>
          {rightSideTitle && (
            <div className={styles.listTitle}>{rightSideTitle}</div>
          )}
        </div>
      )}
      <div className={`${styles.listChildContainer} ${className}`}>
        {children}
      </div>
    </div>
  );
};

export const ListItem = ({
  component,
  children,
  left,
  right,
  onClick,
  editIcon,
  deleteIcon,
  to,
  className,
  href,
  noRight,
}) => {
  const Component = component || "div";
  const listItemClassNames = classNames(styles.listItem, {
    [styles.link]: to,
    [className]: className,
  });

  let rightContent = "";

  if (to) {
    rightContent = <ChevronRightIcon className={styles.listItemLinkIcon} />;
  }
  if (editIcon && onClick) {
    rightContent = (
      <button className={styles.button} onClick={onClick}>
        <EditIcon className={styles.buttonIcon} />
      </button>
    );
  }
  if (deleteIcon && onClick) {
    rightContent = (
      <button className={styles.button} onClick={onClick}>
        <DeleteIcon className={styles.buttonIcon} />
      </button>
    );
  }

  if (right) {
    rightContent = right;
  }

  return (
    <Component
      href={href}
      target={href && "_blank"}
      rel={href && "noopener noreferrer"}
      className={listItemClassNames}
      to={to}
      onClick={editIcon || deleteIcon ? null : onClick}
    >
      <div className={styles.left}>{left}</div>
      <div className={styles.middle}>{children}</div>
      {!noRight && <div className={styles.right}>{rightContent}</div>}
    </Component>
  );
};


export const ListCheckbox = ({ children, inputComponent,warning,flex, ...props }) => {

  const listCheckboxClassNames = classNames(styles.listCheckbox, {
    [styles.disabled]: props.disabled,
    [styles.grayCloseIcon]: props.grayCloseIcon,
    [styles.warning]: warning,
    [styles.flex]: flex,
    [styles.warning]: warning,
  });

  const Component = inputComponent || "input";
  return (
    <label className={listCheckboxClassNames}>
      <Component className={styles.listCheckboxInput} {...props} />
      <div className={styles.listCheckboxContent}>
        <div className={styles.listCheckboxIndicator}>
          <CheckIcon className={styles.listCheckboxIcon} />
        </div>
        <div className={styles.listCheckboxLabel}>{children}</div>
      </div>
    </label>
  );
};
export const ListAction = ({ children, onClick, red, to, component, icon }) => {
  const Component = component || "button";

  const listActionClassNames = classNames(styles.listAction, {
    [styles.red]: red,
  });

  let Icon = icon ? icon : PlusIcon;
  Icon = to ? ChevronRightIcon : PlusIcon;

  return (
    <Component className={listActionClassNames} onClick={onClick} to={to}>
      <Icon className={styles.listActionIcon} />
      {children}
    </Component>
  );
};

export const ListContent = ({ children, className, empty }) => {
  const listContentClassNames = classNames(styles.listContent, {
    [className]: className,
    [styles.empty]: empty,
  });

  return <div className={listContentClassNames}>{children}</div>;
};

export const ListForm = ({
  children,
  title,
  component = "div",
  cancelText = "Peruuta",
  submitText = "Tallenna",
  deleteText = "Poista",
  onClickCancel,
  onClickDelete,
  onClickSubmit,
  submitDisabled = false,
  empty,
  className,
}) => {
  const formClassNames = classNames(styles.form, {
    [styles.empty]: empty,
    [className]: className,
  });

  const Component = component;
  return (
    <Component className={formClassNames}>
      <div className={styles.formInputs}>
        {title && <div className={styles.formTitle}>{title}</div>}
        {children}
      </div>
      {onClickDelete && (
        <div className={styles.formDelete}>
          <button
            type="button"
            onClick={onClickDelete}
            className={styles.formDeleteButton}
          >
            {deleteText}
          </button>
        </div>
      )}
      <div className={styles.formActions}>
        {onClickCancel && (
          <button
            type="button"
            onClick={onClickCancel}
            className={styles.formActionsCancel}
          >
            {cancelText}
          </button>
        )}
        <button
          type="submit"
          onClick={onClickSubmit}
          disabled={submitDisabled}
          className={styles.formActionsSubmit}
        >
          {submitText}
        </button>
      </div>
    </Component>
  );
};

export const ListFootnote = ({ title, children }) => {
  return <InlineHelp title={title} children={children} list />;
};

import React from "react";
import InformationIcon from "mdi-react/InformationIcon";
import AccountSupervisorIcon from "mdi-react/AccountSupervisorIcon";
import AccountOffIcon from "mdi-react/AccountOffIcon";
import WhiteBalanceSunnyIcon from "mdi-react/WhiteBalanceSunnyIcon";
import CardTextIcon from "mdi-react/CardTextIcon";

import { useTranslation } from "react-i18next";
import { GET_CHILD } from "../../../../queries";
import { List, ListItem } from "../../ListItem/ListItem";
import ErrorMessage from "../../ErrorMessage/ErrorMessage";
import Link from "../../Link/Link";

import { Mutation, Query } from "react-apollo";
import gql from "graphql-tag";

import dayjs from "dayjs";
import "dayjs/locale/fi";

const AdditionalInformation = ({ child, staff }) => {
  let absences = null;
  let vacations = null;
  if (child.absences) absences = sortAbsences(child.absences);
  if (child.vacationDays) vacations = sortAbsences(child.vacationDays);

  const { t } = useTranslation();
  const { i18n } = useTranslation();

  if (i18n.language === "fi-FI") dayjs.locale("fi");

  return (
    <div>
      <Guardians
        childId={child.id}
        staff={staff}
        guardians={child.guardians ? child.guardians : undefined}
        t={t}
      />
      {staff && child.activePlacements && child.activePlacements.length > 0 && (
        <Contracts placements={child.activePlacements} t={t} />
      )}
      {staff && <OtherInfo age={child.age} childId={child.id} t={t} />}
      {staff && <Absences absences={absences} t={t} />}
      {staff && <Vacations vacations={vacations} t={t} />}
    </div>
  );
};

const sortAbsences = (absences) => {
  absences.sort((a, b) => {
    return new Date(a.date) - new Date(b.date);
  });

  let groupedAbsences = [];
  let groupIndex = 0;
  let intermediaryArray = [];

  const nextGroup = () => {
    groupedAbsences[groupIndex] = intermediaryArray;
    groupIndex++;
    intermediaryArray = [];
  };

  absences.forEach((a, i) => {
    intermediaryArray.push(a);

    if (i < absences.length - 1) {
      let nextDate = new Date(absences[i + 1].date);
      let currentDatePlusOne = new Date(a.date);
      currentDatePlusOne.setDate(currentDatePlusOne.getDate() + 1);

      if (currentDatePlusOne.getDay() === 6) {
        let nextMonday = new Date(currentDatePlusOne);
        nextMonday.setDate(currentDatePlusOne.getDate() + 2);

        if (nextDate.getTime() !== nextMonday.getTime()) {
          nextGroup();
        }
      } else {
        if (nextDate.getTime() !== currentDatePlusOne.getTime()) {
          nextGroup();
        }
      }
    } else {
      groupedAbsences[groupIndex] = intermediaryArray;
    }
  });

  return groupedAbsences;
};

const Guardians = ({ guardians, t, childId, staff }) => {
  if (guardians && guardians.length > 0) {
    return (
      <List
        title={t("Profile.AdditionalInformation.guardian_title")}
        icon={AccountSupervisorIcon}
      >
        {guardians.map((guardian, index) => (
          <ListItem key={index}>
            <div className="child-margins-y-8">
              <div className="size-medium weight-500">{guardian.name}</div>
              <Address
                guardian={guardian}
                staff={staff}
                childId={childId}
                t={t}
              />
              <PhoneNumbers
                phoneNumber={guardian.phoneNumber}
                workPhoneNumber={guardian.workPhoneNumber}
                t={t}
              />
            </div>
          </ListItem>
        ))}
      </List>
    );
  } else {
    return "";
  }
};

const Address = ({ t, childId, staff, guardian }) => {
  if (staff) {
    return (
      <Mutation mutation={CREATE_CHILD_IDENTITY_NUMBER_VIEW}>
        {(createChildAddressView, { data, loading, error }) => {
          const addAddressView = (values) => {
            createChildAddressView({
              variables: {
                childId: childId,
              },
            });
          };
          if (error) {
            return <ErrorMessage error={error} />;
          }
          if (loading) {
            return (
              <h4 className="weight-500">
                {t("Profile.AdditionalInformation.address")}
              </h4>
            );
          }
          if (data) {
            const guardians = data.createChildIdentityNumberView.guardians;
            const selectedGuardian = guardians.filter(
              (item) => item.id === guardian.id
            );
            const { street, postalCode, city } = selectedGuardian[0];
            return (
              <>
                <h4 className="weight-500">
                  {t("Profile.AdditionalInformation.address")}
                </h4>
                {street} {postalCode} {city}
              </>
            );
          } else {
            return (
              <div>
                <h4 className="weight-500">
                  {t("Profile.AdditionalInformation.address")}
                </h4>
                <button
                  className="link"
                  style={{ padding: 0 }}
                  onClick={addAddressView}
                >
                  {t("Profile.AdditionalInformation.show")}
                </button>
              </div>
            );
          }
        }}
      </Mutation>
    );
  } else {
    return (
      <Query query={GET_CHILD} variables={{ id: childId }}>
        {({ data, loading, error }) => {
          if (loading)
            return (
              <h4 className="weight-500">
                {t("Profile.AdditionalInformation.address")}
              </h4>
            );
          if (error) return null;
          const guardians = data.child.guardians;
          const selectedGuardian = guardians.filter(
            (item) => item.id === guardian.id
          );
          const { street, postalCode, city } = selectedGuardian[0];
          return (
            <>
              <h4 className="weight-500">
                {t("Profile.AdditionalInformation.address")}
              </h4>
              {street} {postalCode} {city}
            </>
          );
        }}
      </Query>
    );
  }
};

const PhoneNumbers = ({ phoneNumber, workPhoneNumber, t }) => {
  if (
    (phoneNumber && phoneNumber.length > 0) ||
    (workPhoneNumber && workPhoneNumber.length > 0)
  ) {
    if (workPhoneNumber && workPhoneNumber.length > 0) {
      workPhoneNumber = workPhoneNumber.replace(
        /^(.{6})(.{3})(.*)$/,
        "$1 $2 $3"
      );
    }
    phoneNumber = phoneNumber.replace(/^(.{6})(.{3})(.*)$/, "$1 $2 $3");
    return (
      <div className="size-normal">
        <h4 className="weight-500">
          {t("Profile.AdditionalInformation.phone_number")}
        </h4>
        {phoneNumber}
        {workPhoneNumber && workPhoneNumber.length > 0 && (
          <div>
            {workPhoneNumber} (
            <span>{t("Profile.AdditionalInformation.work_phone_number")}</span>)
          </div>
        )}
      </div>
    );
  } else {
    return "";
  }
};

const Contracts = ({ placements, t }) => {
  const activePlacementsString = placements
    .map((placement) => placement.serviceRequirement)
    .sort((a, b) => a.localeCompare(b))
    .join(", ");

  return (
    <List title={t("Profile.contract")} icon={CardTextIcon}>
      <ListItem>
        <h4 className="weight-500">{activePlacementsString}</h4>
      </ListItem>
    </List>
  );
};

const OtherInfo = ({ age, childId, t }) => {
  return (
    <List
      title={t("Profile.AdditionalInformation.other_information")}
      icon={InformationIcon}
    >
      {age && (
        <ListItem right={age}>
          <h4 className="weight-500">
            {t("Profile.AdditionalInformation.other_information_age")}
          </h4>
        </ListItem>
      )}
      <IdentityNumber childId={childId} t={t} />
    </List>
  );
};

const Absences = ({ absences, t }) => (
  <List title={t("Profile.absences")} icon={AccountOffIcon}>
    {absences && absences.length > 0 ? (
      absences.map((absenceGroup, index) => {
        const firstDate = dayjs(absenceGroup[0].date).format("dd D.M.YYYY");

        let lastDate = null;
        if (absenceGroup.length > 1) {
          lastDate =
            " – " +
            dayjs(absenceGroup[absenceGroup.length - 1].date).format(
              "dd D.M.YYYY"
            );
        }

        return (
          <ListItem key={index}>
            <h4>
              {firstDate}
              {lastDate}
            </h4>
          </ListItem>
        );
      })
    ) : (
      <ListItem>{t("Profile.no_absences")}</ListItem>
    )}
  </List>
);

const Vacations = ({ vacations, t }) => (
  <List title={t("Profile.vacations")} icon={WhiteBalanceSunnyIcon}>
    {vacations && vacations.length > 0 ? (
      vacations.map((vacationGroup, index) => {
        const firstDate = dayjs(vacationGroup[0].date).format("dd D.M.YYYY");

        let lastDate = null;
        if (vacationGroup.length > 1) {
          lastDate =
            " – " +
            dayjs(vacationGroup[vacationGroup.length - 1].date).format(
              "dd D.M.YYYY"
            );
        }

        return (
          <ListItem key={index}>
            <h4>
              {firstDate}
              {lastDate}
            </h4>
          </ListItem>
        );
      })
    ) : (
      <ListItem>{t("Profile.no_vacations")}</ListItem>
    )}
  </List>
);

const IdentityNumber = ({ childId, t }) => {
  return (
    <Mutation mutation={CREATE_CHILD_IDENTITY_NUMBER_VIEW}>
      {(createChildIdentityNumberView, { data, loading, error }) => {
        const addIdentityNumberView = (values) => {
          createChildIdentityNumberView({
            variables: {
              childId: childId,
            },
          });
        };

        if (error) {
          return <ErrorMessage error={error} />;
        }

        return (
          <ListItem
            right={
              loading
                ? "…"
                : (data &&
                    data.createChildIdentityNumberView.identityNumber) || (
                    <button
                      className="link"
                      style={{ padding: 0 }}
                      onClick={addIdentityNumberView}
                    >
                      Näytä
                    </button>
                  )
            }
          >
            <h4 className="weight-500">
              {t(
                "Profile.AdditionalInformation.other_information_identity_number"
              )}
            </h4>
          </ListItem>
        );
      }}
    </Mutation>
  );
};

const CREATE_CHILD_IDENTITY_NUMBER_VIEW = gql`
  mutation createChildIdentityNumberView($childId: ID!) {
    createChildIdentityNumberView(childId: $childId) {
      id
      identityNumber
      guardians {
        id
        firstName
        lastName
        phoneNumber
        street
        postalCode
        city
      }
    }
  }
`;

export default AdditionalInformation;

import React from "react";
import { PDFViewer } from "@react-pdf/renderer";
import gql from "graphql-tag";
import { Query } from "react-apollo";
import { useTranslation } from "react-i18next";

import { Loader, ErrorMessage } from "../shared";
import VasuPDF from "../components/VasuPDF/VasuPDF";

const VasuPDFContainer = (props) => {
  const { t } = useTranslation();
  let id = props.match.params.id;
  let documentId = props.match.params.document_id;

  return (
    <Query
      query={GET_CHILD}
      variables={{
        id: id,
        includeInactive: true,
      }}
    >
      {({ data, loading, error }) => {
        if (loading) return <Loader text={t("Vasu.loading_single")} centered large />;
        if (error)
          return (
            <ErrorMessage centered large>
              {t("Vasu.loading_error")}
            </ErrorMessage>
          );

        const { child } = data;

        return (
          <Query
            query={GET_DOCUMENT}
            variables={{
              document: { id: documentId },
            }}
            fetchPolicy={"network-only"}
          >
            {({ data, loading, error }) => {
              if (loading) return <Loader text={t("Vasu.loading_single")} centered large />;
              if (error) {
                console.error(error);
                return (
                  <ErrorMessage centered large>
                    {t("Vasu.loading_error")}
                  </ErrorMessage>
                );
              }

              const { earlyEducationDocument } = data;

              return (
                <PDFViewer style={{ width: "100%", height: "100%" }}>
                  <VasuPDF child={child} document={earlyEducationDocument} />
                </PDFViewer>
              );
            }}
          </Query>
        );
      }}
    </Query>
  );
};

const GET_CHILD = gql`
  query getChild($id: ID!, $includeInactive: Boolean) {
    child(id: $id, includeInactive: $includeInactive) {
      id
      name
      firstName
      lastName
      dateOfBirth
      daycareCenters {
        id
        name
      }
      activeDaycareGroups {
        id
        name
      }
    }
  }
`;

const GET_DOCUMENT = gql`
  query earlyEducationDocument($document: EarlyEducationDocumentAttributes!) {
    earlyEducationDocument(document: $document) {
      id
      publishedAt
      updatedAt
      createdAt
      lastUpdateAuthor {
        id
        firstName
        lastName
      }
      formFieldsData {
        key
        value
      }
      documentColumns {
        id
        position
        title
      }
      documentRows {
        id
        position
        title
      }
      objectiveValues {
        id
        value
        documentColumn {
          id
          position
          title
        }
        documentRow {
          id
          position
          title
        }
      }
    }
  }
`;

export default VasuPDFContainer;

// import dayjs from "dayjs";
// import LocalizedFormat from "dayjs/plugin/localizedFormat";

export const formatDateToTimeToday = date => {
  // dayjs.extend(LocalizedFormat);

  // HACK: hack to make sure /shared uses the same instance of dayjs as /customer or /staff
  const dayjs = window.localizedDayjs;

  const today = dayjs();
  const displayedDate = dayjs(date);

  if (today.isSame(displayedDate, "day")) {
    return displayedDate.format("H:mm");
  } else if (today.isSame(displayedDate, "year")) {
    return displayedDate.format("D.M. H:mm");
  } else {
    return displayedDate.format("D.M.YYYY H:mm");
  }
};

import React from "react";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";

import styles from "./ThumbnailGrid.module.scss";

const ThumbnailGrid = ({ children, heading }) => (
  <div className={styles.ThumbnailGrid}>
    {heading && (
      <h2 className={styles.Heading}>
        <FormatDate date={heading} threshold={7} />
      </h2>
    )}
    <div className={styles.Grid}>
      <ThumbnailGridItems>{children}</ThumbnailGridItems>
    </div>
  </div>
);

const ThumbnailGridItems = ({ children }) => {
  return React.Children.map(children, child => {
    return <ThumbnailGridItem>{child}</ThumbnailGridItem>;
  });
};

const ThumbnailGridItem = ({ children }) => {
  return <div className={styles.Box}>{children}</div>;
};

const FormatDate = ({ date, threshold }) => {
  const { t } = useTranslation();
  const today = DateTime.local().startOf("day");
  const displayedDate = DateTime.fromISO(date).startOf("day");

  const differenceInDays = today.diff(displayedDate, ["days"]).toObject().days;

  if (differenceInDays === 0) {
    return t("General.today");
  } else if (Math.abs(differenceInDays) < threshold) {
    return displayedDate.toFormat("cccc");
  } else {
    return displayedDate.toFormat("d.L.y");
  }
};

export default ThumbnailGrid;

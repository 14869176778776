import React from "react";
import styles from "./NarrowContainer.module.scss";

const NarrowContainer = ({ children, className, lessPadding }) => {
  return (
    <div
      className={[
        styles.outer,
        className,
        lessPadding ? styles.lessPadding : undefined,
      ].join(" ")}
    >
      <div className={styles.inner}>{children}</div>
    </div>
  );
};

export default NarrowContainer;

import React, {useState, useEffect} from 'react'
import styles from "./EventListItem.module.scss";
import StarIcon from "mdi-react/StarIcon";
import ChevronRightIcon from "mdi-react/ChevronRightIcon"
import Icon from '../../Icons/Icons'
//import ClampLines from 'react-clamp-lines';
import classNames from "classnames";
import dayjs from 'dayjs'
import { NavLink } from "react-router-dom";

const EventListItem = ({data, children, eventsData, customer, dashboard, eventItem, search, t, id, activeTab}) => {

  let {title, startDate, endDate, startTime, daycareGroups, endTime, icon, guardiansAllowed, summary, important} = eventItem

  const containerClassNames = classNames(styles.eventListItemContainer, {
    [styles.staff]: true,
  //  [styles.customer]: customer,
  //  [styles.important]:  important,
    [styles.dashboard]: dashboard,
    [styles.selectedItem]: eventItem.id === id,
  //  [styles.importantSelected]: eventItem.id === id && important
  });

  const eventListItemPaddingClassNames = classNames(styles.eventListItemPadding, {
    [styles.staff]: !customer,
  //  [styles.customer]: customer && !important,
    [styles.dashboard]: dashboard
  });
  const summaryClassNames = classNames(styles.eventDescription, {
    [styles.dashboard]: dashboard
  });

  if(!eventItem){
    return null
  }

  let names = []
  if(customer && eventsData){

    const groups = []
    eventsData.children.forEach((child) => {
      child.activeDaycareGroups.forEach((group) => {
        groups.push({
          name: group.name,
          id: group.id,
          daycareGroupsLength: group.daycareCenter.daycareGroups.length,
          daycareName: group.daycareCenter.name
        })
      })
    })

    if(groups[0].daycareGroupsLength === daycareGroups.length){
      names = groups[0].daycareName
    } else {
      groups.forEach((group, i) => {

        const groupIsInEvent = () => daycareGroups.some((eventGroup) => eventGroup.id === group.id)
        if(groupIsInEvent()){

            names.push(group.name)

        }
      })
      let uniqueArray = [...new Set(names)]
      names = uniqueArray.join()
    }
  }

  return(
    <div className={containerClassNames}>
    {important && <StarIcon className={styles.starIcon} />}
      <div className={eventListItemPaddingClassNames}>

        <NavLink
          to={{
            pathname: customer ? `/events/${eventItem.id}` : `/events/${activeTab}/${eventItem.id}`,
            search: search
          }}
          style={{textDecoration: 'none'}}
          key={eventItem.id}
          exact
        >

        <div style={{display: 'flex'}}>
            <div style={{flex: 5}}>
              <Time startAndEndDates={{startDate, endDate, startTime, endTime}} t={t}/>
              <h3 className={styles.eventTitle}>
                {title}
                {customer && <span style={{fontWeight: 400, marginLeft: 5}}>{`(${customer && names})`}</span>}

              </h3>
            </div>
            <div style={{flex: 1}}>
              {icon && <Icon label={icon} iconStyle={{marginTop: 5, height: 40 ,width: 40, left: customer ? 275 : 325 }}/>}
            </div>
        </div>

        <p className={summaryClassNames}>{summary}</p>
        </NavLink>

        {children}
        <NavLink
          to={{
            pathname: customer ? `/events/${eventItem.id}` : `/events/${activeTab}/${eventItem.id}`,
            search: search
          }}
          style={{textDecoration: 'none'}}
          key={eventItem.id}
          exact
        >
        {guardiansAllowed && customer && !dashboard && <p className={styles.RSVPreplyDeadLineText}>{t("Eventcalendar.GuardiansCanAttend")}</p>}
        </NavLink>
      </div>
      {customer &&
        <NavLink
          to={{
            pathname: customer ? `/events/${eventItem.id}` : `/events/${activeTab}/${eventItem.id}`,
            search: search
          }}
          style={{textDecoration: 'none'}}
          key={eventItem.id}
          exact
        >
          <ChevronRightIcon className={styles.arrowRightIcon}/>
        </NavLink>
      }
    </div>
  )
}

const Time = ({startAndEndDates, t }) => {

  let {startDate, startTime, endDate, endTime} = startAndEndDates
  const dayOfWeek = dayjs(startDate).format("d")

  const startDayOfWeek = dayjs(startDate).format("d")
  let endDayOfWeek = ""
  let endWeekday = ""

  if(endDate && endDate !== startDate){
     endDayOfWeek = dayjs(endDate).format("d")

     switch (endDayOfWeek) {
       case "1":
         endWeekday = t("Eventcalendar.Monday")
         break;
       case "2":
         endWeekday = t("Eventcalendar.Tuesday")
         break;
       case "3":
         endWeekday = t("Eventcalendar.Wednesday")
         break;
       case "4":
         endWeekday = t("Eventcalendar.Thursday")
         break;
       case "5":
         endWeekday = t("Eventcalendar.Friday")
         break;
       case "6":
         endWeekday = t("Eventcalendar.Saturday")
         break;
       case "0":
         endWeekday = t("Eventcalendar.Sunday")
       default:
     }
  }

  endDate = startDate !== endDate ? + dayjs(endDate).format('DD.MM') + "." : ""
  startDate = dayjs(startDate).format('DD.MM') + "."
  startTime = startTime + "." ? startTime : ""

  let startWeekday = ""
  switch (startDayOfWeek) {
    case "1":
      startWeekday = t("Eventcalendar.Monday")
      break;
    case "2":
      startWeekday = t("Eventcalendar.Tuesday")
      break;
    case "3":
      startWeekday = t("Eventcalendar.Wednesday")
      break;
    case "4":
      startWeekday = t("Eventcalendar.Thursday")
      break;
    case "5":
      startWeekday = t("Eventcalendar.Friday")
      break;
    case "6":
      startWeekday = t("Eventcalendar.Saturday")
      break;
    case "0":
      startWeekday = t("Eventcalendar.Sunday")
    default:
  }

  if(startTime && endTime){
    if(startDate === endDate){
      return <p className={styles.eventTime}>{`${startWeekday} ${startDate} ${startTime}-${endTime}`}</p>
    } else {
      return <p className={styles.eventTime}>{`${startWeekday} ${startDate} ${startTime} - ${endWeekday} ${endDate} ${endTime}`}</p>
    }
  } else {
    if(startDate === endDate){
      return <p className={styles.eventTime}>{`${startWeekday} ${startDate}`}</p>
    } else {
      if(endDate === ""){
        return <p className={styles.eventTime}>{`${startWeekday} ${startDate} `}</p>
      }
      return <p className={styles.eventTime}>{`${startWeekday} ${startDate} - ${endWeekday} ${endDate} `}</p>
    }
  }
}


export default EventListItem

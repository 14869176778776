
import React from "react";
import classNames from "classnames";
import styles from "./Loader.module.scss";

import Logo from "../Logo/Logo";

const Loader = ({ text, centered, large, inline, className }) => {
  const containerClassNames = classNames(styles.container, {
    [styles.centered]: centered,
    [styles.large]: large,
    [styles.inline]: inline,
    [className]: className,
  });
  return (
    <div className={containerClassNames}>
      <div className={styles.inner}>
        <Logo className={styles.loader} />
        {text && <div className={styles.text}>{text}</div>}
      </div>
    </div>
  );
};

export default Loader;

import React from "react";
import classNames from "classnames";

import styles from "./ContainerWithSideContent.module.scss";

const ContainerWithSideContent = ({
  left,
  right,
  tabs,
  noLeftSide,
  rightSideActiveOnMobile,
  padding,
  vasu,
  vasuDoc,
}) => {
  const outerClassNames = classNames(styles.outer, {
    [styles.rightSideActiveOnMobile]: rightSideActiveOnMobile,
    [styles.padding]: padding,
    [styles.noLeftSide]: noLeftSide,
    [styles.vasu]: vasu,
    [styles.vasuDoc]: vasuDoc,
  });
  return (
    <div className={outerClassNames}>
      {tabs && <div className={styles.tabsContainer}>{tabs}</div>}
      <div className={styles.inner}>
        {left && <div className={styles.left}>{left}</div>}
        {right && <div className={styles.right}>{right}</div>}
      </div>
    </div>
  );
};

export default ContainerWithSideContent;

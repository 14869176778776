import React, {useEffect, useState} from 'react'
import styles from "./Participants.module.scss";
import CheckCircleIcon from "mdi-react/CheckCircleIcon";
import HelpCircleIcon from "mdi-react/HelpCircleIcon";
import CloseCircleIcon from "mdi-react/CloseCircleIcon";
import Avatar from "../../Avatar/Avatar";

const Participants = ({responses, receivers, data, t, parentsCanAttend}) => {
  const daycareChildren = data.event.daycareCenter.children
  let attendingChildren = []
  let declinedChildren = []
  let repliedSomethingChildren = []

  responses.forEach((response) => {

    if(response.children.length > 0){
      let attendingNames = []

      response.children.forEach((child) => {
        attendingNames.push({
          id: child.id,
          name: child.name,
          firstName: child.firstName,
          lastName: child.lastName
        })
        repliedSomethingChildren.push(child.id)
      })

      attendingChildren.push({
        names: attendingNames,
        guestCount: response.guestCount
      })
    }

    if(response.declinedChildren.length > 0){

      const notAttendingNames = []

      response.declinedChildren.forEach((child) => {
        notAttendingNames.push({
          id: child.id,
          name: child.name,
          firstName: child.firstName,
          lastName: child.lastName
        })
        repliedSomethingChildren.push(child.id)
      })

      declinedChildren.push({
        names: notAttendingNames,
      })
    }
  });

  const eventGroups = data.event.daycareGroups.map(group => group.id)

  const childrenInEventGroups = data.event.daycareCenter.children.filter((child) =>
    child.activeDaycareGroups.some(placement =>
      eventGroups.includes(placement.id)
    )
  )

  const waitingResponseChildren = childrenInEventGroups.filter((child) =>
    !repliedSomethingChildren.some(repliedId => repliedId === child.id)
  )

  return(
    <>
        <IsAttending responses={responses} t={t}>
          {attendingChildren.map(response =>
            <AttendingItemsContainer
              attending
              response={response}
              parentsCanAttend={parentsCanAttend}
              daycareChildren={daycareChildren}
            />
          )}
        </IsAttending>

        <NotAttending responses={responses} t={t}>
          {declinedChildren.map(response =>
            <>
              {response.names.map(child =>
                <NotAttendingItem
                   child={child}
                />
              )}
            </>
          )}
        </NotAttending>

        <WaitingResponse t={t}>
          {waitingResponseChildren.map(child => {
            const {firstName, lastName} = child
            return <WaitingItem firstName={firstName} lastName={lastName}/>
          })}
        </WaitingResponse>


    </>
  )
}

const AttendingItemsContainer = ({response, daycareChildren, attending, parentsCanAttend}) => {

  const guestCount = response.guestCount

    if(response.names.length === 1){

      return (
        <SingleItem
          name={response.names[0].name}
          firstName={response.names[0].firstName}
          lastName={response.names[0].lastName}
          guestCount={guestCount}
          parentsCanAttend={parentsCanAttend}
        />
      )
    } else {
      return (
        <MultiItem
          parentsCanAttend={parentsCanAttend}
          parents={guestCount}
          names={response.names}
          guestCount={guestCount}
          parentsCanAttend={parentsCanAttend}
        />
      )
    }
}

const SingleItem = ({firstName, lastName, name, guestCount, parentsCanAttend}) => {

  let initials = ""

  if(firstName && lastName){
    initials = firstName.slice(0,1) + lastName.slice(0,1)
  }
  return(
    <div className={styles.attendingSingleItem}>
      <div className={styles.nameContainer}>
        <div style={{display: 'inline-block'}}>
          <Avatar initials={initials} url={null}/>
        </div>
        <p className={styles.singleItemName}>{`${name}`}</p>
      </div>
      {parentsCanAttend && guestCount > 0 &&
        <div className={styles.countContainer}>
          <p className={styles.parentsAmountText}>+ {guestCount} perheenjäsentä</p>
        </div>
      }

    </div>
  )
}

const MultiItem = ({childName, names, guestCount, children, parentsCanAttend}) => {
  return(
    <div style={{display: 'flex'}}>
      <div style={{flex: parentsCanAttend ? 3 : 1}}>
        {
          names.map(name => {

          return(
            <div style={{padding: 10}}>
              <div style={{display: 'inline-block'}}>
                <Avatar initials={name.firstName.slice(0,1) + name.lastName.slice(0,1)} url={null}/>
              </div>
              <p className={styles.singleItemName}>{`${name.name}`}</p>
            </div>
          )
        })
      }
      </div>
      {parentsCanAttend && guestCount > 0 &&
        <div className={styles.countContainer}>
          <p className={styles.parentsAmountText}>+ {guestCount} perheenjäsentä</p>
        </div>
      }

    </div>
  )
}


const WaitingItem = ({firstName, lastName}) => {
  let initials = ""
  if(firstName && lastName){
    initials = firstName.slice(0,1) + lastName.slice(0,1)
  }
  return(
    <div className={styles.attendingSingleItem}>
      <div className={styles.nameContainer}>
        <div style={{display: 'inline-block'}}>
          <Avatar initials={initials} url={null}/>
        </div>
        <p className={styles.singleItemName}>{`${firstName} ${lastName}`}</p>
      </div>
    </div>
  )
}


const NotAttendingItem = ({child}) => {

  let {firstName, lastName, name } = child
  let initials = ""

  if(firstName && lastName){
    initials = firstName.slice(0,1) + lastName.slice(0,1)
  }

  return(
    <div className={styles.attendingSingleItem}>
      <div className={styles.nameContainer}>
        <div style={{display: 'inline-block'}}>
          <Avatar initials={initials} url={null}/>
        </div>
        <p className={styles.singleItemName}>{`${name}`}</p>
      </div>
    </div>
  )
}


const IsAttending = ({responses, children, t}) => {
  return(
    <div className={styles.attendingContainer}>
      <div className={styles.attendingSingleItem} style={{padding: 10}}>
        <p className={`${styles.attendingText} ${styles.green}`}>
          <span><CheckCircleIcon className={styles.icon}/></span>
          {t("Eventcalendar.Participating")}
        </p>
      </div>
      {children}
    </div>
  )
}


const NotAttending = ({children, t}) => {
  return(
    <div className={styles.attendingContainer}>
      <div className={styles.attendingSingleItem} style={{padding: 10}}>
        <p className={`${styles.attendingText} ${styles.gray}`}>
          <span><CloseCircleIcon className={styles.icon}/></span>
          {t("Eventcalendar.NotParticipating")}
        </p>
      </div>
      {children}
    </div>
  )
}
const WaitingResponse = ({children, t}) => {
  return(
    <div className={styles.attendingContainer}>
      <div className={styles.attendingSingleItem} style={{padding: 10}}>
        <p className={`${styles.attendingText} ${styles.gray}`}>
          <span><HelpCircleIcon className={styles.icon}/></span>
          {t("Eventcalendar.WaitingResponse")}
        </p>
      </div>
      {children}
    </div>
  )
}

export default Participants

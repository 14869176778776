import React from "react";
import CloseIcon from "mdi-react/CloseIcon";
import { useTranslation } from "react-i18next";
import styles from "./SearchResult.module.scss";

const SearchResult = ({ label, value, onClick }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div className={styles.text}>
          <span className={styles.label}>{label ? label : t("General.search_results")}</span>{" "}
          <span className={styles.value}>&quot;{value}&quot;</span>
        </div>
        <button className={styles.close} onClick={onClick}>
          <CloseIcon />
        </button>
      </div>
    </div>
  );
};

export default SearchResult;

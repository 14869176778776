import React from "react";
import { DateUtils } from "react-day-picker";
import dayjs from "dayjs";
import { Formik, Form, Field, ErrorMessage as ErrorHandler } from "formik";
import { useTranslation } from "react-i18next";

import ErrorMessage from "../ErrorMessage/ErrorMessage";
import {
  List,
  ListItem,
  ListCheckbox,
  ListContent,
} from "../ListItem/ListItem";
import Loader from "../Loader/Loader";
import Button from "../Button/Button";
import ListItemNotifier from "../ListItemNotifier/ListItemNotifier";

import timeStyles from "./ReservationForm.module.scss";
import AlertOutlineIcon from "mdi-react/AlertOutlineIcon";

import isBetween from "dayjs/plugin/isBetween";
dayjs.extend(isBetween);

const { timeWrapper, timeLabel, timeInput, timeError } = timeStyles;

const { clone, isSameDay } = DateUtils;

const DEFAULT_START_HOUR = 8;
const DEFAULT_START_MINUTE = 0;
const DEFAULT_END_HOUR = 16;
const DEFAULT_END_MINUTE = 0;

const TIME_FORMAT = "HH:mm";
const TIME_STEP = 60 * 5;

const ReservationForm = ({
  children: reactChildren,
  days = [],
  childList: children = [],
  childrenSelectedByDefault = [],
  childrenBlockedFromReserving = [],
  ChildSelector = DefaultChildSelector,
  action = "reserve",
  apiLoading = false,
  apiError = false,
  onSubmit = () => {},
  submitDisabled = false,
  onDelete = () => {},
  selectedDaysInCurrentMonth = 0,
  staff = false,
  loading,
}) => {
  // HACK: hack to make sure /shared uses the same instance of dayjs as /customer or /staff
  const dayjs = window.localizedDayjs;
  const { t } = useTranslation();

  // for customer side
  let minTime,
    maxTime,
    savedReservation,
    preschoolStartHours,
    preschoolStartMinutes,
    preschoolEndHours,
    preschoolEndMinutes;

  let calculatePreschool = false;

  if (!staff) {
    const firstSelectedChild = children.find(
      (child) => child.id === childrenSelectedByDefault[0],
    );

    const daycareCenter = firstSelectedChild.daycareCenters[0];
    const [opensAtHours, opensAtMinutes] = parseTime(daycareCenter.opensAt);
    const [closesAtHours, closesAtMinutes] = parseTime(daycareCenter.closesAt);

    if (
      daycareCenter &&
      daycareCenter.preschoolHoursStartAt &&
      daycareCenter.preschoolHoursEndAt
    ) {
      calculatePreschool = true;
      [preschoolStartHours, preschoolStartMinutes] = parseTime(
        daycareCenter.preschoolHoursStartAt,
      );
      [preschoolEndHours, preschoolEndMinutes] = parseTime(
        daycareCenter.preschoolHoursEndAt,
      );
    }

    minTime = dayjs().hour(opensAtHours).minute(opensAtMinutes).second(0);
    maxTime = dayjs().hour(closesAtHours).minute(closesAtMinutes).second(1);

    const compareDay = dayjs(days[0]);
    savedReservation = firstSelectedChild.reservations.find((reservation) => {
      return (
        compareDay.format("YYYY-MM-DD") ===
        dayjs(reservation.startTime).format("YYYY-MM-DD")
      );
    });
  }

  let defaultStartTime = dayjs()
    .hour(DEFAULT_START_HOUR)
    .minute(DEFAULT_START_MINUTE)
    .second(0);

  let defaultEndTime = dayjs()
    .hour(DEFAULT_END_HOUR)
    .minute(DEFAULT_END_MINUTE)
    .second(0);

  if (savedReservation) {
    defaultStartTime = dayjs()
      .hour(dayjs(savedReservation.startTime).get("hour"))
      .minute(dayjs(savedReservation.startTime).get("minute"))
      .second(0);

    defaultEndTime = dayjs()
      .hour(dayjs(savedReservation.endTime).get("hour"))
      .minute(dayjs(savedReservation.endTime).get("minute"))
      .second(0);
  } else if (!savedReservation && !staff) {
    for (let index = 0; index < children.length; index++) {
      if (
        children[index].latestReservation &&
        children[index].latestReservation.startTime &&
        children[index].latestReservation.endTime &&
        childrenSelectedByDefault.includes(children[index].id)
      ) {
        defaultStartTime = dayjs()
          .hour(dayjs(children[index].latestReservation.startTime).get("hour"))
          .minute(
            dayjs(children[index].latestReservation.startTime).get("minute"),
          )
          .second(0);

        defaultEndTime = dayjs()
          .hour(dayjs(children[index].latestReservation.endTime).get("hour"))
          .minute(
            dayjs(children[index].latestReservation.endTime).get("minute"),
          )
          .second(0);

        break;
      }
    }
  }

  const validate = ({ startTime, endTime, childSelections }) => {
    let errors = {};

    const [startHour, startMinute] = parseTime(startTime);
    const [endHour, endMinute] = parseTime(endTime);

    const from = dayjs().hour(startHour).minute(startMinute).second(1);
    const to = dayjs().hour(endHour).minute(endMinute).second(0);

    if (from.isAfter(to)) {
      errors.startTime = t(
        "ReservationForm.ValidationError.drop_off_before_pick_up",
      );
      errors.endTime = t(
        "ReservationForm.ValidationError.pick_up_after_drop_off",
      );
    }

    if (minTime && from.isBefore(minTime)) {
      errors.startTime = t(
        "ReservationForm.ValidationError.drop_off_earliest",
        {
          validation: minTime.format(TIME_FORMAT),
        },
      );
    } else if (maxTime && from.isAfter(maxTime)) {
      errors.startTime = t("ReservationForm.ValidationError.drop_off_latest", {
        validation: maxTime.format(TIME_FORMAT),
      });
    }

    if (minTime && to.isBefore(minTime)) {
      errors.endTime = t("ReservationForm.ValidationError.pick_up_earliest", {
        validation: minTime.format(TIME_FORMAT),
      });
    } else if (maxTime && to.isAfter(maxTime)) {
      errors.endTime = t("ReservationForm.ValidationError.pick_up_latest", {
        validation: maxTime.format(TIME_FORMAT),
      });
    }

    if (!from.isValid()) {
      errors.startTime = t(
        "ReservationForm.ValidationError.time_format_invalid",
      );
    }
    if (!to.isValid()) {
      errors.endTime = t("ReservationForm.ValidationError.time_format_invalid");
    }

    return errors;
  };

  return (
    <Formik
      initialValues={{
        childSelections: children.reduce((map, { id }) => {
          map[id] = childrenSelectedByDefault.includes(id);

          if (childrenBlockedFromReserving.some((child) => child.id === id)) {
            map[id] = false;
          }

          return map;
        }, {}),
        startTime: defaultStartTime.format(TIME_FORMAT),
        endTime: defaultEndTime.format(TIME_FORMAT),
      }}
      validate={validate}
      onSubmit={onSubmit}
    >
      {({
        values,
        setValues,
        errors,
        setFieldValue,
        setFieldError,
        touched,
      }) => {
        touched.startTime = true;
        touched.endTime = true;
        const [startHour, startMinute] = parseTime(values.startTime);
        const [endHour, endMinute] = parseTime(values.endTime);
        const from = dayjs().hour(startHour).minute(startMinute);
        const to = dayjs().hour(endHour).minute(endMinute);

        const difference = Math.ceil(to.diff(from, "minutes") / 5) * 5;
        const dailyMinutes = Math.max(0, difference) || 0;
        const totalMinutes = Math.max(0, dailyMinutes * days.length) || 0;

        let dailyMinutesWithPreschool = dailyMinutes;

        if (calculatePreschool) {
          const preFrom = dayjs()
            .hour(preschoolStartHours)
            .minute(preschoolStartMinutes);
          const preTo = dayjs()
            .hour(preschoolEndHours)
            .minute(preschoolEndMinutes);

          if (from >= preTo || to <= preFrom) {
            dailyMinutesWithPreschool = dailyMinutes;
          } else {
            let startDifference =
              Math.ceil(preFrom.diff(from, "minutes") / 5) * 5;
            let endDifference = Math.ceil(to.diff(preTo, "minutes") / 5) * 5;

            if (startDifference < 0) startDifference = 0;
            if (endDifference < 0) endDifference = 0;

            dailyMinutesWithPreschool = startDifference + endDifference;
          }
        }

        const selectedChildren = Object.entries(values.childSelections)
          .filter(([id, selected]) => selected)
          .map(([id]) => id);
        const amountOfChildrenSelected = selectedChildren.length;
        const selectedChildrenBlockedFromReserving =
          childrenBlockedFromReserving.filter(({ id }) =>
            selectedChildren.includes(id),
          );

        const isDisabled =
          selectedChildrenBlockedFromReserving.length > 0 ||
          submitDisabled ||
          Object.keys(errors).length > 0 ||
          apiLoading ||
          totalMinutes <= 0 ||
          amountOfChildrenSelected <= 0;

        return (
          <Form>
            <ChildSelector
              name="childSelections"
              children={children}
              loading={loading}
              selectedChildren={selectedChildren}
              selectedDays={days}
              setFieldValue={setFieldValue}
              setFieldError={setFieldError}
              values={values}
              childrenBlockedFromReserving={childrenBlockedFromReserving}
              selectedDaysInCurrentMonth={selectedDaysInCurrentMonth}
              action={action}
              staff={staff}
              dailyMinutes={dailyMinutes}
              dailyMinutesWithPreschool={dailyMinutesWithPreschool}
            />

            {!staff && action === "reserve" && (
              <div className="flex justify-center padding-8 padding-y">
                <ListItemNotifier />
              </div>
            )}

            {reactChildren}

            <List title={t("ReservationForm.select_times")}>
              <ListContent empty>
                <div className={timeWrapper}>
                  <label htmlFor="startTime" className={timeLabel}>
                    {t("ReservationForm.drop_off")}
                  </label>
                  <div className={timeInput}>
                    <Field
                      type="time"
                      id="startTime"
                      name="startTime"
                      min={minTime && minTime.format(TIME_FORMAT)}
                      max={maxTime && maxTime.format(TIME_FORMAT)}
                      step={TIME_STEP}
                      onBlur={(event) => {
                        const [hour, minute] = parseTime(values.startTime);

                        const roundedMinute = Math.floor(minute / 5) * 5;

                        const startTime = dayjs()
                          .hour(hour)
                          .minute(roundedMinute)
                          .format(TIME_FORMAT);

                        setValues({ ...values, startTime });
                      }}
                    />
                  </div>
                </div>
                <ErrorHandler
                  name="startTime"
                  component={(props) => (
                    <div className={timeError}>
                      <ErrorMessage {...props} />
                    </div>
                  )}
                />
              </ListContent>

              <ListContent empty>
                <div className={timeWrapper}>
                  <label htmlFor="endTime" className={timeLabel}>
                    {t("ReservationForm.pick_up")}
                  </label>
                  <div className={timeInput}>
                    <Field
                      type="time"
                      id="endTime"
                      name="endTime"
                      min={minTime && minTime.format(TIME_FORMAT)}
                      max={maxTime && maxTime.format(TIME_FORMAT)}
                      step={TIME_STEP}
                      onBlur={(event) => {
                        const [hour, minute] = parseTime(values.endTime);

                        const roundedMinute = Math.floor(minute / 5) * 5;

                        const endTime = dayjs()
                          .hour(hour)
                          .minute(roundedMinute)
                          .format(TIME_FORMAT);

                        setValues({ ...values, endTime });
                      }}
                    />
                  </div>
                </div>
                <ErrorHandler
                  name="endTime"
                  component={(props) => (
                    <div className={timeError}>
                      <ErrorMessage {...props} />
                    </div>
                  )}
                />
              </ListContent>
            </List>

            <List>
              <ListContent>
                <div className="child-margins-y-8">
                  <div className={`flex justify child-margins-x-16`}>
                    <div>{t("ReservationForm.time_in_daycare")}</div>
                    <div className="weight-500">
                      {Math.floor(dailyMinutes / 60)}
                      {t("Profile.ReservationStats.hour_abbr")}{" "}
                      {dailyMinutes % 60
                        ? (dailyMinutes % 60) +
                          t("Profile.ReservationStats.min_abbr")
                        : ""}
                    </div>
                  </div>

                  <SelectedDays days={days} />
                  {/* (
                    {daysToRanges(days)
                      .map(({ from, to }, index) =>
                        isSameDay(from, to)
                          ? from.toDateString()
                          : `${from.toDateString()}–${to.toDateString()}`
                      )
                      .join(", ")}
                    ) */}

                  <div className={`flex justify child-margins-x-16`}>
                    <div>{t("ReservationForm.reservable_hours_total")}</div>
                    <div className="weight-500">
                      {Math.floor(totalMinutes / 60)}
                      {t("Profile.ReservationStats.hour_abbr")}{" "}
                      {totalMinutes % 60
                        ? (totalMinutes % 60) +
                          t("Profile.ReservationStats.min_abbr")
                        : ""}
                    </div>
                  </div>
                </div>
              </ListContent>

              {apiError && (
                <ListItem>
                  <ErrorMessage className="padding-16">
                    {t("ReservationForm.error_message")}{" "}
                    <em>{apiError.message}</em>
                  </ErrorMessage>
                </ListItem>
              )}

              <div className="flex even">
                {action === "edit" && (
                  <Button
                    actionBarStyle
                    type="button"
                    disabled={isDisabled}
                    onClick={() => onDelete(values)}
                  >
                    <div className="flex child-margins-x-4">
                      <span>{t("ReservationForm.remove_reservation")}</span>
                      {apiLoading && <Loader inline />}
                    </div>
                  </Button>
                )}

                <Button
                  actionBarStyle
                  type="submit"
                  disabled={isDisabled}
                  green
                >
                  <div className="flex child-margins-x-4">
                    <span>
                      {action === "edit"
                        ? t("ReservationForm.update_reservation")
                        : t("ReservationForm.submit_reservation")}
                    </span>
                    {apiLoading && <Loader inline />}
                  </div>
                </Button>
              </div>
            </List>
          </Form>
        );
      }}
    </Formik>
  );
};
export default ReservationForm;

const ChildCheckboxes = ({
  name: fieldName,
  children = [],
  selectedChildren = [],
  childrenBlockedFromReserving = [],
  selectedDaysInCurrentMonth,
  action,
  staff,
  dailyMinutes,
  dailyMinutesWithPreschool,
}) => {
  const { t } = useTranslation();

  return children.map(({ name, id, timeUsage, quotas, activePlacements }) => {
    const blockedFromReserving = childrenBlockedFromReserving.find(
      (child) => id === child.id,
    );

    let contractIndicator = null;
    let exceedsContract = false;
    let addMinutes = 0;
    let addDays = 0;
    let minutes = dailyMinutes;

    if (
      activePlacements &&
      activePlacements.find((placement) => placement.category === "PRESCHOOL")
    ) {
      minutes = dailyMinutesWithPreschool;
    }

    if (action === "reserve" && !staff) {
      if (selectedChildren.includes(id)) {
        addMinutes = minutes * selectedDaysInCurrentMonth;
        addDays = selectedDaysInCurrentMonth;
      }

      if (timeUsage.hourlyContract && timeUsage.hoursPerMonth) {
        if (
          !exceedsContract &&
          minutes * selectedDaysInCurrentMonth + timeUsage.reserved >
            timeUsage.hoursPerMonth * 60
        ) {
          exceedsContract = true;
        }

        contractIndicator = (
          <div className="flex align-center">
            {addMinutes > 0 && exceedsContract && (
              <AlertOutlineIcon className="margin-8 margin-right" />
            )}
            <HourlyTime time={addMinutes + timeUsage.reserved} t={t} /> /{" "}
            {timeUsage.hoursPerMonth} {t("Profile.ReservationStats.hour_abbr")}{" "}
            ({addDays + timeUsage.reservedDays}{" "}
            {t("Profile.ReservationStats.days_abbr")})
          </div>
        );
      } else if (!timeUsage.hourlyContract) {
        if (
          !!timeUsage.daysPerMonth &&
          !exceedsContract &&
          selectedDaysInCurrentMonth + timeUsage.reservedDays >
            timeUsage.daysPerMonth
        ) {
          exceedsContract = true;
        }

        contractIndicator = (
          <div className="flex align-center">
            {addDays > 0 && exceedsContract && (
              <AlertOutlineIcon className="margin-8 margin-right" />
            )}
            {addDays + timeUsage.reservedDays}
            {!!timeUsage.daysPerMonth && " / " + timeUsage.daysPerMonth}{" "}
            {t("Profile.ReservationStats.days_abbr")} (
            {<HourlyTime time={addMinutes + timeUsage.reserved} t={t} />})
          </div>
        );
      }
    }

    return (
      <React.Fragment key={id}>
        <ListCheckbox
          inputComponent={Field}
          type="checkbox"
          checked={selectedChildren.includes(id)}
          name={`${fieldName}.${id}`}
          disabled={!!blockedFromReserving}
          flex
          warning={exceedsContract}
        >
          <div className="grow margin-8 margin-right">{name}</div>

          {contractIndicator}

          {Object.entries(quotas || {}).map(([dateKey, { current, total }]) => (
            <div key={dateKey} className="flex justify weight-400 size-small">
              <span>{dateKey}</span>
              <span>
                {current}pv/{total}pv
              </span>
            </div>
          ))}
        </ListCheckbox>

        {blockedFromReserving && (
          <ListItem>
            <ErrorMessage>{blockedFromReserving.reason}</ErrorMessage>
          </ListItem>
        )}
      </React.Fragment>
    );
  });
};

const HourlyTime = ({ time, t }) => {
  const hours = parseInt(time / 60);
  const minutes = time % 60;

  return (
    <React.Fragment>
      {hours} {t("Profile.ReservationStats.hour_abbr")}
      {minutes !== 0 && (
        <React.Fragment>
          {" "}
          {minutes} {t("Profile.ReservationStats.min_abbr")}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export const SelectedDays = ({ days }) => {
  const { t } = useTranslation();

  return (
    <div className={`flex justify child-margins-x-16`}>
      <div>
        {days.length > 1
          ? t("ReservationForm.selected_days") + ` (${days.length})`
          : t("ReservationForm.selected_day")}
      </div>
      <div className="weight-500">
        {days.length > 0
          ? days.length > 1
            ? t("ReservationForm.between") +
              " " +
              dayjs(days[0]).format("D.M.") +
              " - " +
              dayjs(days[days.length - 1]).format("D.M.")
            : dayjs(days[0]).format("D.M.")
          : "—"}
      </div>
    </div>
  );
};

export const DefaultChildSelector = ({
  children,
  selectedChildren,
  name,
  childrenBlockedFromReserving,
  selectedDaysInCurrentMonth,
  action,
  staff,
  dailyMinutes,
  dailyMinutesWithPreschool,
  loading,
}) => {
  const { t } = useTranslation();
  if (loading) {
    return (
      <div style={{ marginTop: 80, marginLeft: 10 }}>
        <Loader />
      </div>
    );
  }
  return (
    <List
      title={t("ReservationForm.select_children")}
      rightSideTitle={
        action === "reserve" && !staff
          ? t("ReservationForm.reserved_out_of_contract")
          : null
      }
    >
      <ChildCheckboxes
        name={name}
        children={children}
        selectedChildren={selectedChildren}
        childrenBlockedFromReserving={childrenBlockedFromReserving}
        selectedDaysInCurrentMonth={selectedDaysInCurrentMonth}
        action={action}
        staff={staff}
        dailyMinutes={dailyMinutes}
        dailyMinutesWithPreschool={dailyMinutesWithPreschool}
      />
    </List>
  );
};

export const daysToRanges = (days) => {
  // Assumes days are sorted
  const ranges = [{ from: days[0], to: days[0] }];

  days.forEach((day, dayIndex, days) => {
    const rangeIndex = ranges.length - 1;
    const currentEndOfRange = clone(ranges[rangeIndex].to);
    currentEndOfRange.setDate(currentEndOfRange.getDate() + 1);

    if (isSameDay(day, currentEndOfRange)) {
      ranges[rangeIndex].to = day;
    } else if (dayIndex !== 0) {
      ranges.push({ from: day, to: day });
    }
  });
  return ranges;
};

export const parseTime = (stamp) => {
  const [hours, minutes] = stamp.split(":");
  return [+hours, +minutes];
};

export const encodeTime = (hours, minutes) => {
  return `${hours < 10 ? `0${hours}` : hours}:${
    minutes < 10 ? `0${minutes}` : minutes
  }`;
};

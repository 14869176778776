export const sentryConfig = {
  tracesSampleRate: 0.01,
  tracePropagationTargets: [/^\//],
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0,
  ignoreErrors: [
    /Network error.*/,
    /AbortError.*/,
  ],
};

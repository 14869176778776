import React, { useContext } from "react";

import classNames from "classnames";

import { isNativeApp } from "../UserAgent/UserAgent";

import stickyActions from "./StickyActionPanel.module.scss";
const { container, stickToTop, stickToBottom, actionRow, web, empty } =
  stickyActions;

const StickyActionPanel = ({ children, stickTo = "top" }) => {
  const containerClassNames = classNames(container, {
    [stickToTop]: stickTo === "top",
    [stickToBottom]: !(stickTo === "top"),
    [web]: !isNativeApp(),
  });
  return <div className={containerClassNames}>{children}</div>;
};

export default StickyActionPanel;

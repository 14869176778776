import React from "react";
import styles from "./VasuDocumentItem.module.scss";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

import Link from "../Link/Link";
import Button from "../Button/Button";

const VasuDocumentItem = ({ childId, document, guardian }) => {
  const { t } = useTranslation();
  if (document.publishedAt) {
    return (
      <div className={guardian ? styles.guardian : styles.root}>
        <div className={styles.content}>
          {guardian && (
            <h2 className={styles.guardianTitle}>{t("Vasu.plan")}</h2>
          )}
          {!guardian && (
            <div className={styles.topContent}>
              <h2 className={styles.title}>{t("Vasu.published_version")}</h2>
              <div className={styles.linkContainer}>
                <Link
                  className={styles.publishedLink}
                  to={{
                    pathname: `/vasu/documents/${childId}/document/${document.id}`,
                  }}
                  getSearch
                >
                  {t("Vasu.show")}
                </Link>
              </div>
            </div>
          )}
          <div className={guardian ? styles.guardianMeta : styles.meta}>
            <div className={styles.metaItem}>
              <span className={styles.metaLabelLong}>
                {t("Vasu.published")}
              </span>
              <span className={styles.metaValue}>
                {DateTime.fromISO(document.updatedAt).toFormat(
                  "d.L.yyyy, HH:mm"
                )}
              </span>
            </div>
            <div className={styles.metaItem}>
              <span className={styles.metaLabelLong}>
                {t("Vasu.published_by")}
              </span>
              <span className={styles.metaValue}>
                {document.lastUpdateAuthor.firstName}{" "}
                {document.lastUpdateAuthor.lastName}
              </span>
            </div>
          </div>
          {guardian && (
            <div className={styles.guardianButton}>
              <Link
                className={styles.guardianLink}
                to={{
                  pathname: `/vasu/documents/${childId}/document/${document.id}`,
                }}
              >
                <Button fullWidth>{t("Vasu.show")}</Button>
              </Link>
            </div>
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div className={styles.root}>
        <div className={styles.content}>
          <div className={styles.topContent}>
            <h2 className={styles.title}>{t("Vasu.draft")}</h2>
            <div className={styles.linkContainer}>
              <Link
                className={styles.draftLink}
                to={{
                  pathname: `/vasu/documents/${childId}/document/${document.id}`,
                }}
                getSearch
              >
                {t("Vasu.edit")}
              </Link>
            </div>
          </div>
          <div className={styles.meta}>
            <div className={styles.metaItem}>
              <span className={styles.metaLabelLong}>{t("Vasu.created")}</span>
              <span className={styles.metaValue}>
                {DateTime.fromISO(document.createdAt).toFormat(
                  "d.L.yyyy, HH:mm"
                )}
              </span>
            </div>
            <div className={styles.metaItem}>
              <span className={styles.metaLabelLong}>
                {t("Vasu.last_edited")}
              </span>
              <span className={styles.metaValue}>
                {DateTime.fromISO(document.updatedAt).toFormat(
                  "d.L.yyyy, HH:mm"
                )}
              </span>
            </div>
            <div className={styles.metaItem}>
              <span className={styles.metaLabelLong}>
                {t("Vasu.last_edited_by")}
              </span>
              <span className={styles.metaValue}>
                {document.lastUpdateAuthor.firstName}{" "}
                {document.lastUpdateAuthor.lastName}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default VasuDocumentItem;

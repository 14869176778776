import React from "react";
import styles from "./Sidebar.module.scss";

const Sidebar = ({
  open,
  primaryNavigation,
  logo,
  topContent,
  secondaryNavigation,
  bottomNavigation
}) => {
  return (
    <div className={open ? styles.open : styles.sidebar}>
      <div className={styles.logo}>{logo}</div>
      {topContent && <div className={styles.top}>{topContent}</div>}
      <div className={styles.main}>
        {primaryNavigation && (
          <div className={styles.mainTop}>{primaryNavigation}</div>
        )}
        {secondaryNavigation && (
          <div className={styles.mainBottom}>{secondaryNavigation}</div>
        )}
      </div>
      <div className={styles.bottom}>{bottomNavigation}</div>
    </div>
  );
};

export default Sidebar;

import React from "react";
import styles from "./VasuDocumentMeta.module.scss";
import Avatar from "../Avatar/Avatar";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

const VasuDocumentMeta = ({ child }) => {
  const { id, name, firstName, lastName } = child;
  const { t } = useTranslation();
  const profilePictureUrl =
    child.profilePicture &&
    child.profilePicture.extraSmall &&
    child.profilePicture.extraSmall.url
      ? child.profilePicture.extraSmall.url
      : undefined;

  return (
    <div className={styles.root}>
      <div className={styles.nameAndAvatar}>
        <Avatar initials={firstName[0] + lastName[0]} url={profilePictureUrl} />
        <span className={styles.childName}>{name}</span>
      </div>
      <div className={styles.meta}>
        <div className={styles.metaItem}>
          <span className={styles.metaLabelShort}>{t("Vasu.born")}</span>
          <span className={styles.metaValue}>
            {DateTime.fromISO(child.dateOfBirth).toFormat("d.L.yyyy")}
          </span>
        </div>
        <div className={styles.metaItem}>
          <span className={styles.metaLabelShort}>{t("Vasu.daycare")}</span>
          <span className={styles.metaValue}>
            {child.daycareCenters && child.daycareCenters.length > 0
              ? child.daycareCenters[0].name
              : "–"}
          </span>
        </div>
        <div className={styles.metaItem}>
          <span className={styles.metaLabelShort}>{t("Vasu.group")}</span>
          <span className={styles.metaValue}>
            {child.activeDaycareGroups && child.activeDaycareGroups.length > 0
              ? child.activeDaycareGroups[0].name
              : "–"}
          </span>
        </div>
      </div>
    </div>
  );
};

export default VasuDocumentMeta;

import React from "react";
import { Query } from "react-apollo";
import { CURRENT_WORKER } from "../../queries";

const RoleBoundary = ({ accessibleBy, exclude, children }) => {
  return (
    <Query query={CURRENT_WORKER}>
      {({ data, error, loading }) => {
        if (error || loading) return "";
        const {
          currentWorker: { role },
        } = data;

        if (exclude && !accessibleBy) {
          if (exclude.includes(role)) {
            return "";
          } else {
            return children;
          }
        } else if (!exclude && accessibleBy) {
          if (role === "ADMINISTRATOR" || accessibleBy.includes(role)) {
            return children;
          } else {
            return "";
          }
        } else {
          return children;
        }
      }}
    </Query>
  );
};

export default RoleBoundary;

import React from "react";

const Logo = ({ className, type }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 375 375"
    >
      <g fillRule="evenodd" clipRule="evenodd">
        <path
          d="M285.3 83h-67.2l-16.7 51.3c-.4 1.1-.8 2.2-1.2 3.2.2 25.1 20.8 45.4 46 45.4h3.4l8.1-2.4 45.9-12.8 4.4 15.2h65.1v-12.4c.1-48.2-39.3-87.5-87.8-87.5"
          fill="#ffa249"
        />
        <path
          d="M301.6 190.3l-1-3.1-.3-1.2-1.2-4.3-1.4-4.8-17 4.8-15.3 4.3-5.5 1.5-9.2 2.7-5.6 1.7c-23.9 7.8-37 34-29.2 58.2l1.1 3.5 30.8 42.9-13.8 10.3 20.6 64.3 12-3.9c45.8-15 70.9-65 56.1-111.3l-21.1-65.6z"
          fill="#d2f3e0"
        />
        <path
          d="M225.5 281.2l-8.1-11.2-8.1-11.2-5.3-7.4c-7.2-10-17.8-16.5-29.9-18.5-12-1.9-24.1 1-33.9 8.3l-2.4 1.7-30.9 42.7-13.7-10-54.1 39.7 7.5 10.5c13.7 19.1 34 31.6 57.1 35.3 23 3.6 46-2 64.8-15.8l55.5-40.7 3.6-2.7 3.6-2.6 4.7-3.4-10.4-14.7z"
          fill="#69aadd"
        />
        <path
          d="M96.4 266.6l3.6 2.6 4.6 3.3 10.4-14.3 8.2-11.3 8.2-11.3 5.1-7c14.2-19.6 10.4-46.9-8.1-62L73 148.7l6-18.2L25.8 92l-7.5 10.3c-28.1 38.8-19.5 93.3 19.2 121.5L92.8 264l3.6 2.6z"
          fill="#143c8c"
        />
        <path
          d="M119.7 154.9l12.8 4.1 2.8.9c22.5 7.3 46.6-3.8 55.9-24.9.6-1.4 1.2-2.8 1.6-4.3l2.6-8 4.2-13 9-27.6 1.4-4.2 1.4-4.2 20.1-61.6-11.9-3.9c-45.7-14.8-95 10.2-109.8 55.7l-20.7 63.4-1.4 4.2-1.4 4.2-2.4 7.5 22.9 7.4 12.9 4.3z"
          fill="#ff5847"
        />
      </g>
    </svg>
  );
};

export default Logo;

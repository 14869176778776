import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import styles from "./MessageSuccess.module.scss";

import CheckCircleIcon from "mdi-react/CheckCircleIcon";
import CloseIcon from "mdi-react/CloseIcon";

const MessageSuccess = ({text}) => {
  const [isHidden, setHidden] = useState(false);

  const { t } = useTranslation();

  const handleClick = e => {
    e.preventDefault();
    setHidden(true);
  };

  useEffect(() => {
    let hideTimer = window.setTimeout(() => {
      setHidden(true);
    }, 5000);

    return function cleanup() {
      window.clearTimeout(hideTimer);
    };
  });

  let className = styles.root;
  if (isHidden) className += ` ${styles.hidden}`;

  return (
    <div className={className}>
      <CheckCircleIcon />
      <div className={styles.text}>
        <strong>{!text ? t("Messages.sent_successfully") : text}</strong>
      </div>
      <button className={styles.button} onClick={handleClick}>
        <CloseIcon />
      </button>
    </div>
  );
};

export default MessageSuccess;

import * as Sentry from "@sentry/react";

import { useEffect } from "react";

import { tougoCookies, sentryConfig } from "../../shared";

const sentryDsn = process.env.REACT_APP_SENTRY_DSN;
const sentryEnvironment = process.REACT_APP_SENTRY_ENVIRONMENT || process.env.REACT_APP_CONTEXT || process.env.NODE_ENV;
const sentryRelease = process.env.REACT_APP_COMMIT_REF;

Sentry.init({
  ...sentryConfig,
  dsn: sentryDsn,
  environment: sentryEnvironment,
  release: sentryRelease,
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    // Sentry.reactRouterV6BrowserTracingIntegration({
    //   useEffect,
    //   useLocation,
    //   useNavigationType,
    //   createRoutesFromChildren,
    //   matchRoutes,
    // }),
    // Sentry.replayIntegration(),
  ],
});

const ErrorBoundary = ({ children }) => {
  useEffect(() => {
    Sentry.setUser({ id: tougoCookies.get("workerId") });
  }, []);

  return <Sentry.ErrorBoundary>{children}</Sentry.ErrorBoundary>;
};

export default ErrorBoundary;

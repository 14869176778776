import React from "react";
import styles from "./Avatar.module.scss";
import AccountIcon from "mdi-react/AccountIcon";
import AccountMultipleIcon from "mdi-react/AccountMultipleIcon";
import classNames from "classnames";
import Loader from "../Loader/Loader";

const Avatar = ({ initials, url, large, medium, white, square, group, loading, isSquare }) => {
  const avatarClassNames = classNames(styles.avatar, {
    [styles.large]: large,
    [styles.medium]: medium,
    [styles.square]: square,
    [styles.white]: white,
    [styles.group]: group,
    [styles.onlyIcon]: (!url && !initials) || group,
  });
  if (group) {
    return (
      <div className={avatarClassNames}>
        <AccountMultipleIcon className={styles.icon} />
      </div>
    );
  } else {
    return (
      <div
        className={avatarClassNames}
        style={url && { backgroundImage: `url('${url}')`, borderRadius: isSquare ? 20 : 256 }}
      >
        {!url && !loading && initials && initials.length > 0 && initials}
        {!url && !loading && !initials && <AccountIcon className={styles.icon} />}
        {loading && <Loader centered large />}
      </div>
    );
  }
};

export default Avatar;

import React from "react";
import styles from "./Button.module.scss";

import classNames from "classnames";

const Button = ({
  children,
  text,
  fullWidth,
  noMinWidth,
  wrapper,
  round,
  hasIcon,
  fab,
  outline,
  small,
  large,
  green,
  red,
  blue,
  white,
  greenText,
  redText,
  grayText,
  actionBarStyle,
  circle,
  className,
  narrow,
  ...otherProps
}) => {
  const buttonClassNames = classNames(styles.button, {
    [styles.fullWidth]: fullWidth,
    [styles.noMinWidth]: noMinWidth,
    [styles.round]: round,
    [styles.hasIcon]: hasIcon,
    [styles.greenText]: greenText,
    [styles.redText]: redText,
    [styles.fab]: fab,
    [styles.small]: small,
    [styles.large]: large,
    [styles.green]: green,
    [styles.red]: red,
    [styles.blue]: blue,
    [styles.white]: white,
    [styles.grayText]: grayText,
    [styles.actionBarStyle]: actionBarStyle,
    [styles.circle]: circle,
    [styles.narrow]: narrow,
    [className]: className,
  });

  const Component = wrapper || "button";

  return (
    <Component
      type={Component === "button" ? "button" : null}
      className={buttonClassNames}
      {...otherProps}
    >
      <span className={styles.text}>{children}</span>
    </Component>
  );
};

export default Button;

export const OutlineButton = ({
  children,
  fullWidth,
  noMinWidth,
  wrapper,
  round,
  hasIcon,
  small,
  red,
  blue,
  narrow,
  ...otherProps
}) => {
  const Component = wrapper || "button";

  const buttonClassNames = classNames(styles.outlineButton, {
    [styles.fullWidth]: fullWidth,
    [styles.noMinWidth]: noMinWidth,
    [styles.round]: round,
    [styles.hasIcon]: hasIcon,
    [styles.small]: small,
    [styles.outlineRed]: red,
    [styles.outlineBlue]: blue,
    [styles.narrow]: narrow,
  });

  return (
    <Component
      type={Component === "button" ? "button" : null}
      className={buttonClassNames}
      {...otherProps}
    >
      {children}
    </Component>
  );
};

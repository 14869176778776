import React, { useState } from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import { formatDateToTimeToday } from "../../../helpers/dateHelpers";
import Loader from "../../Loader/Loader";
import ErrorMessage from "../../ErrorMessage/ErrorMessage";
import styles from "./MessageSearchResult.module.scss";

import Avatar from "../../Avatar/Avatar";
import OverlappingAvatars from "../../OverlappingAvatars/OverlappingAvatars";

import Logo from "../../Logo/Logo";

import ChevronRightIcon from "mdi-react/ChevronRightIcon";
import ChevronUpIcon from "mdi-react/ChevronUpIcon";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import ForumIcon from "mdi-react/ForumIcon";
import MessageTextIcon from "mdi-react/MessageTextIcon";

const MessageSearchResult = ({
  result,
  search,
  messageType,
  searchTerm,
  staff,
}) => {
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(true);

  const firstRow = result.groupFirstRow;
  const type = firstRow.rowType;
  const collapsible =
    result.groupRowsCount > 1 && result.groupThreadsWithGuardianRepliesCount > 0
      ? true
      : false;

  let suffix = "";
  let id = firstRow.rowMessageThreadId;

  let thread_type;
  switch (type) {
    case "Announcement":
      thread_type = "announcement";
      break;
    case "Message":
      thread_type = "message";
      break;
    case "MessageThread":
      thread_type = "message_thread";
      break;
    default:
  }

  if (result.groupId && result.groupId.includes("Announcement")) {
    id = result.groupId.replace("Announcement/", "");
  }

  let to = {
    pathname: staff
      ? `/messages/${messageType}/${thread_type}/${id}`
      : `/messages/${thread_type}/${id}`,
    search: search,
  };

  if (
    result.groupRowsCount > 1 &&
    (type === "MessageThread" || type === "Message")
  ) {
    to.pathname = `/messages/${messageType}/thread_group/${firstRow.rowMessageThreadGroupId}`;
  }

  if (result.groupRowsCount === 1 && type === "Message") {
    to.hash = "#msg_" + firstRow.id;
  }

  const resultClassNames = classNames(styles.result, {
    [styles.thread]: type === "MessageThread",
    [styles.collapsible]: collapsible,
  });

  const excerpt = firstRow.highlight.replace(
    /<ts_highlight>|<\/ts_highlight>/g,
    "``"
  );

  let highlightedSubject = firstRow.rowSubject;
  const highlightedChildren = firstRow.rowChildren
    ? highlightString(firstRow.rowChildren, searchTerm)
    : null;

  if (type === "MessageThread" || type === "Announcement") {
    highlightedSubject = highlightString(firstRow.rowSubject, searchTerm);
  }

  const receivers =
    firstRow.rowMessageThreadGroup &&
    firstRow.rowMessageThreadGroup.receivers &&
    firstRow.rowMessageThreadGroup.receivers.length > 0
      ? firstRow.rowMessageThreadGroup.receivers
          .map((receiver) => receiver.name)
          .join(", ")
      : null;

  const highlightedReceivers = receivers
    ? highlightString(receivers, searchTerm)
    : null;

  const childNameInPlaceOfGuardian =
    firstRow.author.authorType === "GUARDIAN" &&
    staff &&
    firstRow.rowMessageThread &&
    firstRow.rowMessageThread.children &&
    firstRow.rowMessageThread.children.length > 0
      ? true
      : false;

  let overlappingAvatarData = null;
  if (
    childNameInPlaceOfGuardian &&
    firstRow.rowMessageThread.children.length > 1
  ) {
    overlappingAvatarData = [];
    firstRow.rowMessageThread.children.forEach((child) => {
      const childInitials = child.firstName[0] + child.lastName[0];
      const childAvatar = { url: undefined, initials: childInitials };
      overlappingAvatarData.push(childAvatar);
    });
  }

  return (
    <div className={styles.resultContainer}>
      <NavLink to={to} className={resultClassNames}>
        <div className={styles.resultHeader}>
          {(type === "Message" || type === "Announcement") && (
            <>
              <span className={styles.subjectLabel}>
                {t("MessageSearch.subject")}
              </span>
              <span>
                <ReactMarkdown
                  children={highlightedSubject}
                  unwrapDisallowed={true}
                  disallowedTypes={["link"]}
                />
              </span>
            </>
          )}
          {type === "MessageThread" &&
            highlightedChildren &&
            result.groupRowsCount === 1 && (
              <span className={styles.children}>
                <ReactMarkdown
                  children={highlightedChildren}
                  unwrapDisallowed={true}
                  disallowedTypes={["link", "paragraph"]}
                />
              </span>
            )}
          {type === "MessageThread" &&
            highlightedReceivers &&
            result.groupRowsCount > 1 && (
              <span className={styles.children}>
                <ReactMarkdown
                  children={highlightedReceivers}
                  unwrapDisallowed={true}
                  disallowedTypes={["link", "paragraph"]}
                />
              </span>
            )}
        </div>
        <div className={styles.content}>
          <div className={styles.avatar}>
            {type === "Announcement" ? (
              <Logo className={styles.logo} />
            ) : result.groupRowsCount > 1 && firstRow.rowIsReplyable ? (
              <div className={styles.groupIcon}>
                <ForumIcon />
              </div>
            ) : result.groupRowsCount > 1 && !firstRow.rowIsReplyable ? (
              <div className={styles.groupIcon}>
                <MessageTextIcon />
              </div>
            ) : childNameInPlaceOfGuardian ? (
              overlappingAvatarData ? (
                <OverlappingAvatars avatarData={overlappingAvatarData} />
              ) : (
                <Avatar
                  initials={
                    firstRow.rowMessageThread.children[0].firstName[0] +
                    firstRow.rowMessageThread.children[0].lastName[0]
                  }
                />
              )
            ) : (
              <Avatar
                initials={
                  firstRow.author.firstName[0] + firstRow.author.lastName[0]
                }
              />
            )}
          </div>
          <div className={styles.threadData}>
            <div className={styles.authorAndDate}>
              <span className={styles.author}>
                {childNameInPlaceOfGuardian ? (
                  firstRow.rowMessageThread.children
                    .map((children) => children.name)
                    .join(", ")
                ) : (
                  <>
                    {type === "Announcement" && "Touhula / "}
                    {firstRow.author.firstName}
                    {type !== "Announcement" && ` ${firstRow.author.lastName}`}
                  </>
                )}
              </span>
              <span className={styles.date}>
                {formatDateToTimeToday(firstRow.rowSentAt)}
              </span>
            </div>
            {type === "MessageThread" && (
              <div className={styles.threadSubject}>
                <ReactMarkdown
                  children={highlightedSubject}
                  unwrapDisallowed={true}
                  disallowedTypes={["link"]}
                />
              </div>
            )}
            {(type === "Message" || type === "Announcement") && (
              <div className={styles.excerpt}>
                <ReactMarkdown
                  children={excerpt}
                  unwrapDisallowed={true}
                  disallowedTypes={["link"]}
                />
              </div>
            )}
          </div>
        </div>
      </NavLink>
      {collapsible && !collapsed && (
        <Query
          query={THREADS_WITH_REPLIES}
          variables={{ id: firstRow.rowMessageThreadGroupId }}
        >
          {({ data, loading, error }) => {
            if (loading) {
              return (
                <div className={styles.loaderAndErrorContainer}>
                  <Loader
                    className="padding-12 padding-y"
                    centered
                    inline
                    text={t("Messages.loader")}
                  />
                </div>
              );
            }
            if (error) {
              return (
                <div className={styles.loaderAndErrorContainer}>
                  <ErrorMessage
                    className="padding-12 padding-y"
                    large
                    centered
                    error={error}
                  >
                    {t("Messages.load_error")}
                  </ErrorMessage>
                </div>
              );
            }

            if (
              data &&
              data.messageThreadGroup &&
              data.messageThreadGroup.threadsWithMessageFromGuardian &&
              data.messageThreadGroup.threadsWithMessageFromGuardian.length > 0
            ) {
              return data.messageThreadGroup.threadsWithMessageFromGuardian.map(
                (thread) => {
                  const to = {
                    pathname: `/messages/${messageType}/thread/${thread.id}`,
                    search: search,
                  };

                  const children = thread.children
                    .map((child) => child.name)
                    .join(", ");
                  const highlightedChildren = highlightString(
                    children,
                    searchTerm
                  );

                  return (
                    <NavLink
                      to={to}
                      key={thread.id}
                      className={styles.threadWithReplies}
                    >
                      <div className={styles.threadWithRepliesContent}>
                        <span className={styles.threadWithRepliesAuthor}>
                          <ReactMarkdown
                            children={highlightedChildren}
                            unwrapDisallowed={true}
                            disallowedTypes={["link", "paragraph"]}
                          />
                        </span>{" "}
                        {thread.recentMessageBody}
                      </div>
                      <ChevronRightIcon />
                    </NavLink>
                  );
                }
              );
            } else {
              return null;
            }
          }}
        </Query>
      )}
      {collapsible && (
        <button
          className={styles.collapseButton}
          onClick={() => setCollapsed(!collapsed)}
        >
          {collapsed
            ? t("MessageSearch.show_replies")
            : t("MessageSearch.hide_replies")}{" "}
          ({result.groupThreadsWithGuardianRepliesCount})
          {collapsed ? <ChevronDownIcon /> : <ChevronUpIcon />}
        </button>
      )}
    </div>
  );
};

const highlightString = (string, searchTerm) => {
  let highlightedString = string;
  const indexOfAppearance = string
    .toLowerCase()
    .indexOf(searchTerm.toLowerCase());

  if (indexOfAppearance >= 0) {
    highlightedString =
      string.substring(0, indexOfAppearance) +
      "``" +
      string.substring(
        indexOfAppearance,
        indexOfAppearance + searchTerm.length
      ) +
      "``" +
      string.substring(indexOfAppearance + searchTerm.length);
  }

  return highlightedString;
};

const THREADS_WITH_REPLIES = gql`
  query threadsWithReplies($id: ID!) {
    messageThreadGroup(id: $id) {
      id
      threadsWithMessageFromGuardian {
        id
        recentMessageBody
        children {
          id
          name
        }
      }
    }
  }
`;

export default MessageSearchResult;

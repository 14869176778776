import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import { formatDateToTimeToday } from "../../../helpers/dateHelpers";
import { useTranslation } from "react-i18next";

import Avatar from "../../Avatar/Avatar";
import OverlappingAvatars from "../../OverlappingAvatars/OverlappingAvatars";
import Logo from "../../Logo/Logo";
import UnreadBadge from "../UnreadBadge/UnreadBadge";

import styles from "./ThreadItem.module.scss";
import StarOutlineIcon from "mdi-react/StarOutlineIcon";
import StarIcon from "mdi-react/StarIcon";
import ArrowRightIcon from "mdi-react/ArrowRightIcon";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import ForumIcon from "mdi-react/ForumIcon";
import MessageTextIcon from "mdi-react/MessageTextIcon";
import CheckAllIcon from "mdi-react/CheckAllIcon";
import PackageDownIcon from "mdi-react/PackageDownIcon";
import PackageUpIcon from "mdi-react/PackageUpIcon";
import DotsHorizontalIcon from "mdi-react/DotsHorizontalIcon";
import AccountChildIcon from "mdi-react/AccountChildIcon";
import AccountTieIcon from "mdi-react/AccountTieIcon";

const ThreadItem = ({
  to,
  from,
  title,
  children,
  subtitle,
  profilePictureUrl,
  initials,
  receiver,
  date,
  unread,
  pinned,
  archived,
  handlePin,
  handleArchival,
  hidePinning,
  hideArchiving,
  count,
  iconType,
  onClickExpand,
  newAnnouncement,
  countReceivers,
  countReceiversByType,
  receiversReadCount,
  receiversTotalCount,
  avatarData,
  guardianReadCount,
  guardianRecipientCount,
  workerReadCount,
  workerRecipientCount,
  marketing,
  internal,
  workerTag = null
}) => {
  const { t } = useTranslation();
  const [expanded, toggleExpanded] = useState(false);
  const [optionsOpen, setOptionsOpen] = useState(false);

  const containerClassNames = classNames(styles.container, {
    [styles.unread]: unread > 0,
    [styles.pinned]: pinned,
    [styles.new]: newAnnouncement,
    [styles.expanded]: expanded
  });

  const optionsButtonClassNames = classNames(styles.optionsButtonInner, {
    [styles.optionsButtonActive]: optionsOpen
  });

  const wrapperClassNames = classNames(styles.wrapper, {
    [styles.expanded]: expanded
  });

  let titleText = t("Messages.ThreadItem.read_status");
  if (countReceiversByType)
    titleText = `${receiversReadCount} / ${receiversTotalCount} ${t(
      "Messages.MessageBox.seen_some"
    )}, ${t(
      "Messages.MessageBox.of_whom"
    )} ${workerReadCount} / ${workerRecipientCount} ${t(
      "Messages.MessageBox.workers"
    )} ${guardianReadCount} / ${guardianRecipientCount} ${t(
      "Messages.MessageBox.guardians"
    )}`;


  return (
    <div className={wrapperClassNames}>
      <NavLink
        to={to}
        className={containerClassNames}
        activeClassName={styles.active}
        exact
      >
        <div className={styles.left}>
          {iconType === "MULTIPLE_CHILDREN" ? (
            <OverlappingAvatars avatarData={avatarData} />
          ) : (
            <Icon
              iconType={iconType}
              profilePictureUrl={profilePictureUrl}
              initials={initials}
            />
          )}
          <UnreadBadge unread={unread} />
          {(handlePin || handleArchival) && (
            <React.Fragment>
              <button
                className={styles.optionsButton}
                onClick={e => {
                  e.preventDefault();
                  setOptionsOpen(!optionsOpen);
                }}
                tabIndex="0"
              >
                <div className={optionsButtonClassNames} tabIndex="-1">
                  <DotsHorizontalIcon />
                </div>
              </button>
              {optionsOpen && (
                <Options
                  setOptionsOpen={setOptionsOpen}
                  handlePin={handlePin}
                  hidePinning={hidePinning}
                  hideArchiving={hideArchiving}
                  handleArchival={handleArchival}
                  pinned={pinned}
                  archived={archived}
                />
              )}
            </React.Fragment>
          )}
        </div>
        <div className={styles.right}>
          <div className={styles.top}>
            <div className={styles.topLeft}>
              <div className={styles.from}>
                {from}
                {receiver && (
                  <React.Fragment>
                    <ArrowRightIcon className={styles.receiverIcon} />
                    {receiver}
                  </React.Fragment>
                )}
              </div>
              <div className={styles.title}>{title}</div>
            </div>
            <div className={styles.topRight}>

              {marketing && (
                <span className={styles.marketing}>
                  {t("Messages.marketing")}
                </span>
              )}
              {internal && (
                <span className={styles.internal}>
                  {t("Messages.internal")}
                </span>
              )}
              {pinned && (
                <StarIcon
                  className={styles.pinnedIcon}
                  size={20}
                  onClick={handlePin}
                />
              )}
            </div>
          </div>
          <div className={styles.bottom}>
            {subtitle && (
              <div className={styles.subtitle}>
                {subtitle.substring(0, 80).trim() + "..."}
              </div>
            )}
            {countReceivers && (
              <div className={styles.readCounts} title={titleText}>
                <span className={styles.readCount}>
                  <CheckAllIcon size={14} />
                  {receiversReadCount} / {receiversTotalCount}
                </span>
                {countReceiversByType && (
                  <>
                    <span className={styles.readCount}>
                      <AccountTieIcon size={14} />
                      {workerReadCount} / {workerRecipientCount}
                    </span>
                    <span className={styles.readCount}>
                      <AccountChildIcon size={14} />
                      {guardianReadCount} / {guardianRecipientCount}
                    </span>
                  </>
                )}
              </div>
            )}
            <div className={styles.date}>{formatDateToTimeToday(date)}</div>
          </div>
        </div>
        {count > 0 && (
          <button
            className={styles.expandCollapse}
            onClick={event => {
              event.preventDefault();
              toggleExpanded(!expanded);
              onClickExpand();
            }}
          >
            {" "}
            <div className={styles.expandCollapseInner}>
              <div className={styles.expandCollapseCount}>
                {count}{" "}
                {count === 1
                  ? t("Messages.ThreadItem.reply")
                  : t("Messages.ThreadItem.replies")}
              </div>
              <ChevronDownIcon className={styles.expandCollapseIcon} />
              <div className={styles.expandCollapseShow}>
                {t("Messages.ThreadItem.show")}
              </div>
            </div>
          </button>
        )}
      </NavLink>
      {expanded && <div className={styles.childWrapper}>{children}</div>}
    </div>
  );
};

const Options = ({
  setOptionsOpen,
  handlePin,
  handleArchival,
  pinned,
  archived,
  hidePinning,
  hideArchiving
}) => {
  useEffect(() => {
    const handleEscape = e => {
      if (e.key === "Escape") {
        setOptionsOpen(false);
      }
    };
    const handleClick = () => {
      setOptionsOpen(false);
    };

    window.addEventListener("keydown", handleEscape);
    window.addEventListener("click", handleClick);

    return function cleanup() {
      window.removeEventListener("keydown", handleEscape);
      window.removeEventListener("click", handleClick);
    };
  });
  return  (
    <div className={styles.options}>
      {handlePin && !hidePinning && <PinButton pinned={pinned} clickHandler={handlePin} />}
      {handleArchival && !hideArchiving && (
        <ArchiveButton archived={archived} clickHandler={handleArchival} />
      )}
    </div>
  );
};

const Icon = ({ iconType, profilePictureUrl, initials }) => {
  if (iconType === "MASS_ANNOUNCEMENT") {
    return (
      <div className={styles.iconContainer}>
        <Logo className={styles.logo} />
      </div>
    );
  } else if (iconType === "REPLYABLE") {
    return (
      <div className={styles.iconContainer}>
        <ForumIcon className={styles.icon} />
      </div>
    );
  } else if (iconType === "ANNOUNCEMENT") {
    return (
      <div className={styles.iconContainer}>
        <MessageTextIcon className={styles.iconSmall} />
      </div>
    );
  } else {
    return <Avatar url={profilePictureUrl} initials={initials} />;
  }
};

const PinButton = ({ pinned, clickHandler }) => {
  const { t } = useTranslation();
  const pinButtonClassNames = classNames(styles.pinButton, {
    [styles.pinned]: pinned
  });
  const Icon = pinned ? StarIcon : StarOutlineIcon;
  return (
    <button className={pinButtonClassNames} onClick={clickHandler}>
      <Icon className={styles.pinIcon} size={20} />
      <span>{pinned ? t("Messages.unpin") : t("Messages.pin")}</span>
    </button>
  );
};

const ArchiveButton = ({ archived, clickHandler }) => {
  const { t } = useTranslation();
  const archiveButtonClassNames = classNames(styles.archiveButton, {
    [styles.archived]: archived
  });
  const Icon = archived ? PackageUpIcon : PackageDownIcon;
  return (
    <button className={archiveButtonClassNames} onClick={clickHandler}>
      <Icon className={styles.archiveIcon} size={20} />
      <span>{archived ? t("Messages.unarchive") : t("Messages.archive")}</span>
    </button>
  );
};

export default ThreadItem;

import React from "react";
import { Link } from "react-router-dom";
import ArrowLeftIcon from "mdi-react/ArrowLeftIcon";
import styles from "./ProfileHeader.module.scss";
import Avatar from "../../Avatar/Avatar";
import Button from "../../Button/Button";
import ErrorMessage from "../../ErrorMessage/ErrorMessage";
import { useTranslation } from "react-i18next";

const ProfileHeader = ({
  child,
  backLink,
  compact = false,
  setModalOpen,
  setError,
  error,
  profilePictureUrl,
  previewUrl,
  removeProfilePicture,
  loading,
  showUploadingNotice,
  staff,
}) => {

  const { t } = useTranslation();
  const {profilePictureAllowed} = child;
  return (
    <div className={compact ? styles.compact : styles.large}>
      {backLink && (
        <Link className={styles.backLink} to={backLink}>
          <ArrowLeftIcon />
        </Link>
      )}
      <Avatar
        medium={!compact}
        white={!compact}
        large={staff}
        url={previewUrl ? previewUrl : profilePictureUrl}
        loading={loading}
        isSquare={staff}
        initials={child.firstName[0] + child.lastName[0]}
      />
      <h1 className={styles.name}>{child.name}</h1>
      {setModalOpen && profilePictureAllowed &&
        <ProfilePicButtons
          setError={(arg) => setError(arg)}
          setModalOpen={arg => setModalOpen(arg)}
          profilePictureUrl={profilePictureUrl}
          previewUrl={previewUrl}
          removeProfilePicture={() => removeProfilePicture()}
          t={t}
        />
      }
      {showUploadingNotice && <p style={{ textAlign: "center" }}>{t("ImageUpload.disclaimer")}</p>}
      {error && <ErrorMessage error={{ message: error }}>{t("General.loading_error")}</ErrorMessage>}
    </div>
  );
};

const ProfilePicButtons = ({setError, setModalOpen, previewUrl, profilePictureUrl, removeProfilePicture, t}) => {
  return(
    <div className={styles.buttonContainer}>
      <Button
        className={styles.buttonAddPic}
        small
        onClick={() => {
          setModalOpen(true);
          setError(null);
        }}
      >
        {t("Settings.add_profile_picture")}
      </Button>
      {profilePictureUrl && !previewUrl && (
        <Button
          small
          onClick={() => {
            removeProfilePicture();
            setError(null);
          }}
        >
          {t("Settings.remove_profile_picture")}
        </Button>
      )}
    </div>
  )
}

export default ProfileHeader;

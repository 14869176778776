import React from "react";
import { NavLink } from "react-router-dom";

// Styles
import styles from "./MessageTabs.module.scss";

export const MessageTabs = ({ children }) => {
  let className = styles.container;
  return <div className={className}>{children}</div>;
};

export const MessageTab = ({ to, label, icon, unread, active, disableNavigation}) => {
  let className = styles.button;
  if (active) className += ` ${styles.active}`;

    return (
      <NavLink to={to} className={className}>
        {icon && icon}
        {icon && unread && <div className={styles.unreadBadge}></div>}
        <div className={styles.text}>{label}</div>
      </NavLink>
    );
  

};

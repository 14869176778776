import React from "react";
import ErrorIcon from "mdi-react/ErrorIcon";
import classNames from "classnames";

import styles from "./ErrorMessage.module.scss";

const ErrorMessage = ({ children, error, centered, large, className }) => {
  const errorClassNames = classNames(styles.error, {
    [styles.centered]: centered,
    [styles.large]: large,
    [className]: className,
  });
  return (
    <div className={`${errorClassNames}`}>
      <div className={styles.inner}>
        <ErrorIcon className={styles.icon} />
        <div>
          {children}
          {error && error.message && <div className="size-small weight-400"> {error.message}</div>}
        </div>
      </div>
    </div>
  );
};
export default ErrorMessage;

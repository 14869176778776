import React, {useState, useEffect} from 'react'
import styles from "./RSVPSituation.module.scss";
import ErrorBoundary from "../../ErrorBoundary/ErrorBoundary";

const RSVPSituation = ({eventItem, data, t}) => {

  const {guardiansAllowed, responses} = eventItem
  const daycareChildren =  data.daycareCenter.children
  const daycareGroups = data.daycareCenter.daycareGroups
  const eventGroups = eventItem.daycareGroups

  const allGroupsInvited = daycareGroups.length === eventGroups.length


  let invitedChildren;
  if(eventGroups.length === daycareGroups.length || eventGroups.length === 0){
    invitedChildren = daycareChildren.length
  } else {
    invitedChildren = daycareChildren.filter((child) => {
      return child.activeDaycareGroups.some(group => {
        return eventGroups.some(eventGroup => eventGroup.id === group.id)
      })
    }).length
  }

  let attending = 0;
  let notAttending = 0;

  responses.forEach((response) => {
    attending = attending + response.children.length
    notAttending = notAttending + response.declinedChildren.length
  })


  const attendingChildren = attending
  const notAttendingChildren = notAttending
  const waitingResponse = invitedChildren - attendingChildren - notAttendingChildren


  return(
    <ErrorBoundary>
    <p className={styles.RSVPstatus}>
       {attendingChildren} {t("Eventcalendar.Participating").toLowerCase()}

      <span className={styles.dot}>.</span>

      {waitingResponse} {t("Eventcalendar.WaitingResponse").toLowerCase()}

      <span style={{position: 'relative', bottom: 3, marginLeft: 3, marginRight: 3}}>.</span>

      {notAttendingChildren} {t("Eventcalendar.NotParticipating").toLowerCase()}


    </p>
    {guardiansAllowed && <p className={styles.guardianText}>{t("Eventcalendar.GuardiansCanAttend")}</p>}
    </ErrorBoundary>
  )
}

export default RSVPSituation

import React, { useState, useEffect } from "react";
import gql from "graphql-tag";
import { Prompt } from "react-router-dom";
import { Query, Mutation } from "react-apollo";
import { useTranslation } from "react-i18next";
import styles from "./ChildVasu.module.scss";
import Link from "../Link/Link";
import Avatar from "../Avatar/Avatar";
import Button, { OutlineButton } from "../Button/Button";
import { ListCheckbox } from "../ListItem/ListItem";

import ChevronLeftIcon from "mdi-react/ChevronLeftIcon";
import CheckCircleIcon from "mdi-react/CheckCircleIcon";
import AlertOutlineIcon from "mdi-react/AlertOutlineIcon";

import RichTextEditor from "react-rte";
import ReactMarkdown from "react-markdown";
import { DateTime } from "luxon";
import { Formik, Field } from "formik";
import classNames from "classnames";

const DOCUMENT_TEMPLATE_VERSION = "2";

const toolbarConfig = {
  display: ["INLINE_STYLE_BUTTONS", "BLOCK_TYPE_BUTTONS", "HISTORY_BUTTONS"],
  extraProps: { tabIndex: "-1" },
  INLINE_STYLE_BUTTONS: [
    { label: "Bold", style: "BOLD" },
    { label: "Italic", style: "ITALIC" },
    { label: "Strikethrough", style: "STRIKETHROUGH" },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" },
  ],
};

const ChildVasu = ({
  child,
  data,
  published,
  publishedDocument,
  guardian,
  pdfLink,
  vasuSessionCreatedAt,
  removeVasuSession,
}) => {
  const { t } = useTranslation();

  const isDeprecated = (key) => {
    let deprecated = false;

    if (
      key === "developmentAndLearningSupport" ||
      key === "planDevising" ||
      key === "childNarration1" ||
      key === "childNarration2" ||
      key === "childNarration3" ||
      key === "presentInDiscussion"
    ) {
      deprecated = true;
    }

    return deprecated;
  };

  const getFieldValue = (key) => {
    const fieldValue =
      data.formFieldsData &&
      data.formFieldsData.length &&
      data.formFieldsData.find((field) => field.key === key);
    if (published || isDeprecated(key)) {
      if (fieldValue && fieldValue.value && fieldValue.value.length) {
        if (key === "supportPeriodDates") {
          return JSON.parse(fieldValue.value);
        } else {
          return fieldValue.value;
        }
      } else {
        if (key === "supportPeriodDates") {
          return {
            general: {
              given: [],
              repealed: [],
            },
            enhanced: {
              given: [],
              repealed: [],
            },
            special: {
              given: [],
              repealed: [],
            },
          };
        } else {
          return "";
        }
      }
    } else {
      if (fieldValue && fieldValue.value && fieldValue.value.length) {
        if (key === "currentSupportPeriod") {
          return fieldValue.value;
        } else if (key === "supportPeriodDates") {
          return JSON.parse(fieldValue.value);
        } else {
          return RichTextEditor.createValueFromString(
            fieldValue.value,
            "markdown"
          );
        }
      } else {
        if (key === "currentSupportPeriod") {
          return "";
        } else if (key === "supportPeriodDates") {
          return {
            general: {
              given: [],
              repealed: [],
            },
            enhanced: {
              given: [],
              repealed: [],
            },
            special: {
              given: [],
              repealed: [],
            },
          };
        } else {
          return RichTextEditor.createEmptyValue();
        }
      }
    }
  };

  const getPlainFieldValue = (key) => {
    const fieldValue =
      data.formFieldsData &&
      data.formFieldsData.length &&
      data.formFieldsData.find((field) => field.key === key);
    if (fieldValue) {
      return fieldValue.value;
    } else {
      return "";
    }
  };

  const getMatrixValue = (rowPos, columnPos, getId) => {
    const objectiveValue =
      data.objectiveValues &&
      data.objectiveValues.length &&
      data.objectiveValues.find(
        (objectiveValue) =>
          objectiveValue.documentRow.position === rowPos &&
          objectiveValue.documentColumn.position === columnPos
      );

    if (getId) {
      if (objectiveValue) {
        return objectiveValue.id;
      } else {
        return undefined;
      }
    } else if (published) {
      if (objectiveValue) {
        return objectiveValue.value;
      } else {
        return "";
      }
    } else {
      if (objectiveValue) {
        return RichTextEditor.createValueFromString(
          objectiveValue.value,
          "markdown"
        );
      } else {
        return RichTextEditor.createEmptyValue();
      }
    }
  };

  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [publishPrompt, setPublishPrompt] = useState(false);

  const [saveSuccessVisible, setSaveSuccessVisible] = useState(false);
  const [saveSuccessFadingOut, setSaveSuccessFadingOut] = useState(false);

  const [publishSuccessVisible, setPublishSuccessVisible] = useState(false);
  const [publishSuccessFadingOut, setPublishSuccessFadingOut] = useState(false);

  const [errorVisible, setErrorVisible] = useState(false);
  const [errorFadingOut, setErrorFadingOut] = useState(false);

  const [timeRemaining, setTimeRemaining] = useState(null);
  const [timeWarning, setTimeWarning] = useState(false);

  const [childLanguages, setChildLanguages] = useState(
    getPlainFieldValue("childLanguages")
  );

  const planDevising = getFieldValue("planDevising");
  const [planDevisingOne, setPlanDevisingOne] = useState(
    getFieldValue("planDevisingOne")
  );
  const [planDevisingTwo, setPlanDevisingTwo] = useState(
    getFieldValue("planDevisingTwo")
  );
  const [planDevisingThree, setPlanDevisingThree] = useState(
    getFieldValue("planDevisingThree")
  );
  const [planDevisingFour, setPlanDevisingFour] = useState(
    getFieldValue("planDevisingFour")
  );

  const [multiCooperationOne, setMultiCooperationOne] = useState(
    getFieldValue("multiCooperationOne")
  );
  const [multiCooperationTwo, setMultiCooperationTwo] = useState(
    getFieldValue("multiCooperationTwo")
  );

  const [strengthsAndInterests1, setStrengthsAndInterests1] = useState(
    getFieldValue("strengthsAndInterests1")
  );
  const [strengthsAndInterests2, setStrengthsAndInterests2] = useState(
    getFieldValue("strengthsAndInterests2")
  );

  const [childNarration, setChildNarration] = useState(
    getFieldValue("childNarration")
  );
  const childNarration1 = getFieldValue("childNarration1");
  const childNarration2 = getFieldValue("childNarration2");
  const childNarration3 = getFieldValue("childNarration3");
  const [notesOnPreviousPlan, setNotesOnPreviousPlan] = useState(
    getFieldValue("notesOnPreviousPlan")
  );

  const [row1Col1, setRow1Col1] = useState(getMatrixValue(1, 1));
  const [row1Col2, setRow1Col2] = useState(getMatrixValue(1, 2));
  const [row1Col3, setRow1Col3] = useState(getMatrixValue(1, 3));

  const [row2Col1, setRow2Col1] = useState(getMatrixValue(2, 1));
  const [row2Col2, setRow2Col2] = useState(getMatrixValue(2, 2));
  const [row2Col3, setRow2Col3] = useState(getMatrixValue(2, 3));

  const [row3Col1, setRow3Col1] = useState(getMatrixValue(3, 1));
  const [row3Col2, setRow3Col2] = useState(getMatrixValue(3, 2));
  const [row3Col3, setRow3Col3] = useState(getMatrixValue(3, 3));

  const [row4Col1, setRow4Col1] = useState(getMatrixValue(4, 1));
  const [row4Col2, setRow4Col2] = useState(getMatrixValue(4, 2));
  const [row4Col3, setRow4Col3] = useState(getMatrixValue(4, 3));

  const [row5Col1, setRow5Col1] = useState(getMatrixValue(5, 1));
  const [row5Col2, setRow5Col2] = useState(getMatrixValue(5, 2));
  const [row5Col3, setRow5Col3] = useState(getMatrixValue(5, 3));

  const [row6Col1, setRow6Col1] = useState(getMatrixValue(6, 1));
  const [row6Col2, setRow6Col2] = useState(getMatrixValue(6, 2));
  const [row6Col3, setRow6Col3] = useState(getMatrixValue(6, 3));

  const developmentAndLearningSupport = getFieldValue(
    "developmentAndLearningSupport"
  );
  const currentSupportPeriodDates = getFieldValue("supportPeriodDates");
  const [supportPeriod, setSupportPeriod] = useState(
    getFieldValue("currentSupportPeriod")
  );
  const [showGiven, setShowGiven] = useState(null);
  const [showRepealed, setShowRepealed] = useState(null);
  const [newGivenSupport, setNewGivenSupport] = useState("");
  const [newRepealedSupport, setNewRepealedSupport] = useState("");
  const [supportPeriodDates, setSupportPeriodDates] = useState(
    getFieldValue("supportPeriodDates")
  );

  const [supportOneOne, setSupportOneOne] = useState(
    getFieldValue("supportOneOne")
  );
  const [supportOneTwo, setSupportOneTwo] = useState(
    getFieldValue("supportOneTwo")
  );
  const [supportOneThree, setSupportOneThree] = useState(
    getFieldValue("supportOneThree")
  );
  const [supportOneFour, setSupportOneFour] = useState(
    getFieldValue("supportOneFour")
  );

  const [supportTwoOne, setSupportTwoOne] = useState(
    getFieldValue("supportTwoOne")
  );
  const [supportTwoTwo, setSupportTwoTwo] = useState(
    getFieldValue("supportTwoTwo")
  );
  const [supportTwoThree, setSupportTwoThree] = useState(
    getFieldValue("supportTwoThree")
  );
  const [supportTwoFour, setSupportTwoFour] = useState(
    getFieldValue("supportTwoFour")
  );

  const [otherFactors, setOtherFactors] = useState(
    getFieldValue("otherFactors")
  );
  const [otherDocumentsAndPlans, setOtherDocumentsAndPlans] = useState(
    getFieldValue("otherDocumentsAndPlans")
  );
  const [monitoringAndEvaluation, setMonitoringAndEvaluation] = useState(
    getFieldValue("monitoringAndEvaluation")
  );
  const presentInDiscussion = getFieldValue("presentInDiscussion");

  const [pageScrolled, setPageScrolled] = useState(false);
  const { id, name, firstName, lastName } = child;
  const profilePictureUrl =
    child.profilePicture &&
    child.profilePicture.extraSmall &&
    child.profilePicture.extraSmall.url
      ? child.profilePicture.extraSmall.url
      : undefined;

  useEffect(() => {
    const cur = getFieldValue("currentSupportPeriod");
    if (supportPeriod === cur || (!cur && !supportPeriod)) {
      setShowGiven(null);
      setShowRepealed(null);
    } else if ((cur && cur.length) || (supportPeriod && supportPeriod.length)) {
      if (cur && cur.length) {
        setShowRepealed(cur);
      }
      if (supportPeriod && supportPeriod.length) {
        setShowGiven(supportPeriod);
      }
    }
  }, [supportPeriod]);

  useEffect(() => {
    const scrollContent = document.getElementById("scrollContent");
    const handleScroll = () => {
      if (scrollContent.scrollTop > 65 && !pageScrolled) {
        setPageScrolled(true);
      } else if (scrollContent.scrollTop <= 65 && pageScrolled) {
        setPageScrolled(false);
      }
    };
    scrollContent.addEventListener("scroll", handleScroll);

    let saveFadeoutTimer;
    let saveHideTimer;

    let errorFadeoutTimer;
    let errorHideTimer;

    let publishFadeoutTimer;
    let publishHideTimer;

    let sessionRemainingTimer;

    if (vasuSessionCreatedAt && removeVasuSession) {
      const sessionExpiresAt = DateTime.fromISO(vasuSessionCreatedAt).plus({
        hour: 1,
      });
      sessionRemainingTimer = window.setInterval(() => {
        const now = DateTime.local();
        const remaining = sessionExpiresAt
          .diff(now, ["minutes", "seconds"])
          .toObject();
        const remainingSeconds = sessionExpiresAt
          .diff(now, ["seconds"])
          .toObject();

        setTimeRemaining(
          Math.floor(remaining.minutes).toString().padStart(2, "0") +
            ":" +
            Math.floor(remaining.seconds).toString().padStart(2, "0")
        );

        if (remainingSeconds.seconds < 180 && !timeWarning) {
          setTimeWarning(true);
        }

        if (remainingSeconds.seconds < 0) {
          window.clearInterval(sessionRemainingTimer);
          removeVasuSession();
        }
      }, 1000);
    }

    if (saveSuccessVisible) {
      saveFadeoutTimer = window.setTimeout(() => {
        setSaveSuccessFadingOut(true);
      }, 2000);
      saveHideTimer = window.setTimeout(() => {
        setSaveSuccessFadingOut(false);
        setSaveSuccessVisible(false);
      }, 2500);
    }

    if (errorVisible) {
      errorFadeoutTimer = window.setTimeout(() => {
        setErrorFadingOut(true);
      }, 5000);
      errorHideTimer = window.setTimeout(() => {
        setErrorFadingOut(false);
        setErrorVisible(false);
      }, 5500);
    }

    if (publishSuccessVisible) {
      publishFadeoutTimer = window.setTimeout(() => {
        setPublishSuccessFadingOut(true);
      }, 2000);
      publishHideTimer = window.setTimeout(() => {
        setPublishSuccessFadingOut(false);
        setPublishSuccessVisible(false);
      }, 2500);
    }

    return () => {
      window.clearTimeout(saveFadeoutTimer);
      window.clearTimeout(saveHideTimer);
      window.clearTimeout(errorFadeoutTimer);
      window.clearTimeout(errorHideTimer);
      window.clearTimeout(publishFadeoutTimer);
      window.clearTimeout(publishHideTimer);
      window.clearInterval(sessionRemainingTimer);
      scrollContent.removeEventListener("scroll", handleScroll);
    };
  }, [pageScrolled, saveSuccessVisible, publishSuccessVisible, errorVisible]);

  let backLinkClassNames = styles.backLink;
  if (pageScrolled) backLinkClassNames += ` ${styles.backLinkScrolled}`;

  const handleChange = (setter, value) => {
    if (!unsavedChanges) setUnsavedChanges(true);
    if (publishPrompt) setPublishPrompt(false);
    setter(value);
  };

  const getRow = (pos, publish) => {
    let document = data;
    if (publish) document = publishedDocument;
    return document.documentRows.find((row) => row.position === pos).id;
  };

  const getCol = (pos, publish) => {
    let document = data;
    if (publish) document = publishedDocument;
    return document.documentColumns.find((column) => column.position === pos)
      .id;
  };

  const getObjectiveValues = (publish) => {
    let values = [];

    const getField = (row, col) => {
      let value;

      if (row === 1 && col === 1) value = row1Col1;
      if (row === 1 && col === 2) value = row1Col2;
      if (row === 1 && col === 3) value = row1Col3;

      if (row === 2 && col === 1) value = row2Col1;
      if (row === 2 && col === 2) value = row2Col2;
      if (row === 2 && col === 3) value = row2Col3;

      if (row === 3 && col === 1) value = row3Col1;
      if (row === 3 && col === 2) value = row3Col2;
      if (row === 3 && col === 3) value = row3Col3;

      if (row === 4 && col === 1) value = row4Col1;
      if (row === 4 && col === 2) value = row4Col2;
      if (row === 4 && col === 3) value = row4Col3;

      if (row === 5 && col === 1) value = row5Col1;
      if (row === 5 && col === 2) value = row5Col2;
      if (row === 5 && col === 3) value = row5Col3;

      if (row === 6 && col === 1) value = row6Col1;
      if (row === 6 && col === 2) value = row6Col2;
      if (row === 6 && col === 3) value = row6Col3;

      return value.toString("markdown");
    };

    for (let rowIndex = 1; rowIndex <= 6; rowIndex++) {
      let cont = false;
      if (rowIndex === 6) {
        let document = data;
        if (publish) document = publishedDocument;
        if (document.documentRows.find((row) => row.position === 6)) {
          cont = true;
        }
      } else {
        cont = true;
      }

      if (cont) {
        for (let colIndex = 1; colIndex <= 3; colIndex++) {
          let value = {
            value: getField(rowIndex, colIndex),
            documentRow: { id: getRow(rowIndex, publish) },
            documentColumn: { id: getCol(colIndex, publish) },
          };
          values.push(value);
        }
      }
    }
    return values;
  };

  const getSupportDates = () => {
    let supportPeriodDates = getFieldValue("supportPeriodDates");

    if (showGiven && newGivenSupport) {
      supportPeriodDates[showGiven].given.push(newGivenSupport);
    }

    if (showRepealed && newRepealedSupport) {
      supportPeriodDates[showRepealed].repealed.push(newRepealedSupport);
    }

    return supportPeriodDates;
  };

  const getFormFieldsData = (publish) => {
    const supportPeriodDates = JSON.stringify(getSupportDates());

    let values = [
      { key: "version", value: DOCUMENT_TEMPLATE_VERSION },
      { key: "childLanguages", value: childLanguages },
      { key: "planDevising", value: planDevising.toString("markdown") },
      { key: "planDevisingOne", value: planDevisingOne.toString("markdown") },
      { key: "planDevisingTwo", value: planDevisingTwo.toString("markdown") },
      {
        key: "planDevisingThree",
        value: planDevisingThree.toString("markdown"),
      },
      { key: "planDevisingFour", value: planDevisingFour.toString("markdown") },
      {
        key: "multiCooperationOne",
        value: multiCooperationOne.toString("markdown"),
      },
      {
        key: "multiCooperationTwo",
        value: multiCooperationTwo.toString("markdown"),
      },
      {
        key: "strengthsAndInterests1",
        value: strengthsAndInterests1.toString("markdown"),
      },
      {
        key: "strengthsAndInterests2",
        value: strengthsAndInterests2.toString("markdown"),
      },
      { key: "childNarration", value: childNarration.toString("markdown") },
      { key: "childNarration1", value: childNarration1.toString("markdown") },
      { key: "childNarration2", value: childNarration2.toString("markdown") },
      { key: "childNarration3", value: childNarration3.toString("markdown") },
      {
        key: "notesOnPreviousPlan",
        value: notesOnPreviousPlan.toString("markdown"),
      },
      {
        key: "developmentAndLearningSupport",
        value: developmentAndLearningSupport.toString("markdown"),
      },
      {
        key: "currentSupportPeriod",
        value: supportPeriod,
      },
      {
        key: "supportPeriodDates",
        value: supportPeriodDates,
      },
      {
        key: "supportOneOne",
        value: supportOneOne.toString("markdown"),
      },
      {
        key: "supportOneTwo",
        value: supportOneTwo.toString("markdown"),
      },
      {
        key: "supportOneThree",
        value: supportOneThree.toString("markdown"),
      },
      {
        key: "supportOneFour",
        value: supportOneFour.toString("markdown"),
      },
      {
        key: "supportTwoOne",
        value: supportTwoOne.toString("markdown"),
      },
      {
        key: "supportTwoTwo",
        value: supportTwoTwo.toString("markdown"),
      },
      {
        key: "supportTwoThree",
        value: supportTwoThree.toString("markdown"),
      },
      {
        key: "supportTwoFour",
        value: supportTwoFour.toString("markdown"),
      },
      { key: "otherFactors", value: otherFactors.toString("markdown") },
      {
        key: "otherDocumentsAndPlans",
        value: otherDocumentsAndPlans.toString("markdown"),
      },
      {
        key: "monitoringAndEvaluation",
        value: monitoringAndEvaluation.toString("markdown"),
      },
      {
        key: "presentInDiscussion",
        value: presentInDiscussion.toString("markdown"),
      },
    ];
    if (publish) values.push({ key: "published", value: "true" });

    return values;
  };

  const lowercaseFirstLetter = (word) => {
    return word.charAt(0).toLowerCase() + word.slice(1);
  };

  const containerClassNames = classNames(styles.container, {
    [styles.guardian]: guardian,
  });

  const timerClassNames = classNames(styles.timer, {
    [styles.timeRed]: timeWarning,
  });

  return (
    <div className={containerClassNames}>
      <Prompt
        when={unsavedChanges}
        message={t("Vasu.unsaved_changes_prompt")}
      />
      <div className={styles.nav}>
        <div className={styles.navInner}>
          <Link
            className={backLinkClassNames}
            to={{ pathname: `/vasu/documents/${id}` }}
            getSearch={!guardian}
          >
            <ChevronLeftIcon />
            <div className={styles.backLinkContent}>
              <div className={styles.childMeta}>
                <Avatar
                  initials={firstName[0] + lastName[0]}
                  url={profilePictureUrl}
                />
                <span className={styles.childName}>
                  {firstName}
                  <span className={styles.childLastName}> {lastName}</span>
                </span>
              </div>
              <span>{t("Vasu.back")}</span>
            </div>
          </Link>

          <div className={styles.headerButtons}>
            {timeRemaining && (
              <div className={timerClassNames}>
                <div className={styles.timerLabel}>
                  {t("Vasu.time_remaining")}
                </div>
                <div className={styles.timerTimer}>{timeRemaining}</div>
              </div>
            )}
            <div className={styles.headerButton}>
              {guardian && pdfLink ? (
                pdfLink
              ) : (
                <a
                  className={styles.printLink}
                  href={`/vasu/documents/${id}/pdf/${data.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <OutlineButton round blue narrow>
                    {t("Vasu.print")}
                  </OutlineButton>
                </a>
              )}
            </div>
            {!published && (
              <>
                <div className={styles.headerButton}>
                  <Mutation
                    mutation={UPDATE_DOCUMENT}
                    refetchQueries={["earlyEducationDocument"]}
                  >
                    {(updateEarlyEducationDocument, { loading, error }) => {
                      const updateDocument = () => {
                        if (
                          (showGiven && !newGivenSupport) ||
                          (showRepealed && !newRepealedSupport)
                        ) {
                          setErrorVisible(true);
                        } else {
                          updateEarlyEducationDocument({
                            variables: {
                              document: { id: data.id },
                              objectiveValues: getObjectiveValues(false),
                              formFieldsData: getFormFieldsData(false),
                            },
                          }).then((response) => {
                            if (response) {
                              setSupportPeriodDates(getSupportDates());
                              setUnsavedChanges(false);
                              setSaveSuccessVisible(true);
                              setShowGiven(false);
                              setShowRepealed(false);
                              setNewGivenSupport("");
                              setNewRepealedSupport("");
                            }
                          });
                        }
                      };

                      if (error) {
                        console.error(error);
                      }

                      return (
                        <Button
                          round
                          blue
                          narrow
                          disabled={!unsavedChanges || loading}
                          onClick={() => updateDocument()}
                        >
                          {t("Vasu.save_as_draft")}
                        </Button>
                      );
                    }}
                  </Mutation>
                </div>
                <div className={styles.headerButton}>
                  <Mutation mutation={UPDATE_DOCUMENT}>
                    {(updateEarlyEducationDocument, { loading, error }) => {
                      const updateDocument = () => {
                        updateEarlyEducationDocument({
                          variables: {
                            document: { id: publishedDocument.id },
                            objectiveValues: getObjectiveValues(true),
                            formFieldsData: getFormFieldsData(true),
                          },
                        }).then((response) => {
                          if (response) {
                            setUnsavedChanges(false);
                            setPublishSuccessVisible(true);
                          }
                        });
                      };

                      return (
                        <div className={styles.publishButton}>
                          <Button
                            narrow
                            round
                            disabled={unsavedChanges || loading}
                            onClick={() => setPublishPrompt(true)}
                          >
                            {t("Vasu.publish_for_guardians")}
                          </Button>
                          {unsavedChanges && (
                            <div className={styles.publishTooltip}>
                              {t("Vasu.save_changes_first")}
                            </div>
                          )}
                          {publishPrompt && (
                            <div className={styles.userPrompt}>
                              <div>{t("Vasu.publish_info")}</div>
                              <div className={styles.userPromptButtons}>
                                <div className={styles.userPromptButton}>
                                  <OutlineButton
                                    fullWidth
                                    onClick={() => {
                                      setPublishPrompt(false);
                                    }}
                                  >
                                    {t("Vasu.cancel")}
                                  </OutlineButton>
                                </div>
                                <div className={styles.userPromptButton}>
                                  <Button
                                    fullWidth
                                    onClick={() => {
                                      setPublishPrompt(false);
                                      updateDocument();
                                    }}
                                  >
                                    {t("Vasu.publish")}
                                  </Button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    }}
                  </Mutation>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {timeWarning && (
        <div className={styles.timeWarning}>
          <AlertOutlineIcon />
          <div>
            <span>{t("Vasu.session_expiring")}. </span>
            {t("Vasu.save_reminder")}.
          </div>
        </div>
      )}

      <div className={styles.content} id="scrollContent">
        {errorVisible && (
          <div
            className={
              errorFadingOut
                ? `${styles.error} ${styles.fadingOut}`
                : styles.error
            }
          >
            <AlertOutlineIcon />
            <span>{t("Vasu.given_or_repealed_error")}</span>
          </div>
        )}
        {saveSuccessVisible && (
          <div
            className={
              saveSuccessFadingOut
                ? `${styles.success} ${styles.fadingOut}`
                : styles.success
            }
          >
            <CheckCircleIcon />
            <span>{t("Vasu.changes_saved")}</span>
          </div>
        )}
        {publishSuccessVisible && (
          <div
            className={
              publishSuccessFadingOut
                ? `${styles.publishSuccess} ${styles.fadingOut}`
                : styles.publishSuccess
            }
          >
            <CheckCircleIcon />
            <span>{t("Vasu.plan_published")}</span>
          </div>
        )}

        <div className={styles.header}>
          <h1 className={styles.childTitle}>
            <Avatar
              initials={firstName[0] + lastName[0]}
              url={profilePictureUrl}
            />
            <span>{name}</span>
          </h1>
          <h2 className={styles.subtitle}>
            {t("Vasu.child_plan")}
            {!guardian && " – "}
            {!guardian &&
              (published
                ? lowercaseFirstLetter(t("Vasu.published_version"))
                : lowercaseFirstLetter(t("Vasu.draft")))}
          </h2>
          <div className={styles.metaContainer}>
            <div className={styles.meta}>
              <div className={styles.metaItem}>
                <span className={styles.metaLabelShort}>{t("Vasu.born")}</span>
                <span className={styles.metaValue}>
                  {DateTime.fromISO(child.dateOfBirth).toFormat("d.L.yyyy")}
                </span>
              </div>
              <div className={styles.metaItem}>
                <span className={styles.metaLabelShort}>
                  {t("Vasu.daycare")}
                </span>
                <span className={styles.metaValue}>
                  {child.daycareCenters && child.daycareCenters.length > 0
                    ? child.daycareCenters[0].name
                    : "–"}
                </span>
              </div>
              <div className={styles.metaItem}>
                <span className={styles.metaLabelShort}>{t("Vasu.group")}</span>
                <span className={styles.metaValue}>
                  {child.activeDaycareGroups &&
                  child.activeDaycareGroups.length > 0
                    ? child.activeDaycareGroups[0].name
                    : "–"}
                </span>
              </div>
            </div>
            <div className={styles.metaDividerContainer}>
              <div className={styles.metaDivider}></div>
            </div>
            <div className={styles.meta}>
              {!published && (
                <div className={styles.metaItem}>
                  <span className={styles.metaLabelLong}>
                    {t("Vasu.created")}
                  </span>
                  <span className={styles.metaValue}>
                    {DateTime.fromISO(data.createdAt).toFormat(
                      "d.L.yyyy, HH:mm"
                    )}
                  </span>
                </div>
              )}
              <div className={styles.metaItem}>
                <span
                  className={
                    published ? styles.metaLabelShort : styles.metaLabelLong
                  }
                >
                  {published ? t("Vasu.published") : t("Vasu.last_edited")}
                </span>
                <span className={styles.metaValue}>
                  {DateTime.fromISO(data.updatedAt).toFormat("d.L.yyyy, HH:mm")}
                </span>
              </div>
              <div className={styles.metaItem}>
                <span
                  className={
                    published ? styles.metaLabelShort : styles.metaLabelLong
                  }
                >
                  {published
                    ? t("Vasu.published_by")
                    : t("Vasu.last_edited_by")}
                </span>
                <span className={styles.metaValue}>
                  {data.lastUpdateAuthor.firstName}{" "}
                  {data.lastUpdateAuthor.lastName}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.document}>
          <div className={styles.documentInner}>
            <div className={styles.section}>
              <div className={styles.sectionIndicatorRed}></div>
              <h2 className={styles.sectionTitle}>
                1. {t("Vasu.home_languages")}
              </h2>
              <div className={styles.editorFlexContainerNarrow}>
                <div className={styles.plainValueWrapper}>
                  {published ? (
                    <div className={styles.markdownContainer}>
                      {childLanguages}
                    </div>
                  ) : (
                    <Formik>
                      <Field
                        type="text"
                        value={childLanguages}
                        onChange={(e) =>
                          handleChange(setChildLanguages, e.target.value)
                        }
                        placeholder={t("Vasu.write")}
                      />
                    </Formik>
                  )}
                </div>
              </div>
            </div>

            <div className={styles.section}>
              <div className={styles.sectionIndicatorOrange}></div>
              <h2 className={styles.sectionTitle}>
                2. {t("Vasu.authors_of_plan")}
              </h2>
              <div className={styles.editorFlexContainerNarrow}>
                <div className={styles.editorWrapper}>
                  <h3 className={styles.questionTitle}>
                    {t("Vasu.authors_of_plan_description")}
                  </h3>
                  {planDevising && planDevising.length > 2 && (
                    <div className={styles.deprecatedContainer}>
                      <label className={styles.deprecatedFieldLabel}>
                        {t("Vasu.deprecated")}
                      </label>
                      <ReactMarkdown children={planDevising} />
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.editorFlexContainerNarrow}>
                <div className={styles.editorWrapper}>
                  <h3 className={styles.largeQuestionTitle}>
                    {t("Vasu.authors_of_plan_one")}
                  </h3>
                  {published ? (
                    <div className={styles.markdownContainer}>
                      <ReactMarkdown children={planDevisingOne} />
                    </div>
                  ) : (
                    <RichTextEditor
                      value={planDevisingOne}
                      onChange={(value) =>
                        handleChange(setPlanDevisingOne, value)
                      }
                      toolbarConfig={toolbarConfig}
                      placeholder={t("Vasu.write")}
                    />
                  )}
                </div>
              </div>
              <div className={styles.editorFlexContainerNarrow}>
                <div className={styles.editorWrapper}>
                  <h3 className={styles.largeQuestionTitle}>
                    {t("Vasu.authors_of_plan_two")}
                  </h3>
                  {published ? (
                    <div className={styles.markdownContainer}>
                      <ReactMarkdown children={planDevisingTwo} />
                    </div>
                  ) : (
                    <RichTextEditor
                      value={planDevisingTwo}
                      onChange={(value) =>
                        handleChange(setPlanDevisingTwo, value)
                      }
                      toolbarConfig={toolbarConfig}
                      placeholder={t("Vasu.write")}
                    />
                  )}
                </div>
              </div>
              <div className={styles.editorFlexContainerNarrow}>
                <div className={styles.editorWrapper}>
                  <h3 className={styles.largeQuestionTitle}>
                    {t("Vasu.authors_of_plan_three")}
                  </h3>
                  {published ? (
                    <div className={styles.markdownContainer}>
                      <ReactMarkdown children={planDevisingThree} />
                    </div>
                  ) : (
                    <RichTextEditor
                      value={planDevisingThree}
                      onChange={(value) =>
                        handleChange(setPlanDevisingThree, value)
                      }
                      toolbarConfig={toolbarConfig}
                      placeholder={t("Vasu.write")}
                    />
                  )}
                </div>
              </div>
              <div className={styles.editorFlexContainerNarrow}>
                <div className={styles.editorWrapper}>
                  <h3 className={styles.largeQuestionTitle}>
                    {t("Vasu.authors_of_plan_four")}
                  </h3>
                  {published ? (
                    <div className={styles.markdownContainer}>
                      <ReactMarkdown children={planDevisingFour} />
                    </div>
                  ) : (
                    <RichTextEditor
                      value={planDevisingFour}
                      onChange={(value) =>
                        handleChange(setPlanDevisingFour, value)
                      }
                      toolbarConfig={toolbarConfig}
                      placeholder={t("Vasu.write")}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className={styles.section}>
              <div className={styles.sectionIndicatorLightred}></div>
              <h2 className={styles.sectionTitle}>
                3. {t("Vasu.multidisciplinary_cooperation")}
              </h2>
              <div className={styles.editorFlexContainerNarrow}>
                <div className={styles.editorWrapper}>
                  <h3 className={styles.largeQuestionTitle}>
                    {t("Vasu.multidisciplinary_cooperation_one")}
                  </h3>
                  {published ? (
                    <div className={styles.markdownContainer}>
                      <ReactMarkdown children={multiCooperationOne} />
                    </div>
                  ) : (
                    <RichTextEditor
                      value={multiCooperationOne}
                      onChange={(value) =>
                        handleChange(setMultiCooperationOne, value)
                      }
                      toolbarConfig={toolbarConfig}
                      placeholder={t("Vasu.write")}
                    />
                  )}
                </div>
              </div>
              <div className={styles.editorFlexContainerNarrow}>
                <div className={styles.editorWrapper}>
                  <h3 className={styles.largeQuestionTitle}>
                    {t("Vasu.multidisciplinary_cooperation_two")}
                  </h3>
                  {published ? (
                    <div className={styles.markdownContainer}>
                      <ReactMarkdown children={multiCooperationTwo} />
                    </div>
                  ) : (
                    <RichTextEditor
                      value={multiCooperationTwo}
                      onChange={(value) =>
                        handleChange(setMultiCooperationTwo, value)
                      }
                      toolbarConfig={toolbarConfig}
                      placeholder={t("Vasu.write")}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className={styles.section}>
              <div className={styles.sectionIndicatorYellow}></div>
              <h2 className={styles.sectionTitle}>
                4. {t("Vasu.strengths_and_interests")}
              </h2>
              <h3 className={styles.questionTitle}>
                {t("Vasu.strengths_and_interests_description")}
              </h3>
              <div className={styles.editorFlexContainerNarrow}>
                <div className={styles.editorWrapper}>
                  <h3 className={styles.largeQuestionTitle}>
                    {t("Vasu.centre_comments")}
                  </h3>
                  {published ? (
                    <div className={styles.markdownContainer}>
                      <ReactMarkdown children={strengthsAndInterests1} />
                    </div>
                  ) : (
                    <RichTextEditor
                      value={strengthsAndInterests1}
                      onChange={(value) =>
                        handleChange(setStrengthsAndInterests1, value)
                      }
                      toolbarConfig={toolbarConfig}
                      placeholder={t("Vasu.write")}
                    />
                  )}
                </div>
              </div>
              <div className={styles.editorFlexContainerNarrow}>
                <div className={styles.editorWrapper}>
                  <h3 className={styles.largeQuestionTitle}>
                    {t("Vasu.guardian_comments")}
                  </h3>
                  {published ? (
                    <div className={styles.markdownContainer}>
                      <ReactMarkdown children={strengthsAndInterests2} />
                    </div>
                  ) : (
                    <RichTextEditor
                      value={strengthsAndInterests2}
                      onChange={(value) =>
                        handleChange(setStrengthsAndInterests2, value)
                      }
                      toolbarConfig={toolbarConfig}
                      placeholder={t("Vasu.write")}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className={styles.section}>
              <div className={styles.sectionIndicatorMint}></div>
              <h2 className={styles.sectionTitle}>
                5. {t("Vasu.child_statement")}
              </h2>
              <h3 className={styles.questionTitle}>
                {t("Vasu.child_statement_description")}
              </h3>
              {((childNarration1 && childNarration1.length > 2) ||
                (childNarration2 && childNarration2.length > 2) ||
                (childNarration3 && childNarration3.length > 2)) && (
                <div className={styles.editorFlexContainer}>
                  <div className={styles.editorWrapper}>
                    <div className={styles.deprecatedContainer}>
                      <label className={styles.deprecatedFieldLabel}>
                        {t("Vasu.deprecated")}
                      </label>
                      <ReactMarkdown children={childNarration1} />
                    </div>
                  </div>
                  <div className={styles.editorWrapper}>
                    <div className={styles.deprecatedContainer}>
                      <label className={styles.deprecatedFieldLabel}>
                        {t("Vasu.deprecated")}
                      </label>
                      <ReactMarkdown children={childNarration2} />
                    </div>
                  </div>
                  <div className={styles.editorWrapper}>
                    <div className={styles.deprecatedContainer}>
                      <label className={styles.deprecatedFieldLabel}>
                        {t("Vasu.deprecated")}
                      </label>
                      <ReactMarkdown children={childNarration3} />
                    </div>
                  </div>
                </div>
              )}
              <div className={styles.editorFlexContainerNarrow}>
                <div className={styles.editorWrapper}>
                  {published ? (
                    <div className={styles.markdownContainer}>
                      <ReactMarkdown children={childNarration} />
                    </div>
                  ) : (
                    <RichTextEditor
                      value={childNarration}
                      onChange={(value) =>
                        handleChange(setChildNarration, value)
                      }
                      toolbarConfig={toolbarConfig}
                      placeholder={t("Vasu.write")}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className={styles.section}>
              <div className={styles.sectionIndicatorGreen}></div>
              <h2 className={styles.sectionTitle}>
                6. {t("Vasu.notes_on_previous_plan")}
              </h2>
              <div className={styles.editorFlexContainerNarrow}>
                <div className={styles.editorWrapper}>
                  <h3 className={styles.questionTitle}>
                    {t("Vasu.notes_on_previous_plan_description")}
                  </h3>
                  {published ? (
                    <div className={styles.markdownContainer}>
                      <ReactMarkdown children={notesOnPreviousPlan} />
                    </div>
                  ) : (
                    <RichTextEditor
                      value={notesOnPreviousPlan}
                      onChange={(value) =>
                        handleChange(setNotesOnPreviousPlan, value)
                      }
                      toolbarConfig={toolbarConfig}
                      placeholder={t("Vasu.write")}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className={styles.section}>
              <div className={styles.sectionIndicatorLightblue}></div>
              <h2 className={styles.sectionTitle}>
                7. {t("Vasu.goals_of_activities")}
              </h2>
              <h3 className={styles.questionTitle}>
                {t("Vasu.goals_of_activities_description")}
              </h3>
              <div className={styles.matrixContainer}>
                <div className={styles.matrix}>
                  <div className={styles.firstRow}>
                    <div className={styles.column}></div>
                    <div className={styles.column}>
                      <div className={styles.columnTitle}>
                        <span>{t("Vasu.activity_to_be_practised")}</span>
                      </div>
                    </div>
                    <div className={styles.column}>
                      <div className={styles.columnTitleSmall}>
                        <span>{t("Vasu.solutions")}</span>
                      </div>
                    </div>
                    <div className={styles.column}>
                      <div className={styles.columnTitle}>
                        <span>{t("Vasu.assessment")}</span>
                      </div>
                    </div>
                  </div>

                  <div className={styles.row}>
                    <div className={styles.column}>
                      <div className={styles.rowTitle}>
                        {t("Vasu.routines")}
                      </div>
                    </div>
                    <div className={styles.column}>
                      <div className={styles.editorWrapper}>
                        {published ? (
                          <div className={styles.markdownContainerCell}>
                            <ReactMarkdown children={row1Col1} />
                          </div>
                        ) : (
                          <RichTextEditor
                            value={row1Col1}
                            onChange={(value) =>
                              handleChange(setRow1Col1, value)
                            }
                            toolbarConfig={toolbarConfig}
                            placeholder={t("Vasu.write")}
                          />
                        )}
                      </div>
                    </div>
                    <div className={styles.column}>
                      <div className={styles.editorWrapper}>
                        {published ? (
                          <div className={styles.markdownContainerCell}>
                            <ReactMarkdown children={row1Col2} />
                          </div>
                        ) : (
                          <RichTextEditor
                            value={row1Col2}
                            onChange={(value) =>
                              handleChange(setRow1Col2, value)
                            }
                            toolbarConfig={toolbarConfig}
                            placeholder={t("Vasu.write")}
                          />
                        )}
                      </div>
                    </div>
                    <div className={styles.column}>
                      <div className={styles.editorWrapper}>
                        {published ? (
                          <div className={styles.markdownContainerCell}>
                            <ReactMarkdown children={row1Col3} />
                          </div>
                        ) : (
                          <RichTextEditor
                            value={row1Col3}
                            onChange={(value) =>
                              handleChange(setRow1Col3, value)
                            }
                            toolbarConfig={toolbarConfig}
                            placeholder={t("Vasu.write")}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  <div className={styles.row}>
                    <div className={styles.column}>
                      <div className={styles.rowTitle}>
                        {t("Vasu.play_and_emotional")}
                      </div>
                    </div>
                    <div className={styles.column}>
                      <div className={styles.editorWrapper}>
                        {published ? (
                          <div className={styles.markdownContainerCell}>
                            <ReactMarkdown children={row2Col1} />
                          </div>
                        ) : (
                          <RichTextEditor
                            value={row2Col1}
                            onChange={(value) =>
                              handleChange(setRow2Col1, value)
                            }
                            toolbarConfig={toolbarConfig}
                            placeholder={t("Vasu.write")}
                          />
                        )}
                      </div>
                    </div>
                    <div className={styles.column}>
                      <div className={styles.editorWrapper}>
                        {published ? (
                          <div className={styles.markdownContainerCell}>
                            <ReactMarkdown children={row2Col2} />
                          </div>
                        ) : (
                          <RichTextEditor
                            value={row2Col2}
                            onChange={(value) =>
                              handleChange(setRow2Col2, value)
                            }
                            toolbarConfig={toolbarConfig}
                            placeholder={t("Vasu.write")}
                          />
                        )}
                      </div>
                    </div>
                    <div className={styles.column}>
                      <div className={styles.editorWrapper}>
                        {published ? (
                          <div className={styles.markdownContainerCell}>
                            <ReactMarkdown children={row2Col3} />
                          </div>
                        ) : (
                          <RichTextEditor
                            value={row2Col3}
                            onChange={(value) =>
                              handleChange(setRow2Col3, value)
                            }
                            toolbarConfig={toolbarConfig}
                            placeholder={t("Vasu.write")}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  <div className={styles.row}>
                    <div className={styles.column}>
                      <div className={styles.rowTitle}>
                        {t("Vasu.speech_development")}
                      </div>
                    </div>
                    <div className={styles.column}>
                      <div className={styles.editorWrapper}>
                        {published ? (
                          <div className={styles.markdownContainerCell}>
                            <ReactMarkdown children={row3Col1} />
                          </div>
                        ) : (
                          <RichTextEditor
                            value={row3Col1}
                            onChange={(value) =>
                              handleChange(setRow3Col1, value)
                            }
                            toolbarConfig={toolbarConfig}
                            placeholder={t("Vasu.write")}
                          />
                        )}
                      </div>
                    </div>
                    <div className={styles.column}>
                      <div className={styles.editorWrapper}>
                        {published ? (
                          <div className={styles.markdownContainerCell}>
                            <ReactMarkdown children={row3Col2} />
                          </div>
                        ) : (
                          <RichTextEditor
                            value={row3Col2}
                            onChange={(value) =>
                              handleChange(setRow3Col2, value)
                            }
                            toolbarConfig={toolbarConfig}
                            placeholder={t("Vasu.write")}
                          />
                        )}
                      </div>
                    </div>
                    <div className={styles.column}>
                      <div className={styles.editorWrapper}>
                        {published ? (
                          <div className={styles.markdownContainerCell}>
                            <ReactMarkdown children={row3Col3} />
                          </div>
                        ) : (
                          <RichTextEditor
                            value={row3Col3}
                            onChange={(value) =>
                              handleChange(setRow3Col3, value)
                            }
                            toolbarConfig={toolbarConfig}
                            placeholder={t("Vasu.write")}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  <div className={styles.row}>
                    <div className={styles.column}>
                      <div className={styles.rowTitle}>
                        {t("Vasu.motor_skills")}
                      </div>
                    </div>
                    <div className={styles.column}>
                      <div className={styles.editorWrapper}>
                        {published ? (
                          <div className={styles.markdownContainerCell}>
                            <ReactMarkdown children={row4Col1} />
                          </div>
                        ) : (
                          <RichTextEditor
                            value={row4Col1}
                            onChange={(value) =>
                              handleChange(setRow4Col1, value)
                            }
                            toolbarConfig={toolbarConfig}
                            placeholder={t("Vasu.write")}
                          />
                        )}
                      </div>
                    </div>
                    <div className={styles.column}>
                      <div className={styles.editorWrapper}>
                        {published ? (
                          <div className={styles.markdownContainerCell}>
                            <ReactMarkdown children={row4Col2} />
                          </div>
                        ) : (
                          <RichTextEditor
                            value={row4Col2}
                            onChange={(value) =>
                              handleChange(setRow4Col2, value)
                            }
                            toolbarConfig={toolbarConfig}
                            placeholder={t("Vasu.write")}
                          />
                        )}
                      </div>
                    </div>
                    <div className={styles.column}>
                      <div className={styles.editorWrapper}>
                        {published ? (
                          <div className={styles.markdownContainerCell}>
                            <ReactMarkdown children={row4Col3} />
                          </div>
                        ) : (
                          <RichTextEditor
                            value={row4Col3}
                            onChange={(value) =>
                              handleChange(setRow4Col3, value)
                            }
                            toolbarConfig={toolbarConfig}
                            placeholder={t("Vasu.write")}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  <div className={styles.row}>
                    <div className={styles.column}>
                      <div className={styles.rowTitle}>
                        {t("Vasu.cognitive_activity")}
                      </div>
                    </div>
                    <div className={styles.column}>
                      <div className={styles.editorWrapper}>
                        {published ? (
                          <div className={styles.markdownContainerCell}>
                            <ReactMarkdown children={row5Col1} />
                          </div>
                        ) : (
                          <RichTextEditor
                            value={row5Col1}
                            onChange={(value) =>
                              handleChange(setRow5Col1, value)
                            }
                            toolbarConfig={toolbarConfig}
                            placeholder={t("Vasu.write")}
                          />
                        )}
                      </div>
                    </div>
                    <div className={styles.column}>
                      <div className={styles.editorWrapper}>
                        {published ? (
                          <div className={styles.markdownContainerCell}>
                            <ReactMarkdown children={row5Col2} />
                          </div>
                        ) : (
                          <RichTextEditor
                            value={row5Col2}
                            onChange={(value) =>
                              handleChange(setRow5Col2, value)
                            }
                            toolbarConfig={toolbarConfig}
                            placeholder={t("Vasu.write")}
                          />
                        )}
                      </div>
                    </div>
                    <div className={styles.column}>
                      <div className={styles.editorWrapper}>
                        {published ? (
                          <div className={styles.markdownContainerCell}>
                            <ReactMarkdown children={row5Col3} />
                          </div>
                        ) : (
                          <RichTextEditor
                            value={row5Col3}
                            onChange={(value) =>
                              handleChange(setRow5Col3, value)
                            }
                            toolbarConfig={toolbarConfig}
                            placeholder={t("Vasu.write")}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  <div className={styles.row}>
                    <div className={styles.column}>
                      <div className={styles.rowTitle}>
                        {t("Vasu.cultural_awareness")}
                      </div>
                    </div>
                    <div className={styles.column}>
                      <div className={styles.editorWrapper}>
                        {published ? (
                          <div className={styles.markdownContainerCell}>
                            <ReactMarkdown children={row6Col1} />
                          </div>
                        ) : (
                          <RichTextEditor
                            value={row6Col1}
                            onChange={(value) =>
                              handleChange(setRow6Col1, value)
                            }
                            toolbarConfig={toolbarConfig}
                            placeholder={t("Vasu.write")}
                          />
                        )}
                      </div>
                    </div>
                    <div className={styles.column}>
                      <div className={styles.editorWrapper}>
                        {published ? (
                          <div className={styles.markdownContainerCell}>
                            <ReactMarkdown children={row6Col2} />
                          </div>
                        ) : (
                          <RichTextEditor
                            value={row6Col2}
                            onChange={(value) =>
                              handleChange(setRow6Col2, value)
                            }
                            toolbarConfig={toolbarConfig}
                            placeholder={t("Vasu.write")}
                          />
                        )}
                      </div>
                    </div>
                    <div className={styles.column}>
                      <div className={styles.editorWrapper}>
                        {published ? (
                          <div className={styles.markdownContainerCell}>
                            <ReactMarkdown children={row6Col3} />
                          </div>
                        ) : (
                          <RichTextEditor
                            value={row6Col3}
                            onChange={(value) =>
                              handleChange(setRow6Col3, value)
                            }
                            toolbarConfig={toolbarConfig}
                            placeholder={t("Vasu.write")}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.section}>
              <div className={styles.sectionIndicatorDarkblue}></div>
              <h2 className={styles.sectionTitle}>
                8. {t("Vasu.support_for_development_and_learning")}
              </h2>
              <div className={styles.editorFlexContainerNarrow}>
                <div className={styles.editorWrapper}>
                  <h3 className={styles.questionTitle}>
                    {t("Vasu.support_description")}
                  </h3>
                  {developmentAndLearningSupport &&
                    developmentAndLearningSupport.length > 2 && (
                      <div className={styles.deprecatedContainer}>
                        <label className={styles.deprecatedFieldLabel}>
                          {t("Vasu.deprecated")}
                        </label>
                        <ReactMarkdown
                          children={developmentAndLearningSupport}
                        />
                      </div>
                    )}
                </div>
              </div>
              <h3 className={styles.largeQuestionTitle}>
                8.1. {t("Vasu.support_one")}
              </h3>
              <h3 className={styles.questionTitle}>
                {t("Vasu.support_one_description")}
              </h3>
              <div className={styles.supportPeriods}>
                <div className={styles.supportPeriod}>
                  <ListCheckbox
                    type="checkbox"
                    name="supportPeriod"
                    value="general"
                    checked={supportPeriod === "general"}
                    onChange={() => {
                      if (!published) {
                        handleChange(
                          setSupportPeriod,
                          supportPeriod === "general" ? null : "general"
                        );
                      }
                    }}
                  >
                    {t("Vasu.general_support")}
                  </ListCheckbox>
                  <div className={styles.supportPeriodDates}>
                    <div className={styles.supportPeriodLabel}>
                      {t("Vasu.date_given")}
                    </div>
                    {supportPeriodDates &&
                      supportPeriodDates.general &&
                      supportPeriodDates.general.given &&
                      supportPeriodDates.general.given.length > 0 &&
                      supportPeriodDates.general.given.map((date) => {
                        return (
                          <div className={styles.supportPeriodDate} key={date}>
                            {date}
                          </div>
                        );
                      })}
                    {showGiven === "general" && (
                      <Formik>
                        <Field
                          type="text"
                          value={newGivenSupport}
                          onChange={(e) =>
                            handleChange(setNewGivenSupport, e.target.value)
                          }
                          placeholder={t("Vasu.write")}
                          required
                        />
                      </Formik>
                    )}
                    <div className={styles.supportPeriodLabel}>
                      {t("Vasu.date_repealed")}
                    </div>
                    {supportPeriodDates &&
                      supportPeriodDates.general &&
                      supportPeriodDates.general.repealed &&
                      supportPeriodDates.general.repealed.length > 0 &&
                      supportPeriodDates.general.repealed.map((date) => {
                        return (
                          <div className={styles.supportPeriodDate} key={date}>
                            {date}
                          </div>
                        );
                      })}
                    {showRepealed === "general" && (
                      <Formik>
                        <Field
                          type="text"
                          value={newRepealedSupport}
                          onChange={(e) =>
                            handleChange(setNewRepealedSupport, e.target.value)
                          }
                          placeholder={t("Vasu.write")}
                          required
                        />
                      </Formik>
                    )}
                  </div>
                </div>
                <div className={styles.supportPeriod}>
                  <ListCheckbox
                    type="checkbox"
                    name="supportPeriod"
                    value="enhanced"
                    checked={supportPeriod === "enhanced"}
                    onChange={() => {
                      if (!published) {
                        handleChange(
                          setSupportPeriod,
                          supportPeriod === "enhanced" ? null : "enhanced"
                        );
                      }
                    }}
                  >
                    {t("Vasu.enhanced_support")}
                  </ListCheckbox>
                  <div className={styles.supportPeriodDates}>
                    <div className={styles.supportPeriodLabel}>
                      {t("Vasu.date_given")}
                    </div>
                    {supportPeriodDates &&
                      supportPeriodDates.enhanced &&
                      supportPeriodDates.enhanced.given &&
                      supportPeriodDates.enhanced.given.length > 0 &&
                      supportPeriodDates.enhanced.given.map((date) => {
                        return (
                          <div className={styles.supportPeriodDate} key={date}>
                            {date}
                          </div>
                        );
                      })}
                    {showGiven === "enhanced" && (
                      <Formik>
                        <Field
                          type="text"
                          value={newGivenSupport}
                          onChange={(e) =>
                            handleChange(setNewGivenSupport, e.target.value)
                          }
                          placeholder={t("Vasu.write")}
                          required
                        />
                      </Formik>
                    )}
                    <div className={styles.supportPeriodLabel}>
                      {t("Vasu.date_repealed")}
                    </div>
                    {supportPeriodDates &&
                      supportPeriodDates.enhanced &&
                      supportPeriodDates.enhanced.repealed &&
                      supportPeriodDates.enhanced.repealed.length > 0 &&
                      supportPeriodDates.enhanced.repealed.map((date) => {
                        return (
                          <div className={styles.supportPeriodDate} key={date}>
                            {date}
                          </div>
                        );
                      })}
                    {showRepealed === "enhanced" && (
                      <Formik>
                        <Field
                          type="text"
                          value={newRepealedSupport}
                          onChange={(e) =>
                            handleChange(setNewRepealedSupport, e.target.value)
                          }
                          placeholder={t("Vasu.write")}
                          required
                        />
                      </Formik>
                    )}
                  </div>
                </div>
                <div className={styles.supportPeriod}>
                  <ListCheckbox
                    type="checkbox"
                    name="supportPeriod"
                    value="special"
                    checked={supportPeriod === "special"}
                    onChange={() => {
                      if (!published) {
                        handleChange(
                          setSupportPeriod,
                          supportPeriod === "special" ? null : "special"
                        );
                      }
                    }}
                  >
                    {t("Vasu.special_support")}
                  </ListCheckbox>
                  <div className={styles.supportPeriodDates}>
                    <div className={styles.supportPeriodLabel}>
                      {t("Vasu.date_given")}
                    </div>
                    {supportPeriodDates &&
                      supportPeriodDates.special &&
                      supportPeriodDates.special.given &&
                      supportPeriodDates.special.given.length > 0 &&
                      supportPeriodDates.special.given.map((date) => {
                        return (
                          <div className={styles.supportPeriodDate} key={date}>
                            {date}
                          </div>
                        );
                      })}
                    {showGiven === "special" && (
                      <Formik>
                        <Field
                          type="text"
                          value={newGivenSupport}
                          onChange={(e) =>
                            handleChange(setNewGivenSupport, e.target.value)
                          }
                          placeholder={t("Vasu.write")}
                          required
                        />
                      </Formik>
                    )}
                    <div className={styles.supportPeriodLabel}>
                      {t("Vasu.date_repealed")}
                    </div>
                    {supportPeriodDates &&
                      supportPeriodDates.special &&
                      supportPeriodDates.special.repealed &&
                      supportPeriodDates.special.repealed.length > 0 &&
                      supportPeriodDates.special.repealed.map((date) => {
                        return (
                          <div className={styles.supportPeriodDate} key={date}>
                            {date}
                          </div>
                        );
                      })}
                    {showRepealed === "special" && (
                      <Formik>
                        <Field
                          type="text"
                          value={newRepealedSupport}
                          onChange={(e) =>
                            handleChange(setNewRepealedSupport, e.target.value)
                          }
                          placeholder={t("Vasu.write")}
                          required
                        />
                      </Formik>
                    )}
                  </div>
                </div>
              </div>

              {(showGiven || showRepealed) && (
                <div className={styles.cancelSupportChange}>
                  <Button
                    round
                    narrow
                    onClick={() => {
                      setSupportPeriod(getFieldValue("currentSupportPeriod"));
                      setShowGiven(false);
                      setShowRepealed(false);
                      setNewGivenSupport("");
                      setNewRepealedSupport("");
                    }}
                  >
                    {t("Vasu.cancel_support_change")}
                  </Button>
                </div>
              )}

              <h3 className={styles.questionDescription}>
                {t("Vasu.support_one_description_two")}
              </h3>
              <div className={styles.editorFlexContainerNarrow}>
                <div className={styles.editorWrapper}>
                  <h3 className={styles.questionTitle}>
                    {t("Vasu.support_one_one")}
                  </h3>
                  {published ? (
                    <div className={styles.markdownContainer}>
                      <ReactMarkdown children={supportOneOne} />
                    </div>
                  ) : (
                    <RichTextEditor
                      value={supportOneOne}
                      onChange={(value) =>
                        handleChange(setSupportOneOne, value)
                      }
                      toolbarConfig={toolbarConfig}
                      placeholder={t("Vasu.write")}
                    />
                  )}
                </div>
              </div>
              <div className={styles.editorFlexContainerNarrow}>
                <div className={styles.editorWrapper}>
                  <h3 className={styles.questionTitle}>
                    {t("Vasu.support_one_two")}
                  </h3>
                  {published ? (
                    <div className={styles.markdownContainer}>
                      <ReactMarkdown children={supportOneTwo} />
                    </div>
                  ) : (
                    <RichTextEditor
                      value={supportOneTwo}
                      onChange={(value) =>
                        handleChange(setSupportOneTwo, value)
                      }
                      toolbarConfig={toolbarConfig}
                      placeholder={t("Vasu.write")}
                    />
                  )}
                </div>
              </div>
              <div className={styles.editorFlexContainerNarrow}>
                <div className={styles.editorWrapper}>
                  <h3 className={styles.questionTitle}>
                    {t("Vasu.support_one_three")}
                  </h3>
                  {published ? (
                    <div className={styles.markdownContainer}>
                      <ReactMarkdown children={supportOneThree} />
                    </div>
                  ) : (
                    <RichTextEditor
                      value={supportOneThree}
                      onChange={(value) =>
                        handleChange(setSupportOneThree, value)
                      }
                      toolbarConfig={toolbarConfig}
                      placeholder={t("Vasu.write")}
                    />
                  )}
                </div>
              </div>
              <div className={styles.editorFlexContainerNarrow}>
                <div className={styles.editorWrapper}>
                  <h3 className={styles.questionTitle}>
                    {t("Vasu.support_one_four")}
                  </h3>
                  {published ? (
                    <div className={styles.markdownContainer}>
                      <ReactMarkdown children={supportOneFour} />
                    </div>
                  ) : (
                    <RichTextEditor
                      value={supportOneFour}
                      onChange={(value) =>
                        handleChange(setSupportOneFour, value)
                      }
                      toolbarConfig={toolbarConfig}
                      placeholder={t("Vasu.write")}
                    />
                  )}
                </div>
              </div>

              <h3 className={styles.largeQuestionTitle}>
                8.2. {t("Vasu.support_two")}
              </h3>
              <h3 className={styles.questionDescription}>
                {t("Vasu.support_two_description")}
              </h3>
              <div className={styles.editorFlexContainerNarrow}>
                <div className={styles.editorWrapper}>
                  <h3 className={styles.questionTitle}>
                    {t("Vasu.support_one_two")}
                  </h3>
                  {published ? (
                    <div className={styles.markdownContainer}>
                      <ReactMarkdown children={supportTwoOne} />
                    </div>
                  ) : (
                    <RichTextEditor
                      value={supportTwoOne}
                      onChange={(value) =>
                        handleChange(setSupportTwoOne, value)
                      }
                      toolbarConfig={toolbarConfig}
                      placeholder={t("Vasu.write")}
                    />
                  )}
                </div>
              </div>
              <div className={styles.editorFlexContainerNarrow}>
                <div className={styles.editorWrapper}>
                  <h3 className={styles.questionTitle}>
                    {t("Vasu.support_one_three")}
                  </h3>
                  {published ? (
                    <div className={styles.markdownContainer}>
                      <ReactMarkdown children={supportTwoTwo} />
                    </div>
                  ) : (
                    <RichTextEditor
                      value={supportTwoTwo}
                      onChange={(value) =>
                        handleChange(setSupportTwoTwo, value)
                      }
                      toolbarConfig={toolbarConfig}
                      placeholder={t("Vasu.write")}
                    />
                  )}
                </div>
              </div>
              <div className={styles.editorFlexContainerNarrow}>
                <div className={styles.editorWrapper}>
                  <h3 className={styles.questionTitle}>
                    {t("Vasu.support_one_four")}
                  </h3>
                  {published ? (
                    <div className={styles.markdownContainer}>
                      <ReactMarkdown children={supportTwoThree} />
                    </div>
                  ) : (
                    <RichTextEditor
                      value={supportTwoThree}
                      onChange={(value) =>
                        handleChange(setSupportTwoThree, value)
                      }
                      toolbarConfig={toolbarConfig}
                      placeholder={t("Vasu.write")}
                    />
                  )}
                </div>
              </div>
              <div className={styles.editorFlexContainerNarrow}>
                <div className={styles.editorWrapper}>
                  <h3 className={styles.questionTitle}>
                    {t("Vasu.support_two_four")}
                  </h3>
                  {published ? (
                    <div className={styles.markdownContainer}>
                      <ReactMarkdown children={supportTwoFour} />
                    </div>
                  ) : (
                    <RichTextEditor
                      value={supportTwoFour}
                      onChange={(value) =>
                        handleChange(setSupportTwoFour, value)
                      }
                      toolbarConfig={toolbarConfig}
                      placeholder={t("Vasu.write")}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className={styles.section}>
              <div className={styles.sectionIndicatorPurple}></div>
              <h2 className={styles.sectionTitle}>
                9. {t("Vasu.other_issues")}
              </h2>
              <div className={styles.editorFlexContainerNarrow}>
                <div className={styles.editorWrapper}>
                  <h3 className={styles.questionTitle}>
                    {t("Vasu.other_issues_description")}
                  </h3>
                  {published ? (
                    <div className={styles.markdownContainer}>
                      <ReactMarkdown children={otherFactors} />
                    </div>
                  ) : (
                    <RichTextEditor
                      value={otherFactors}
                      onChange={(value) => handleChange(setOtherFactors, value)}
                      toolbarConfig={toolbarConfig}
                      placeholder={t("Vasu.write")}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className={styles.section}>
              <div className={styles.sectionIndicatorPink}></div>
              <h2 className={styles.sectionTitle}>
                10. {t("Vasu.other_documents")}
              </h2>
              <div className={styles.editorFlexContainerNarrow}>
                <div className={styles.editorWrapper}>
                  {published ? (
                    <div className={styles.markdownContainer}>
                      <ReactMarkdown children={otherDocumentsAndPlans} />
                    </div>
                  ) : (
                    <RichTextEditor
                      value={otherDocumentsAndPlans}
                      onChange={(value) =>
                        handleChange(setOtherDocumentsAndPlans, value)
                      }
                      toolbarConfig={toolbarConfig}
                      placeholder={t("Vasu.write")}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className={styles.section}>
              <div className={styles.sectionIndicatorMint}></div>
              <h2 className={styles.sectionTitle}>
                11. {t("Vasu.monitoring")}
              </h2>
              <div className={styles.editorFlexContainerNarrow}>
                <div className={styles.editorWrapper}>
                  {published ? (
                    <div className={styles.markdownContainer}>
                      <ReactMarkdown children={monitoringAndEvaluation} />
                    </div>
                  ) : (
                    <RichTextEditor
                      value={monitoringAndEvaluation}
                      onChange={(value) =>
                        handleChange(setMonitoringAndEvaluation, value)
                      }
                      toolbarConfig={toolbarConfig}
                      placeholder={t("Vasu.write")}
                    />
                  )}
                </div>
              </div>
            </div>

            {presentInDiscussion && presentInDiscussion.length > 2 && (
              <div className={styles.section}>
                <div className={styles.sectionIndicatorLightred}></div>
                <h2 className={styles.sectionTitle}>
                  12. {t("Vasu.participants")}
                </h2>
                <div className={styles.editorFlexContainerNarrow}>
                  <div className={styles.editorWrapper}>
                    <h3 className={styles.questionTitle}>
                      {t("Vasu.participants_description")}
                    </h3>
                    <div className={styles.deprecatedContainer}>
                      <label className={styles.deprecatedFieldLabel}>
                        {t("Vasu.not_in_use")}
                      </label>
                      <ReactMarkdown children={presentInDiscussion} />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const UPDATE_DOCUMENT = gql`
  mutation updateEarlyEducationDocument(
    $document: EarlyEducationDocumentAttributes!
    $objectiveValues: [EarlyEducationDocumentObjectiveValueAttributes!]
    $formFieldsData: [EarlyEducationDocumentFormFieldsDataAttributes!]
  ) {
    updateEarlyEducationDocument(
      document: $document
      objectiveValues: $objectiveValues
      formFieldsData: $formFieldsData
    ) {
      id
    }
  }
`;

export default ChildVasu;

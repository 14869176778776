import { tougoCookies } from "./cookieHelpers";

const isCurrentUserMessageAuthor = (messageId, staff) => {
  const currentUserId = staff ? tougoCookies.get("workerId") : tougoCookies.get("sessionUserId");
  if (messageId === currentUserId) {
    return true;
  } else {
    return false;
  }
};

export { isCurrentUserMessageAuthor };

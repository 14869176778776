import gql from "graphql-tag";

export const IS_LOGGED_IN = gql`
  query isUserLoggedIn {
    isLoggedIn @client
  }
`;

export const CURRENT_WORKER = gql`
  query currentWorker {
    currentWorker {
      id
      firstName
      lastName
      role
    }
  }
`;

export const GET_DAYCARE_CENTERS_LIST = gql`
  query daycareCenters($offset: Int, $limit: Int) {
    daycareCenters(offset: $offset, limit: $limit) {
      id
      name
    }
    selectedDaycareCenter @client
  }
`;

export const GET_DAYCARE_CENTERS = gql`
  query daycareCenters($offset: Int, $limit: Int, $query: String) {
    daycareCenters(offset: $offset, limit: $limit, query: $query) {
      id
      name
      opensAt
      closesAt
      workers {
        id
        firstName
        lastName
        profilePicture {
          extraSmall {
            url
          }
        }
      }
      municipality {
        id
        name
        region {
          id
          name
        }
      }
    }
    selectedDaycareCenter @client
  }
`;

export const GET_REGION_DAYCARE_CENTERS = gql`
  query regionDaycareCenters($regionId: ID!) {
    regionDaycareCenters(regionId: $regionId) {
      id
      name
      municipality {
        id
        name
        region {
          id
          name
        }
      }
    }
    selectedDaycareCenter @client
  }
`;

export const GET_DAYCARE_GROUPS = gql`
  query daycareCenter($id: ID!) {
    daycareCenter(id: $id) {
      id
      name
      daycareGroups {
        id
        name
      }
    }
    selectedDaycareGroup @client
  }
`;

export const GET_DAYCARE_CENTER = gql`
  query daycareCenter($id: ID!) {
    daycareCenter(id: $id) {
      id
      name
      opensAt
      streetAddress
      city
      daycareContacts {
        id
        name
        phoneNumber
      }
      closesAt
      preschoolHoursStartAt
      preschoolHoursEndAt
      daycareClosures {
        id
        daycareCenter {
          id
        }
        closedFrom
        closedUntil
        reason
      }
      vacationPeriods {
        id
        description
        startDate
        endDate
        bookableBetween {
          fromDate
          untilDate
        }
      }
      workers {
        id
        firstName
        lastName
        role
        profilePicture {
          extraSmall {
            url
          }
        }
      }
      daycareGroups {
        id
        name
        children {
          id
        }
      }
      children {
        id
        name
        activePlacements {
          id
          serviceRequirement
        }
        activeDaycareGroups {
          id
          name
        }
        reservationLockPeriod
        siblings {
          id
          name
        }
      }
    }
  }
`;

export const UPDATE_EVENT = gql`
  mutation updateEvent(
    $id: ID!
    $daycareGroupIds: [ID!]!
    $title: String!
    $summary: String!
    $description: String!
    $responseRequired: Boolean!
    $responseDeadline: ISO8601DateTime!
    $startDate: ISO8601Date!
    $important: Boolean
    $icon: EventIcon
    $endDate: ISO8601Date!
    $startTime: String
    $endTime: String
    $guardiansAllowed: Boolean!
    $reminderEnabled: Boolean!
    $document: JSON
  ) {
    updateEvent(
      id: $id
      daycareGroupIds: $daycareGroupIds
      title: $title
      summary: $summary
      important: $important
      icon: $icon
      description: $description
      responseRequired: $responseRequired
      responseDeadline: $responseDeadline
      startDate: $startDate
      endDate: $endDate
      startTime: $startTime
      endTime: $endTime
      guardiansAllowed: $guardiansAllowed
      reminderEnabled: $reminderEnabled
      document: $document
    ) {
      id
    }
  }
`;

export const CREATE_NEW_EVENT = gql`
  mutation createEvent(
    $daycareCenterId: ID!
    $daycareGroupIds: [ID!]!
    $title: String!
    $summary: String!
    $description: String!
    $responseRequired: Boolean!
    $responseDeadline: ISO8601DateTime
    $icon: EventIcon
    $important: Boolean
    $startDate: ISO8601Date!
    $endDate: ISO8601Date!
    $startTime: String
    $endTime: String
    $guardiansAllowed: Boolean!
    $reminderEnabled: Boolean!
    $document: JSON
  ) {
    createEvent(
      daycareCenterId: $daycareCenterId
      daycareGroupIds: $daycareGroupIds
      title: $title
      summary: $summary
      description: $description
      responseRequired: $responseRequired
      responseDeadline: $responseDeadline
      icon: $icon
      important: $important
      startDate: $startDate
      endDate: $endDate
      startTime: $startTime
      endTime: $endTime
      guardiansAllowed: $guardiansAllowed
      reminderEnabled: $reminderEnabled
      document: $document
    ) {
      id
    }
  }
`;

export const GET_EVENT = gql`
  query event($id: ID!) {
    event(id: $id) {
      id
      daycareCenter {
        id
        children {
          id
          firstName
          lastName
          activePlacements {
            id
            serviceRequirement
          }
          activeDaycareGroups {
            id
            name
          }
        }
      }
      daycareGroups {
        id
      }
      title
      summary
      description
      icon
      important
      startDate
      endDate
      startTime
      endTime
      guardiansAllowed
      responseRequired
      responseDeadline
      responses {
        children {
          id
          name
          firstName
          lastName
        }
        declinedChildren {
          id
          name
          firstName
          lastName
        }
        attending
        guestCount
      }
      reminderEnabled
      document {
        original {
          url
          metadata {
            filename
            size
          }
        }
      }
    }
  }
`;

export const DELETE_EVENT = gql`
  mutation deleteEvent($id: ID!) {
    deleteEvent(id: $id) {
      id
    }
  }
`;

export const GET_DAYCARE_CENTER_EVENTS = gql`
  query daycareCenter($id: ID!, $past: Boolean) {
    daycareCenter(id: $id) {
      id
      name
      children {
        id
        name
        activeDaycareGroups {
          id
          name
        }
      }
      daycareGroups {
        id
      }
      events(past: $past) {
        id
        daycareCenter {
          id
        }
        daycareGroups {
          id
        }
        title
        summary
        description
        icon
        important
        startDate
        endDate
        startTime
        endTime
        responseDeadline
        responseRequired
        responses {
          children {
            id
            name
          }
          declinedChildren {
            id
            name
          }
          attending
          guestCount
        }
        guardiansAllowed
        reminderEnabled
      }
    }
  }
`;

export const GET_DAYCARE_CENTERS_WORKERS = gql`
  query workers($offset: Int, $limit: Int, $query: String) {
    workers(offset: $offset, limit: $limit, query: $query) {
      id
      firstName
      lastName
      role
      activeSince
      activeUntil
      assignments {
        daycareCenter {
          name
        }
      }
    }
  }
`;

export const GET_DAYCARE_CENTER_WORKERS = gql`
  query daycareCenter($id: ID!, $offset: Int, $limit: Int) {
    daycareCenter(id: $id) {
      id
      name
      workers(offset: $offset, limit: $limit) {
        id
        firstName
        lastName
        role
        activeSince
        activeUntil
        assignments {
          daycareCenter {
            name
          }
        }
        profilePicture {
          extraSmall {
            url
          }
        }
      }
    }
  }
`;

export const UPDATE_RESERVATION_LOCK_PERIOD = gql`
  mutation updateReservationLockPeriod($id: ID!, $reservationLockPeriod: String!) {
    updateReservationLockPeriod(id: $id, reservationLockPeriod: $reservationLockPeriod) {
      id
      reservationLockPeriod
    }
  }
`;

export const UPDATE_MUNICIPALITY = gql`
  mutation updateMunicipality($id: ID!, $childrenPerWorker: String!) {
    updateMunicipality(id: $id, childrenPerWorker: $childrenPerWorker) {
      id
      childrenPerWorker
    }
  }
`;

export const UPDATE_WORKER = gql`
  mutation updateWorker(
    $id: ID!
    $activeSince: ISO8601Date
    $activeUntil: ISO8601Date
    $pin: String
    $pinConfirmation: String
    $profilePicture: JSON
  ) {
    updateWorker(
      id: $id
      activeSince: $activeSince
      activeUntil: $activeUntil
      pin: $pin
      pinConfirmation: $pinConfirmation
      profilePicture: $profilePicture
    ) {
      id
      activeSince
      activeUntil
    }
  }
`;

export const UPDATE_DAYCARE_CENTER = gql`
  mutation updateDaycareCenter(
    $id: ID!
    $streetAddress: String
    $city: String
    $opensAt: String
    $closesAt: String
    $preschoolHoursStartAt: String
    $preschoolHoursEndAt: String
    $municipalityId: ID
  ) {
    updateDaycareCenter(
      id: $id
      streetAddress: $streetAddress
      city: $city
      opensAt: $opensAt
      closesAt: $closesAt
      preschoolHoursStartAt: $preschoolHoursStartAt
      preschoolHoursEndAt: $preschoolHoursEndAt
      municipalityId: $municipalityId
    ) {
      id
      name
      streetAddress
      city
      opensAt
      closesAt
      preschoolHoursStartAt
      preschoolHoursEndAt
      municipalityId
    }
  }
`;

export const CREATE_DAYCARE_CLOSURE = gql`
  mutation createDaycareClosure(
    $daycareCenterId: ID!
    $closedFrom: ISO8601Date!
    $closedUntil: ISO8601Date!
    $reason: String
  ) {
    createDaycareClosure(
      daycareCenterId: $daycareCenterId
      closedFrom: $closedFrom
      closedUntil: $closedUntil
      reason: $reason
    ) {
      id
      daycareCenterId
      closedFrom
      closedUntil
      reason
    }
  }
`;

export const UPDATE_DAYCARE_CLOSURE = gql`
  mutation updateDaycareClosure($id: ID!, $closedFrom: ISO8601Date!, $closedUntil: ISO8601Date!, $reason: String) {
    updateDaycareClosure(id: $id, closedFrom: $closedFrom, closedUntil: $closedUntil, reason: $reason) {
      id
      daycareCenterId
      closedFrom
      closedUntil
      reason
    }
  }
`;

export const DELETE_DAYCARE_CLOSURE = gql`
  mutation deleteDaycareClosure($id: ID!) {
    deleteDaycareClosure(id: $id) {
      id
    }
  }
`;

export const SET_SELECTED_DAYCARE_CENTER = gql`
  mutation setSelectedDaycareCenter($id: ID!, $group_id: String) {
    setSelectedDaycareCenter(id: $id, group_id: $group_id) @client
  }
`;

export const SET_SELECTED_DAYCARE_GROUP = gql`
  mutation setSelectedDaycareGroup($id: ID!) {
    setSelectedDaycareGroup(id: $id) @client
  }
`;

export const GET_TODAYS_RESERVATIONS_FOR_DAYCARE = gql`
  query daycareCenter($id: ID!, $date: ISO8601DateTime!) {
    daycareCenter(id: $id) {
      id
      opensAt
      closesAt
      checkIns(date: $date) {
        checkedInAt
        checkedOutAt
        child {
          age
        }
      }
      reservations(date: $date) {
        startTime
        endTime
        child {
          age
        }
      }
      municipality {
        id
        childrenPerWorker
      }
    }
  }
`;

export const GET_DAYCARE_RESERVATIONS = gql`
  query daycareReservations($daycareId: ID!, $date: ISO8601DateTime!, $untilDate: ISO8601DateTime) {
    daycareCenter(id: $daycareId) {
      id
      name
      opensAt
      closesAt
      daycareGroups {
        id
        children(date: $date, untilDate: $untilDate) {
          id
          name
          firstName
          lastName
          age
          activePlacements {
            id
            serviceRequirement
            category
            hoursPerMonth
            daysPerMonth
            hoursPerWeek
          }
          reservations(date: $date, untilDate: $untilDate, daycareCenterId: $daycareId) {
            id
            startTime
            endTime
            author {
              id
              authorType
            }
          }
          activeDaycareGroups {
            id
            name
          }
          absences(date: $date, untilDate: $untilDate) {
            id
            description
            category
            date
          }
          checkIns(date: $date, untilDate: $untilDate) {
            id
            checkedInAt
            checkedOutAt
          }
          vacationDays(date: $date, untilDate: $untilDate) {
            id
            date
            author {
              id
              authorType
            }
          }
          profilePicture {
            extraSmall {
              url
            }
          }
        }
      }
    }
  }
`;

export const GET_MONTHLY_DAYCARE_RESERVATIONS = gql`
  query daycareReservations($daycareId: ID!, $date: ISO8601DateTime!, $untilDate: ISO8601DateTime) {
    daycareCenter(id: $daycareId) {
      id
      name
      opensAt
      closesAt
      daycareGroups {
        id
        children {
          id
          name
          firstName
          lastName
          age
          activePlacements {
            id
            serviceRequirement
            category
            hoursPerMonth
            daysPerMonth
          }
          reservations(date: $date, untilDate: $untilDate) {
            id
            startTime
            endTime
            author {
              id
              authorType
            }
          }
          activeDaycareGroups {
            id
            name
          }
          absences(date: $date, untilDate: $untilDate) {
            id
            description
            category
            date
          }
          checkIns(date: $date, untilDate: $untilDate) {
            id
            checkedInAt
            checkedOutAt
          }
          profilePicture {
            extraSmall {
              url
            }
          }
        }
      }
    }
  }
`;

export const GET_GLOBAL_FILTERS = gql`
  {
    currentWorker {
      id
      messagerType
      role
    }
    selectedDate @client
    selectedDaycareCenter @client
    selectedDaycareGroup @client
  }
`;

export const GET_WORKER_ROLE = gql`
  {
    workerRole @client
  }
`;

// CHILDREN
export const GET_ALL_CHILDREN = gql`
  query children($offset: Int, $limit: Int) {
    children(offset: $offset, limit: $limit) {
      id
      name
      firstName
      lastName
      allergies {
        id
        name
        notes
      }
      specialCares {
        id
        name
        notes
      }
      trustedPeople {
        id
        firstName
        lastName
        phoneNumber
      }
      profilePicture {
        extraSmall {
          url
        }
      }
      profilePictureAllowed
      tougoPhotosAllowed
      tougoGroupPhotosAllowed
      marketingPhotosAllowed
    }
  }
`;

export const GET_CENTER_CHILDREN = gql`
  query getCenterChildren($daycareId: ID!, $cutoffPeriodDays: Int) {
    daycareCenter(id: $daycareId) {
      id
      currentAndFutureChildren(cutoffPeriodDays: $cutoffPeriodDays) {
        id
        name
        firstName
        lastName
        age
        profilePicture {
          extraSmall {
            url
          }
        }
      }
      daycareGroups {
        id
        children {
          id
          name
          firstName
          lastName
          age
          profilePicture {
            extraSmall {
              url
            }
          }
        }
      }
      vacationPeriods {
        description
        startDate
        endDate
        id
        bookableBetween {
          fromDate
          untilDate
        }
      }
    }
  }
`;

export const GET_GROUP_CHILDREN = gql`
  query getGroupChildren($daycareId: ID!, $groupId: ID!, $cutoffPeriodDays: Int) {
    daycareCenter(id: $daycareId) {
      id
      daycareGroup(id: $groupId) {
        id
        currentAndFutureChildren(cutoffPeriodDays: $cutoffPeriodDays) {
          id
          name
          firstName
          lastName
          age
          profilePicture {
            extraSmall {
              url
            }
          }
        }
        children {
          id
          name
          firstName
          lastName
          age
          profilePicture {
            extraSmall {
              url
            }
          }
        }
      }
      vacationPeriods {
        description
        startDate
        endDate
        id
        bookableBetween {
          fromDate
          untilDate
        }
      }
    }
  }
`;

export const GET_CHILD = gql`
  query getChild($id: ID!) {
    child(id: $id) {
      id
      name
      firstName
      lastName
      age
      activePlacements {
        id
        serviceRequirement
      }
      legacyAllergies
      legacyMedicalConditions
      allergies {
        id
        name
        notes
      }
      specialCares {
        id
        name
        notes
      }
      trustedPeople {
        id
        firstName
        lastName
        phoneNumber
      }
      profilePicture {
        extraSmall {
          url
        }
        large {
          url
        }
      }
      careNotes
      profilePictureAllowed
      tougoPhotosAllowed
      tougoGroupPhotosAllowed
      marketingPhotosAllowed
      marketingArtAllowed
      specialistContactAllowed
      lutheranEventsAllowed
      guardians {
        name
        phoneNumber
        workPhoneNumber
        city
        postalCode
        street
      }
    }
  }
`;

export const GET_CHILD_WITH_ABSENCES = gql`
  query getChildWithAbsences($id: ID!, $date: ISO8601DateTime!, $untilDate: ISO8601DateTime) {
    child(id: $id) {
      id
      name
      firstName
      lastName
      age
      activePlacements {
        id
        serviceRequirement
      }
      legacyAllergies
      legacyMedicalConditions
      allergies {
        id
        name
        notes
        createdAt
        updatedAt
      }
      specialCares {
        id
        name
        notes
        createdAt
        updatedAt
      }
      trustedPeople {
        id
        firstName
        lastName
        phoneNumber
      }
      profilePicture {
        extraSmall {
          url
        }
        large {
          url
        }
      }
      careNotes
      profilePictureAllowed
      tougoPhotosAllowed
      tougoGroupPhotosAllowed
      marketingPhotosAllowed
      marketingArtAllowed
      specialistContactAllowed
      lutheranEventsAllowed
      guardians {
        id
        name
        phoneNumber
        workPhoneNumber
      }
      absences(date: $date, untilDate: $untilDate) {
        id
        description
        category
        date
      }
      vacationDays(date: $date, untilDate: $untilDate) {
        id
        date
      }
    }
  }
`;

export const GET_OFFICIAL_HOLIDAYS = gql`
  query {
    officialHolidays {
      date
      name
    }
  }
`;

export const UPDATE_RESERVATION = gql`
  mutation updateReservation(
    $reservationId: ID!
    $additionalChildrenIds: [ID!]
    $preserveOriginalTime: Boolean!
    $startTime: ISO8601DateTime!
    $endTime: ISO8601DateTime!
  ) {
    updateReservation(
      reservationId: $reservationId
      preserveOriginalTime: $preserveOriginalTime
      additionalChildrenIds: $additionalChildrenIds
      startTime: $startTime
      endTime: $endTime
    ) {
      id
      startTime
      endTime
    }
  }
`;

export const CREATE_CHECK_IN = gql`
  mutation createCheckIn($childId: ID!, $daycareCenterId: ID!, $startTime: ISO8601DateTime, $endTime: ISO8601DateTime) {
    createCheckIn(
      childId: $childId
      daycareCenterId: $daycareCenterId
      checkedInAt: $startTime
      checkedOutAt: $endTime
    ) {
      id
      checkedInAt
      checkedOutAt
    }
  }
`;

export const UPDATE_CHECK_IN = gql`
  mutation updateCheckIn($checkInId: ID!, $startTime: ISO8601DateTime, $endTime: ISO8601DateTime) {
    updateCheckIn(id: $checkInId, checkedInAt: $startTime, checkedOutAt: $endTime) {
      id
      checkedInAt
      checkedOutAt
    }
  }
`;

export const DELETE_RESERVATIONS = gql`
  mutation deleteReservations($reservationIds: [ID!]!) {
    deleteReservations(reservationIds: $reservationIds) {
      id
    }
  }
`;

export const DELETE_CHECK_INS = gql`
  mutation deleteCheckIn($checkInIds: [ID!]!) {
    deleteCheckIn(id: $checkInIds) {
      id
    }
  }
`;

export const DELETE_SERVICE_MANAGER = gql`
  mutation deleteServiceManager($regionId: ID!, $workerId: ID!) {
    deleteServiceManager(regionId: $regionId, workerId: $workerId) {
      id
      serviceManagers {
        id
        name
      }
    }
  }
`;

export const ADD_SERVICE_MANAGER = gql`
  mutation addServiceManager($regionId: ID!, $workerId: ID!) {
    addServiceManager(regionId: $regionId, workerId: $workerId) {
      id
      serviceManagers {
        id
        name
      }
    }
  }
`;

export const GET_MESSAGE_THREADS = gql`
  query {
    messageThreads {
      id
      subject
      recentMessageBody
      recentMessageAuthorName
      recentMessageAuthorType
      recentMessageCreatedAt
      sender {
        id
        name
        messagerType
      }
    }
  }
`;

export const MARK_ALL_MESSAGES_AS_READ = gql`
  mutation markAsSeenAllMessaging {
    markAsSeenAllMessaging
  }
`;

export const GET_MESSAGE_RECEIVERS = gql`
  query messageReceivers {
    messageReceivers {
      id
      messagerType
      name
    }
  }
`;

export const GET_LIST_OF_UNREAD_CHILDREN = gql`
  query childrenWhoseGuardiansHaveNotReadThreadAfterMessageWasSent($messageId: ID!) {
    childrenWhoseGuardiansHaveNotReadThreadAfterMessageWasSent(messageId: $messageId) {
      name
      firstName
      lastName
    }
  }
`;

export const GET_MESSAGE_THREAD = gql`
  query getMessageThread($id: ID!) {
    messageThread(id: $id) {
      id
      type
      subject
      unreadCount
      locked
      recentMessageBody
      recentMessageAuthorName
      recentMessageAuthorType
      recentMessageCreatedAt
      latestMessageReadByOtherParty
      children {
        id
        name
        firstName
        lastName
        activeDaycareGroups {
          name
        }
        profilePicture {
          extraSmall {
            url
          }
        }
      }
      sender {
        id
        name
        messagerType
      }
      replyable
      receivers {
        id
        name
        messagerType
      }
      messages {
        id
        body
        sentAt
        deletedAt
        document {
          original {
            url
            metadata {
              filename
              size
            }
          }
        }
        author {
          id
          authorType
          firstName
          lastName
        }
        channel {
          id
          type
        }
      }
    }
  }
`;

export const CREATE_NEW_THREAD = gql`
  mutation createNewThread(
    $receivers: [MessagerAttributes!]!
    $sender: MessagerAttributes!
    $body: String!
    $subject: String!
    $replyable: Boolean = true
    $announcement: Boolean = false
    $document: JSON
  ) {
    newMessage(
      sender: $sender
      subject: $subject
      body: $body
      receivers: $receivers
      replyable: $replyable
      announcement: $announcement
      document: $document
    ) {
      messageThreadGroup {
        id
        type
        subject
        sender {
          id
          messagerType
          name
        }
      }
    }
  }
`;

export const TOGGLE_PINNED = gql`
  mutation updateMessageThread($id: ID!, $pin: Boolean) {
    updateMessageThread(id: $id, pin: $pin) {
      id
      pinned
    }
  }
`;

export const GET_MESSAGE_THREAD_GROUP = gql`
  query getMessageThreadGroup($id: ID!) {
    messageThreadGroup(id: $id) {
      id
      type
      subject
      replyable
      latestMessageReadByGuardianCount
      threadsCount
      sender {
        id
        name
        messagerType
      }
      receivers {
        id
        name
        messagerType
      }
      messages {
        id
        body
        sentAt
        deletedAt
        document {
          original {
            url
            metadata {
              filename
              size
            }
          }
        }
        author {
          id
          authorType
          firstName
          lastName
        }
        channel {
          id
          type
        }
      }
    }
  }
`;

export const APPEND_MESSAGE = gql`
  mutation appendMessage($id: ID!, $body: String!, $type: ChannelCategory = MESSAGE_THREAD, $document: JSON) {
    appendMessage(body: $body, document: $document, channel: { id: $id, type: $type }) {
      message {
        id
        body
        sentAt
        author {
          id
          firstName
          authorType
          lastName
        }
        channel {
          id
          type
        }
      }
      errors
    }
  }
`;

export const UPDATE_CHILD = gql`
  mutation updateChild(
    $id: ID!
    $careNotes: String
    $profilePictureAllowed: Boolean
    $tougoPhotosAllowed: Boolean
    $tougoGroupPhotosAllowed: Boolean
    $marketingPhotosAllowed: Boolean
  ) {
    updateChild(
      id: $id
      careNotes: $careNotes
      profilePictureAllowed: $profilePictureAllowed
      tougoPhotosAllowed: $tougoPhotosAllowed
      tougoGroupPhotosAllowed: $tougoGroupPhotosAllowed
      marketingPhotosAllowed: $marketingPhotosAllowed
    ) {
      id
      name
      careNotes
      profilePictureAllowed
      tougoPhotosAllowed
      tougoGroupPhotosAllowed
      marketingPhotosAllowed
    }
  }
`;

export const UPDATE_REGIONAL_MANAGER = gql`
  mutation updateRegionalManager($id: ID!, $workerId: ID!) {
    updateRegionalManager(id: $id, workerId: $workerId) {
      id
      director {
        id
        name
      }
    }
  }
`;

export const CREATE_ABSENCE = gql`
  mutation createAbsence($childId: ID!, $dates: [ISO8601Date!]!, $category: AbsenceCategory!, $description: String) {
    createAbsence(childId: $childId, dates: $dates, category: $category, description: $description) {
      id
    }
  }
`;

export const CREATE_MUNICIPALITY = gql`
  mutation createMunicipality($regionId: ID!, $name: String!) {
    createMunicipality(regionId: $regionId, name: $name) {
      id
    }
  }
`;

export const DELETE_MUNICIPALITY = gql`
  mutation deleteMunicipality($id: ID!) {
    deleteMunicipality(id: $id) {
      id
    }
  }
`;
export const GET_REGIONS = gql`
  query {
    regions {
      id
      name
      municipalities {
        id
        name
        childrenPerWorker
      }
    }
  }
`;

export const GET_REGION = gql`
  query ($id: ID!) {
    region(id: $id) {
      id
      name
      director {
        id
        name
      }
      serviceManagers {
        id
        name
      }
      workers {
        id
        name
        role
      }
      municipalities {
        id
        name
        childrenPerWorker
        daycareCenters {
          id
        }
      }
      possibleDirectors {
        id
        name
      }
      possibleServiceManagers {
        id
        name
      }
    }
  }
`;

export const GET_MUNICIPALITY = gql`
  query ($id: ID!) {
    municipality(id: $id) {
      id
      name
      childrenPerWorker
      region {
        id
        name
      }
      vacationPeriods {
        id
        description
        startDate
        endDate
        bookableBetween {
          fromDate
          untilDate
        }
      }
    }
  }
`;

export const GET_WORKER = gql`
  query ($id: ID!) {
    worker(id: $id) {
      id
      name
      firstName
      lastName
      role
      title
      active
      activeSince
      activeUntil
      lastSignInAt
      username
      profilePicture {
        extraSmall {
          url
        }
      }
      assignments {
        id
        daycareCenter {
          id
          name
        }
        validFrom
        validUntil
        category
      }
    }
  }
`;

export const GET_PUBLIC_DAYCARE_CENTERS = gql`
  query publicDaycareCenters {
    publicDaycareCenters {
      id
      name
    }
  }
`;

export const GET_WORKER_ROLES = gql`
  query workerRoles {
    workerRoles {
      value
      label
    }
  }
`;

export const CREATE_WORKER = gql`
  mutation createWorker(
    $firstName: String!
    $lastName: String!
    $phoneNumber: String
    $identityNumber: String!
    $role: WorkerRole!
    $streetAddress: String
    $postCode: String
    $daycareCenterId: ID
    $contractStartDate: ISO8601Date
    $contractEndDate: ISO8601Date
  ) {
    createWorker(
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
      identityNumber: $identityNumber
      role: $role
      streetAddress: $streetAddress
      postCode: $postCode
      daycareCenterId: $daycareCenterId
      contractStartDate: $contractStartDate
      contractEndDate: $contractEndDate
    ) {
      id
    }
  }
`;

export const NEW_MASS_ANNOUNCEMENT = gql`
  mutation newMassAnnouncement(
    $subject: String!
    $body: String!
    $daycareCenterIds: [ID!]
    $regionIds: [ID!]
    $municipalityIds: [ID!]
    $sendToAllDaycareCenters: Boolean
    $staffOnly: Boolean
    $marketing: Boolean
  ) {
    newMassAnnouncement(
      subject: $subject
      body: $body
      daycareCenterIds: $daycareCenterIds
      regionIds: $regionIds
      municipalityIds: $municipalityIds
      sendToAllDaycareCenters: $sendToAllDaycareCenters
      staffOnly: $staffOnly
      marketing: $marketing
    ) {
      id
    }
  }
`;

export const DELETE_ABSENCE = gql`
  mutation deleteAbsence($absenceId: ID!) {
    deleteAbsence(absenceId: $absenceId) {
      id
    }
  }
`;

export const GET_UNREAD_COUNT = gql`
  query unreadMessageThreadsCount {
    unreadMessageThreadsCount
  }
`;

export const GET_ANNOUNCEMENTS = gql`
  query announcements {
    announcements {
      id
      subject
      body
      deletedAt
      author {
        id
        authorType
        firstName
        lastName
      }
      sentAt
      marketing
      staffOnly
      unreadCount
      totalReadCount
      totalRecipientCount
      guardianReadCount
      guardianRecipientCount
      workerReadCount
      workerRecipientCount
    }
  }
`;

export const GET_ANNOUNCEMENT_RECEIVERS = gql`
  query regions {
    regions {
      id
      name
      municipalities {
        id
        name
        daycareCenters {
          id
          name
        }
      }
    }
  }
`;

export const CREATE_NEW_CONTACT = gql`
  mutation createDaycareContact($daycareCenterId: ID!, $name: String!, $phoneNumber: String!) {
    createDaycareContact(daycareCenterId: $daycareCenterId, name: $name, phoneNumber: $phoneNumber) {
      id
      name
    }
  }
`;
export const UPDATE_CONTACT = gql`
  mutation updateDaycareContact($daycareContactId: ID!, $name: String!, $phoneNumber: String!) {
    updateDaycareContact(daycareContactId: $daycareContactId, name: $name, phoneNumber: $phoneNumber) {
      id
      name
    }
  }
`;
export const DELETE_CONTACT = gql`
  mutation deleteDaycareContact($daycareContactId: ID!) {
    deleteDaycareContact(daycareContactId: $daycareContactId) {
      id
    }
  }
`;

export const CREATE_PHOTO = gql`
  mutation createPhoto(
    $daycareCenterId: ID!
    $childIds: [ID!]!
    $daycareGroupIds: [ID!]!
    $description: String!
    $photos: [JSON!]!
  ) {
    createPhoto(
      daycareCenterId: $daycareCenterId
      childIds: $childIds
      daycareGroupIds: $daycareGroupIds
      description: $description
      photos: $photos
    ) {
      id
    }
  }
`;

import React, { useState, useEffect } from "react";
import ReactDayPicker from "react-day-picker";
import { loader } from "graphql.macro";
import { useQuery, useMutation } from "react-apollo";
import { DateTime } from "luxon";

// Styles and assets
import ChevronLeftIcon from "mdi-react/ChevronLeftIcon";
import ChevronRightIcon from "mdi-react/ChevronRightIcon";
import { useTranslation } from "react-i18next";
import styles from "./CalendarPopover.module.scss";

// Queries
const selectedDateQuery = loader("./selectedDateQuery.graphql");
const setSelectedDateMutation = loader("./selectedDateMutation.graphql");

const CalendarPopover = ({
  selectedDateModifier = value => value,
  type, children = null,
  propsOpen = null,
  propsSetDate,
  disablePast
}) => {
  let { data: { selectedDate } = {} } = useQuery(selectedDateQuery);
  const [setSelectedDate] = useMutation(setSelectedDateMutation);
  // Apply modifiers to selected day so week calendar highlights monday - fri and month calendar highlights the beginning of the month
  const modifiedSelectedDate = selectedDate
    ? selectedDateModifier(selectedDate)
    : selectedDateModifier(DateTime.local().toISODate());

  const selectedDateObject = DateTime.fromISO(modifiedSelectedDate);

  const [open, setOpen] = useState(true);
  // ReactDayPicker needs to use JS Date object so we convert toJSDate
  const [focusedMonth, setFocusedMonth] = useState(selectedDateObject.toJSDate());

  const { t } = useTranslation();

  useEffect(() => {
    if (open) {
      const close = () => setOpen(false);
      window.addEventListener("click", close);
      return () => window.removeEventListener("click", close);
    }
  }, [open, setOpen]);

  useEffect(() => {
    setOpen(!open)
  }, [propsOpen])

  const onDayClick = (value, { disabled }) => {
    if (disabled) {
      return;
    }
    if(propsOpen !== null){
        propsSetDate(value)
    }
    // Apply modifiers so the week calendar selects monday and month select beginning of month.
    const modifiedDate = selectedDateModifier(DateTime.fromJSDate(value).toISODate());
    const date = modifiedDate;

    setSelectedDate({
      variables: { date },
    });
  };

  let selectedDays = selectedDateObject.toJSDate();

  if (type === "week") {
    selectedDays = {
      from: selectedDateObject.toJSDate(),
      to: selectedDateObject.plus({ days: 4 }).toJSDate(),
    };
  }
  // else {
  //   // FIXME: "month" is actually undefined, and so is "day" so this will never work
  //   selectedDays = {
  //     from: selectedDate.startOf("month").toDate(),
  //     to: selectedDate.endOf("month").toDate(),
  //   };
  // }


  return (
    <div className={styles.container}>
      {children ? children :
        <button className={styles.button} onClick={() => setOpen(!open)}>
          {t("Calendar.choose_from_calendar")}
        </button>
      }
      {open && (
        <div className={styles.dayPickerContainer} onClick={(event) => event.stopPropagation()}>
          <ReactDayPicker
            onDayClick={onDayClick}
            showOutsideDays
            firstDayOfWeek={1}
            modifiers={{ highlight: selectedDays }}
            month={focusedMonth}
            {...(disablePast && {disabledDays: [{before: new Date()}]})}
            captionElement={({ date }) => (
              <div className="DayPicker-Caption">{DateTime.fromJSDate(date).toFormat("LLLL y")}</div>
            )}
            navbarElement={({ previousMonth, nextMonth }) => (
              <div className="DayPicker-Navbar">
                <button
                  className="DayPicker-NavButton"
                  type="button"
                  title={DateTime.fromJSDate(previousMonth).toFormat("LLL")}
                  aria-label={DateTime.fromJSDate(previousMonth).toFormat("LLL")}
                  onClick={() =>
                    setFocusedMonth(DateTime.fromJSDate(focusedMonth).startOf("month").minus({ days: 1 }).toJSDate())
                  }
                >
                  <ChevronLeftIcon />
                </button>
                <button
                  className="DayPicker-NavButton"
                  type="button"
                  title={DateTime.fromJSDate(nextMonth).toFormat("LLL")}
                  aria-label={DateTime.fromJSDate(nextMonth).toFormat("LLL")}
                  onClick={() =>
                    setFocusedMonth(DateTime.fromJSDate(focusedMonth).endOf("month").plus({ days: 1 }).toJSDate())
                  }
                >
                  <ChevronRightIcon />
                </button>
              </div>
            )}
            renderDay={(day, modifiers) => {
              const { hidden } = modifiers;

              if (hidden) {
                return <React.Fragment />; // returning null fails proptype check
              }

              return (
                <React.Fragment>
                  <div className="DayPicker-DaySquarer" />
                  <div className="DayPicker-DayOutline" />
                  <div className="DayPicker-DayPositioner">{day.getDate()} </div>
                </React.Fragment>
              );
            }}
            weekdayElement={({ weekday }) => {
              // Weekday is a number so init new DateTime and set the weekday accoding to what DayPicker gives
              const day = DateTime.local().set({ weekday: weekday });
              return (
                <div className="DayPicker-Weekday" role="columnheader">
                  <abbr title={day.toFormat("cccc")}>{day.toFormat("ccc")}</abbr>
                </div>
              );
            }}
          />
        </div>
      )}
    </div>
  );
};

export default CalendarPopover;

import React, { useState } from "react";
import styles from "./ReservationStats.module.scss";
import gql from "graphql-tag";
import { useTranslation } from "react-i18next";
import { Query } from "react-apollo";
// import dayjs from "dayjs";

import { List, ListItem, ListContent } from "../../ListItem/ListItem";
import Loader from "../../Loader/Loader";
import ErrorMessage from "../../ErrorMessage/ErrorMessage";
import ListItemNotifier from "../../ListItemNotifier/ListItemNotifier";

//Icons
import CalendarIcon from "mdi-react/CalendarIcon";
import ChevronLeftIcon from "mdi-react/ChevronLeftIcon";
import ChevronRightIcon from "mdi-react/ChevronRightIcon";
import AlertOutlineIcon from "mdi-react/AlertOutlineIcon";

const MONTH_STAMP = "YYYY-MM";

const ReservationStats = ({ child }) => {
  // HACK: hack to make sure /shared uses the same instance of dayjs as /customer or /staff
  const dayjs = window.localizedDayjs;

  const [selectedMonth, setSelectedMonth] = useState(dayjs().set("day", 1));
  const { t } = useTranslation();

  let startMonth, endMonth;

  if (child.daycarePlacements && child.daycarePlacements.length) {
    let earliestStartDate, lastEndDate;

    child.daycarePlacements.forEach((placement) => {
      let startDate = dayjs(placement.startDate);
      let endDate = dayjs(placement.endDate);

      if (earliestStartDate) {
        if (startDate.isBefore(earliestStartDate)) {
          earliestStartDate = startDate;
        }
      } else {
        earliestStartDate = startDate;
      }

      if (lastEndDate) {
        if (endDate.isAfter(lastEndDate)) {
          lastEndDate = endDate;
        }
      } else {
        lastEndDate = endDate;
      }
    });

    if (earliestStartDate) {
      startMonth = dayjs(earliestStartDate).format(MONTH_STAMP);
    }
    if (lastEndDate) {
      endMonth = dayjs(lastEndDate).format(MONTH_STAMP);
    }
  }

  return (
    <List title={t("Profile.ReservationStats.title")} icon={CalendarIcon}>
      <div className={styles.month}>
        <h3 className={styles.monthName}>
          {selectedMonth.format("MMMM YYYY")}
        </h3>
        <div className={styles.monthNavigation}>
          <button
            className={styles.monthNavigationButton}
            onClick={() => setSelectedMonth(selectedMonth.subtract(1, "month"))}
            disabled={
              startMonth && startMonth === selectedMonth.format(MONTH_STAMP)
            }
          >
            <ChevronLeftIcon className={styles.monthNavigationIcon} />
          </button>
          <button
            className={styles.monthNavigationButton}
            onClick={() => setSelectedMonth(selectedMonth.add(1, "month"))}
          >
            <ChevronRightIcon className={styles.monthNavigationIcon} />
          </button>
        </div>
      </div>
      <Query
        query={GET_CHILD_USAGE}
        variables={{
          id: child.id,
          date: selectedMonth.format("YYYY-MM-DD"),
        }}
      >
        {({ data, loading, error }) => {
          if (loading)
            return (
              <ListContent>
                <Loader centered text={t("Profile.ReservationStats.loader")} />
              </ListContent>
            );
          if (error)
            return (
              <ListContent>
                <ErrorMessage error={error}>
                  {t("Profile.ReservationStats.load_error")}
                </ErrorMessage>
              </ListContent>
            );
          const { child } = data;
          const { timeUsage, activePlacements } = child;

          let weekHours = false;

          if (activePlacements && activePlacements.some(placement => placement.hoursPerWeek && placement.hoursPerWeek > 0)) {
            weekHours = activePlacements.find(placement => placement.hoursPerWeek).hoursPerWeek;
          }

          return (
            <React.Fragment>
              <ListItem
                right={
                  <UsedTime
                    activePlacements={activePlacements}
                    timeUsage={timeUsage}
                    timeMinutes={timeUsage.used}
                    timeDays={timeUsage.usedDays}
                    t={t}
                  />
                }
              >
                {t("Profile.ReservationStats.care_used")}
              </ListItem>
              <ListItem
                right={
                  <UsedTime
                    activePlacements={activePlacements}
                    timeUsage={timeUsage}
                    timeMinutes={timeUsage.reserved}
                    timeDays={timeUsage.reservedDays}
                    t={t}
                  />
                }
              >
                {t("Profile.ReservationStats.care_reserved")}
              </ListItem>
              <ListItem>
                <ListItemNotifier />
              </ListItem>
              {weekHours && 
                (
                <ListItem
                  right={
                    <div>
                      <HourlyTime time={weekHours * 60} t={t} /> / {t("Calendar.Day.week_title")}
                    </div>
                  }
                >
                  {t("Profile.ReservationStats.care_placements")}
                </ListItem>
                )
              }

            </React.Fragment>
          );
        }}
      </Query>
    </List>
  );
};

const UsedTime = ({ timeUsage, timeMinutes, timeDays, t, activePlacements }) => {
  const [exceedsContract, setExceedsContract] = useState(false);


  if (
    (timeUsage.hourlyContract &&
      timeUsage.hoursPerMonth &&
      timeMinutes > timeUsage.hoursPerMonth * 60) ||
    (!timeUsage.hourlyContract &&
      timeUsage.daysPerMonth &&
      timeDays > timeUsage.daysPerMonth)
  ) {
    !exceedsContract && setExceedsContract(true);
  } else {
    exceedsContract && setExceedsContract(false);
  }

  let className = styles.usedTime;
  if (exceedsContract) className += ` ${styles.usedTimeExceeded}`;


  if (timeUsage.hourlyContract) {
    return (
      <div className={className}>
        {exceedsContract && <AlertOutlineIcon />}
        <HourlyTime time={timeMinutes} t={t} />
        {!!timeUsage.hoursPerMonth && (
          <React.Fragment>
            {" "}
            / <HourlyTime time={timeUsage.hoursPerMonth * 60} t={t} /> (
            {timeDays} {t("Profile.ReservationStats.days_abbr")})
          </React.Fragment>
        )}
      </div>
    );
  } else {
    return (
      <div className={className}>
        {exceedsContract && <AlertOutlineIcon />}
        {timeDays}
        {!!timeUsage.daysPerMonth && (
          <React.Fragment> / {timeUsage.daysPerMonth}</React.Fragment>
        )}{" "}
        {t("Profile.ReservationStats.days_abbr")} (
        <HourlyTime time={timeMinutes} t={t} />)
      </div>
    );
  }
};

const HourlyTime = ({ time, t }) => {
  const hours = parseInt(time / 60);
  const minutes = time % 60;

  return (
    <React.Fragment>
      {hours} {t("Profile.ReservationStats.hour_abbr")}
      {minutes !== 0 && (
        <React.Fragment>
          {" "}
          {minutes} {t("Profile.ReservationStats.min_abbr")}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const DaycarePlacements = ({ daycarePlacements }) => {
  const placements = daycarePlacements.map(
    (placement) => placement.serviceRequirement
  );

  return placements.join(", ");
};

const GET_CHILD_USAGE = gql`
  query child($id: ID!, $date: ISO8601Date!) {
    child(id: $id) {
      id
      name
      timeUsage(date: $date) {
        reserved
        used
        reservedDays
        usedDays
        hoursPerMonth
        daysPerMonth
        hourlyContract
      }
      activePlacements {
        id
        category
        hoursPerWeek
      }
      daycarePlacements(date: $date) {
        id
        category
        serviceRequirement
      }
    }
  }
`;

export default ReservationStats;

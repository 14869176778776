import React, { useRef, useLayoutEffect } from "react";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import { useSpring, animated } from "react-spring";
import { useTranslation } from "react-i18next";

import MessageTextIcon from "mdi-react/MessageTextIcon";
import LockOutlineIcon from "mdi-react/LockOutlineIcon";
import ForumIcon from "mdi-react/ForumIcon";
import StarOutlineIcon from "mdi-react/StarOutlineIcon";
import StarIcon from "mdi-react/StarIcon";
import PackageDownIcon from "mdi-react/PackageDownIcon";
import PackageUpIcon from "mdi-react/PackageUpIcon";

import styles from "./Thread.module.scss";
import { isNativeApp } from "../../UserAgent/UserAgent";
import Avatar from "../../Avatar/Avatar";
import OverlappingAvatars from "../../OverlappingAvatars/OverlappingAvatars";
import Logo from "../../Logo/Logo";

const Thread = withRouter(
  ({
    title,
    from,
    to,
    toCutoff = false,
    children,
    bottom,
    profilePictureUrl,
    initials,
    iconType,
    locked,
    archived,
    pinned,
    handleArchival,
    handlePin,
    avatarData,
    marketing,
    internal,
    location,
  }) => {
    const contentRef = useRef(null);
    const contentInnerRef = useRef(null);
    const { t } = useTranslation();

    let offsetModifier = 20;
    if (document.body.clientWidth < 992) offsetModifier = 70;

    const [{ contentScrollTop }, setContentScrollTop] = useSpring(() => ({
      immediate: true,
      windowScrollTop: document.body.scrollHeight - document.body.clientHeight,
      contentScrollTop: contentRef.current
        ? contentRef.current.scrollHeight - contentRef.current.clientHeight
        : 0,
      config: { mass: 1, tension: 200, clamp: true, friction: 12 },
      onFrame: (props) => {
        window.scroll(0, props.windowScrollTop);
      },
    }));

    useLayoutEffect(() => {
      const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
      setContentScrollTop({
        immediate: false,
        windowScrollTop:
          document.body.scrollHeight - document.body.clientHeight,
        contentScrollTop:
          location &&
          location.hash &&
          location.hash.length &&
          document.querySelector(location.hash)
            ? document.querySelector(location.hash).offsetTop - offsetModifier
            : contentInnerRef.current &&
              contentInnerRef.current.children &&
              contentInnerRef.current.children.length > 0
            ? contentInnerRef.current.children[
                contentInnerRef.current.children.length - 1
              ].offsetTop - offsetModifier
            : scrollHeight - clientHeight,
        from: {
          contentScrollTop: scrollTop,
          windowScrollTop: window.scrollTop,
        },
      });
    }, [children]);

    const headerClassNames = classNames(styles.header, {
      [styles.headerWeb]: !isNativeApp(),
    });
    const footerClassNames = classNames(styles.footer, {
      [styles.footerWeb]: !isNativeApp(),
    });
    const pinnedClassNames = classNames(styles.headerButtonInner, {
      [styles.pinned]: pinned,
    });
    const archivedClassNames = classNames(styles.headerButtonInner, {
      [styles.archived]: archived,
    });

    return (
      <div className={styles.container}>
        <div className={headerClassNames}>
          <div className={styles.headerAvatar}>
            {iconType === "MULTIPLE_CHILDREN" ? (
              <OverlappingAvatars avatarData={avatarData} />
            ) : (
              <Icon
                iconType={iconType}
                profilePictureUrl={profilePictureUrl}
                initials={initials}
              />
            )}
          </div>
          <div className={styles.headerContent}>
            <div className={styles.headerContentText}>
              <h3 className={styles.headerFrom}>
                {marketing && (
                  <span className={styles.marketing}>
                    {t("Messages.marketing")}
                  </span>
                )}
                {internal && (
                  <span className={styles.internal}>
                    {t("Messages.internal")}
                  </span>
                )}
                {`${t("Staff.Messages.from")}: `}
                <span className={styles.headerNames}>{from}</span>
              </h3>
              {to && (
                <h3
                  className={
                    toCutoff ? styles.headerToWithCutoff : styles.headerFrom
                  }
                >
                  {`${t("Staff.Messages.to")}: `}
                  <span className={styles.headerNames}>{to}</span>
                </h3>
              )}
              <h2 className={styles.headerTitle}>{title}</h2>
            </div>
          </div>
          {(handleArchival || handlePin) && (
            <div className={styles.headerButtons}>
              {handlePin &&
                (pinned ? (
                  <button
                    className={styles.headerButtonOuter}
                    onClick={handlePin}
                    title={t("Messages.unpin")}
                    tabIndex="0"
                  >
                    <div className={pinnedClassNames} tabIndex="-1">
                      <StarIcon />
                    </div>
                  </button>
                ) : (
                  <button
                    className={styles.headerButtonOuter}
                    onClick={handlePin}
                    title={t("Messages.pin")}
                    tabIndex="0"
                  >
                    <div className={pinnedClassNames} tabIndex="-1">
                      <StarOutlineIcon />
                    </div>
                  </button>
                ))}
              {handleArchival &&
                (archived ? (
                  <button
                    className={styles.headerButtonOuter}
                    onClick={handleArchival}
                    title={t("Messages.unarchive")}
                    tabIndex="0"
                  >
                    <div className={archivedClassNames} tabIndex="-1">
                      <PackageUpIcon />
                    </div>
                  </button>
                ) : (
                  <button
                    className={styles.headerButtonOuter}
                    onClick={handleArchival}
                    title={t("Messages.archive")}
                    tabIndex="0"
                  >
                    <div className={archivedClassNames} tabIndex="-1">
                      <PackageDownIcon />
                    </div>
                  </button>
                ))}
            </div>
          )}
        </div>
        <animated.div
          className={styles.content}
          ref={contentRef}
          scrollTop={contentScrollTop}
        >
          <div ref={contentInnerRef} className={styles.contentInner}>
            {children}
          </div>
        </animated.div>
        <div className={footerClassNames}>
          {bottom && !locked ? (
            bottom
          ) : locked ? (
            <div className={styles.lockedContainer}>
              <LockOutlineIcon className={styles.lockedIcon}></LockOutlineIcon>
              <div className={styles.lockedText}>
                {t("Messages.thread_locked")}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
);

const Icon = ({ iconType, profilePictureUrl, initials }) => {
  if (iconType === "MASS_ANNOUNCEMENT") {
    return <Logo className={styles.logo} />;
  } else if (iconType === "REPLYABLE") {
    return <ForumIcon className={styles.icon} />;
  } else if (iconType === "ANNOUNCEMENT") {
    return <MessageTextIcon className={styles.iconSmall} />;
  } else {
    return <Avatar url={profilePictureUrl} initials={initials} />;
  }
};

export default Thread;

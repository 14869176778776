import React, {useEffect, useState} from 'react'
import {ListCheckbox} from "../../../shared/components/ListItem/ListItem";
import CalendarPopover from "../../Calendar/CalendarPopover/CalendarPopover";
import dayjs from 'dayjs'
import styles from "./RSVPCheckBox.module.scss";
import { DateTime } from 'luxon';


const RSVPCheckBox = ({
  requireRSVP,
  setRequireRSVP,
  rsvpReplyDate,
  setRsvpReplyDate,
  editMode = true,
  t,
  startAndEndDates,
  focusToDayBeforeStartDate
}) => {

  const TIME_STEP = 60 * 5;
  const [dayPickerOpen, setDayPickerOpen] = useState(true)
  const [rsvpTime, setRsvpTime] = useState(null)
  const [rsvpDate, setRsvpDate] = useState(dayjs(rsvpReplyDate).format('DD.MM'))


  //handle date change to parent
  //handle displaying value in this component
  const rsvpDateChange = (date) => {
    const hours = parseInt(rsvpTime[0] + rsvpTime[1])
    const minutes = parseInt(rsvpTime[3] + rsvpTime[4])
    let newReplyDate = DateTime.fromJSDate(date).set({ hours: hours, minutes: minutes }).toISO()
    setRsvpReplyDate(newReplyDate)
    setRsvpDate(dayjs(date).format('DD.MM'))
    setDayPickerOpen(false)
  }

  //handle displaying rsvp time in component
  useEffect(() => {
    if(rsvpReplyDate && !rsvpTime){
      setRsvpTime(dayjs(rsvpReplyDate).format('HH:mm'))
    }
  }, [rsvpReplyDate])

  //handle rsvp time snapping to day before in event creation
  useEffect(() => {
    if(focusToDayBeforeStartDate){
      if(startAndEndDates){
        const { startDate } = startAndEndDates
        setRsvpDate(dayjs(startDate).subtract(1, 'day').format('DD.MM'))
      }
    }
  }, [startAndEndDates])

  useEffect(() => {
    if(!focusToDayBeforeStartDate){
      setRsvpDate(dayjs(rsvpReplyDate).format('DD.MM'))
    }
  }, [rsvpReplyDate])

  //update rsvp date in parent when time changes
  useEffect(() => {
    if(rsvpTime){
      const hours = parseInt(rsvpTime[0] + rsvpTime[1])
      const minutes = parseInt(rsvpTime[3] + rsvpTime[4])
      if(rsvpReplyDate){
        let newRspvDate = DateTime.fromISO(rsvpReplyDate).set({ hours:  hours, minutes: minutes }).toISO()
        setRsvpReplyDate(newRspvDate)
      }
    }
  })

  useEffect(() => {
    if(focusToDayBeforeStartDate){
      setRsvpTime("23:59")
    }
  }, [])

  return(
    <>
    <div className={styles.border}>
      <ListCheckbox
        type={"checkbox"}
        checked={requireRSVP}
        disabled={!editMode}
        bottomNoPadding
        onClick={() => setRequireRSVP()}
      >
        {t("Eventcalendar.AskForRSVP")}
      </ListCheckbox>


      {requireRSVP && <div>
        <p className={styles.rsvpDeadline} style={{opacity: editMode ? 1 : 0.5}}>
          {requireRSVP && ` ${t("Eventcalendar.Deadline")}: `}
        </p>
        <div style={{display: 'inline-flex'}}>
          <CalendarPopover propsOpen={dayPickerOpen} propsSetDate={date => rsvpDateChange(date)}>
            <p
            style={{ opacity: editMode ? 1 : 0.7}}
            className={styles.rsvpDateText}
            onClick={() => {
              if(!editMode){
                return
              }
              else{
                setDayPickerOpen(!dayPickerOpen)}
              }
            }>
               {rsvpDate} 
            </p>

              <input
               disabled={!focusToDayBeforeStartDate && !editMode}
               className={styles.rsvpTimePicker}
               style={{display: 'inline-flex', height: 30, position: 'relative', bottom: 2}}
               value={rsvpTime}
               onChange={e => setRsvpTime(e.target.value)}
               type="time"
               id="appt"
               step={TIME_STEP}
               />


          </CalendarPopover>
          </div>
      </div>}

    </div>
    {requireRSVP && editMode && <p className={styles.orangeText}>{t("Eventcalendar.RSVPDeadlineReminder")}</p>}
    </>
  )
}


export default RSVPCheckBox

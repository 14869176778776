import { useQuery } from "react-apollo";
import gql from "graphql-tag";
import { DateTime } from "luxon";

const clientStateQuery = gql`
  {
    selectedDate @client
    selectedDaycareCenter @client
    selectedDaycareGroup @client
  }
`;

const useClientState = () => {
  const { data } = useQuery(clientStateQuery);

  const selectedDate = data && data.selectedDate ? data.selectedDate : DateTime.local().toISODate();
  const selectedDaycareCenter = data && data.selectedDaycareCenter ? data.selectedDaycareCenter : null;
  const selectedDaycareGroup = data ? data.selectedDaycareGroup : null;

  return { selectedDate, selectedDaycareCenter, selectedDaycareGroup };
};

export default useClientState;

import React, { useState, useEffect, useRef, useCallback } from "react";
import ReactDayPicker from "react-day-picker";
import { DateUtils, ModifiersUtils } from "react-day-picker";
import { DateTime } from "luxon";
// import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import ChevronLeftIcon from "mdi-react/ChevronLeftIcon";
import ChevronRightIcon from "mdi-react/ChevronRightIcon";

// Intentionally not a CSS module due to react-day-picker's classnames being
// meaningful in the app's logic. Converting to module is possible but a hassle.
import "./DayPicker.scss";

const {
  isSameDay,
  clone,
  addDayToRange,
  isDate,
  isDayInRange,
  addMonths
} = DateUtils;
const { getModifiersForDay } = ModifiersUtils;

const DayPicker = ({
  selectedDays = [],
  setSelectedDays = () => {},
  setFocusedDay = () => {},
  reservedDays = [],
  modifiers = {},
  showBottomMarkersForModifiers = [],
  showTopRightMarkersForModifiers = [],
  showTopLeftMarkersForModifiers = [],
  hideBottomMarkersOnWeekends = false,
  hideTopRightMarkersOnWeekends = false,
  hideTopLeftMarkersOnWeekends = false,
  showOfficialMarkers = true,
  showPersonalMarkers = true,
  fromDate = new Date(),
  toDate = fromDate,
  initialDate = fromDate,
  focusedMonth = initialDate,
  showWeekNumbers = false,
  setFocusedMonth = () => {},
  ...otherProps // passed to DayPicker
}) => {
  // HACK: hack to make sure /shared uses the same instance of dayjs as /customer or /staff
  const dayjs = window.localizedDayjs;

  // Handle keyboard selection and single-click selection
  const handleFocus = day => setFocusedDay(day);
  const handleBlur = () => setFocusedDay();
  const handleClick = (day, dayModifiers, event) => {
    event.preventDefault();
    // Clicking disabled days focuses them, but does nothing else
    if (dayModifiers["disabled"]) {
      setFocusedDay(day);
      return;
    } else {
      setFocusedDay();
    }

    // Clicking selected days deselects them
    if (selectedDays.some(selected => isSameDay(selected, day))) {
      return setSelectedDays(selectedDays =>
        selectedDays.filter(selected => !isSameDay(selected, day))
      );
    }

    // Clicking other kinds of days adds them
    setSelectedDays(
      addRangeBasedOnInteractionModes(
        { from: day, to: day },
        day,
        selectedDays,
        modifiers
      )
    );
  };

  const { t } = useTranslation();

  return (
    <React.Fragment>
      <DayRangePicker
        modifiers={modifiers}
        selectedDays={selectedDays}
        reservedDays={reservedDays}
        setSelectedDays={setSelectedDays}
        fromMonth={fromDate}
        toMonth={toDate}
        initialMonth={initialDate}
        month={focusedMonth}
        showOutsideDays
        firstDayOfWeek={1}
        onDayClick={handleClick}
        onDayFocus={handleFocus}
        onBlur={handleBlur}
        setFocusedDay={setFocusedDay}
        showWeekNumbers={showWeekNumbers}
        renderDay={(day, modifiers) => {
          const { reserved, hidden, weekend } = modifiers;
          const modifierList = Object.keys(modifiers);

          const weekday = day.getDay();
          const isWeekend = weekday === 6 || weekday === 0;

          if (hidden) {
            return <React.Fragment />; // returning null fails proptype check
          }

          const reservationGroups = modifierList
            .filter(mod => mod.startsWith("reservationGroup-"))
            .map(mod => +mod.split("reservationGroup-")[1]);
          const absenceGroups = modifierList
            .filter(mod => mod.startsWith("absenceGroup-"))
            .map(mod => +mod.split("absenceGroup-")[1]);
          const bottomMarkers =
            isWeekend && hideBottomMarkersOnWeekends
              ? []
              : modifierList.filter(modifier =>
                  showBottomMarkersForModifiers.includes(modifier)
                );
          const topRightMarkers =
            isWeekend && hideTopRightMarkersOnWeekends
              ? []
              : modifierList.filter(modifier =>
                  showTopRightMarkersForModifiers.includes(modifier)
                );
          const topLeftMarkers =
            isWeekend && hideTopLeftMarkersOnWeekends
              ? []
              : modifierList.filter(modifier =>
                  showTopLeftMarkersForModifiers.includes(modifier)
                );

          return (
            <React.Fragment>
              <div className="DayPicker-DaySquarer" />
              <div
                className="DayPicker-DayBackground"
                style={{
                  background:
                    reserved &&
                    (reservationGroups.length > 0
                      ? generateGradient(reservationGroups, absenceGroups)
                      : `hsl(206, 64%, 64%)`)
                }}
              />
              <div className="DayPicker-DayOutline" />
              <div className="DayPicker-DayPositioner">
                {day.getDate()}{" "}
                {bottomMarkers.length > 0 && (
                  <div className="DayPicker-Markers DayPicker-Markers--bottom">
                    {bottomMarkers.map(modifier => (
                      <div key={modifier} className={"DayPicker-Marker"} />
                    ))}
                  </div>
                )}
                {topRightMarkers.length > 0 && (
                  <div className="DayPicker-Markers DayPicker-Markers--topRight">
                    {topRightMarkers.map(modifier => (
                      <div key={modifier} className={"DayPicker-Marker"} />
                    ))}
                  </div>
                )}
                {topLeftMarkers.length > 0 && (
                  <div className="DayPicker-Markers DayPicker-Markers--topLeft">
                    {topLeftMarkers.map(modifier => (
                      <div key={modifier} className={"DayPicker-LetterMarker"}>
                        {t(`Daypicker.Legend.${modifier}_marker_letter`)}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </React.Fragment>
          );
        }}
        captionElement={({ date, localeUtils, onClick }) => (
          <div className="DayPicker-Caption">
            <p style={{position: 'absolute', marginBottom: 20}}>
            {dayjs(date).format("MMMM YYYY")}
            </p>
          </div>
        )}
        weekdayElement={({ weekday }) => {
          const day = dayjs().day(weekday);
          return (
            <div className="DayPicker-Weekday" role="columnheader">
              <abbr title={day.format("dddd")}>{day.format("dd")}</abbr>
            </div>
          );
        }}
        navbarElement={({ previousMonth, nextMonth }) => (
          <div className="DayPicker-Navbar">
            <button
              className="DayPicker-NavButton"
              type="button"
              title={dayjs(previousMonth).format("MMM")}
              aria-label={dayjs(previousMonth).format("MMM")}
              onClick={() =>
                setFocusedMonth(
                  DateTime.fromJSDate(focusedMonth)
                    .minus({ months: 1 })
                    .toJSDate()
                )
              }
            >
              <ChevronLeftIcon />
            </button>
            <button
              className="DayPicker-NavButton"
              type="button"
              title={dayjs(nextMonth).format("MMM")}
              aria-label={dayjs(nextMonth).format("MMM")}
              onClick={() =>
                setFocusedMonth(
                  DateTime.fromJSDate(focusedMonth)
                    .plus({ months: 1 })
                    .toJSDate()
                )
              }
            >
              <ChevronRightIcon />
            </button>
          </div>
        )}
        onMonthChange={month => {
          if (focusedMonth !== month) {
            setFocusedMonth(month);
          }
        }}
        {...otherProps}
      />
      <div className="DayPicker-Legend">
        {showOfficialMarkers && (
          <React.Fragment>
            <div className="DayPicker-LegendItem DayPicker-LegendItem--holiday">
              {t("Daypicker.Legend.holiday")}
            </div>
            <div className="DayPicker-LegendItem DayPicker-LegendItem--vacationPeriod">
              {t("Daypicker.Legend.vacation_day")}
            </div>
          </React.Fragment>
        )}
        {showPersonalMarkers && (
          <React.Fragment>
            <div className="DayPicker-LegendItem DayPicker-LegendItem--text">
              <span className="DayPicker-LegendItem-textMarker">
                {t("Daypicker.Legend.vacationDays_marker_letter")}
              </span>
              {t("Daypicker.Legend.vacation")}
            </div>
            <div className="DayPicker-LegendItem DayPicker-LegendItem--text">
              <span className="DayPicker-LegendItem-textMarker">
                {t("Daypicker.Legend.absences_marker_letter")}
              </span>
              {t("Daypicker.Legend.absence")}
            </div>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};
export default DayPicker;

const DayRangePicker = ({
  selectedDays,
  setSelectedDays,
  reservedDays,
  setFocusedDay,
  showWeekNumbers,
  modifiers: passedModifiers,
  ...props
}) => {
  // All modifiers but selections
  const modifiers = {
    ...passedModifiers
  };
  // Range-selection
  const [rangeSelection, setRangeSelection] = useState();

  const onRangeSelectionDone = ({ range, startedFrom }) => {
    setSelectedDays(
      addRangeBasedOnInteractionModes(range, startedFrom, selectedDays, {
        ...modifiers,
        selected: selectedDays
      })
    );
  };

  // Combine selected days and days currently in range-selection
  const allSelectedDays = rangeSelection
    ? addRangeBasedOnInteractionModes(
        rangeSelection.range,
        rangeSelection.startedFrom,
        selectedDays,
        {
          selected: selectedDays,
          ...modifiers
        }
      )
    : selectedDays;

  // Find days in reservations and selected days that are the last or first
  // days of a range, to style them in the calendar.
  // CSS has no proper tools for this. :(
  const ranges = [reservedDays, allSelectedDays];
  const lastsOfRange = ranges.map(days =>
    days.filter((day, index, days) => {
      if (index === days.length - 1) {
        return true;
      }

      const test = clone(day);
      test.setDate(day.getDate() + 1);
      const nextInListIsTomorrow = isSameDay(days[index + 1], test);

      return !nextInListIsTomorrow;
    })
  );
  const firstsOfRange = ranges.map(days =>
    days.filter((day, index, days) => {
      if (index === 0) {
        return true;
      }

      const test = clone(day);
      test.setDate(day.getDate() - 1);
      const previousInListIsYesterday = isSameDay(days[index - 1], test);

      return !previousInListIsYesterday;
    })
  );

  modifiers.firstOfReservedRange = firstsOfRange[0];
  modifiers.lastOfReservedRange = lastsOfRange[0];
  modifiers.selected = allSelectedDays;
  modifiers.firstOfSelectedRange = firstsOfRange[1];
  modifiers.lastOfSelectedRange = lastsOfRange[1];

  // Add rangeSelection to modifiers
  if (rangeSelection) {
    modifiers["selecting"] = rangeSelection;
  }

  // Event handlers
  const isSelecting = useRef(false);

  const startSelecting = (day, modifiers, event) => {
    if (modifiers["disabled"]) {
      setFocusedDay(day);
    } else {
      isSelecting.current = true;
      setRangeSelection({ startedFrom: day, range: { from: day, to: day } });
    }
  };

  const updateRangeSelection = element => {
    const { startedFrom, range } = rangeSelection;
    const dateString = element.getAttribute("aria-label");
    const day = new Date(dateString);

    if (isDate(day)) {
      const newRange = addDayToRange(day, {
        from: startedFrom,
        to: startedFrom
      });

      // For some reason addDayToRange returns nulls when adding a day
      // a range that just consists of itself.
      const value =
        newRange.from === null || newRange.to === null
          ? { from: startedFrom, to: startedFrom }
          : newRange;
      const valueFromString = value.from.toDateString();
      const valueToString = value.to.toDateString();

      if (
        valueFromString !== range.from.toDateString() ||
        valueToString !== range.to.toDateString()
      ) {
        setRangeSelection({ startedFrom, range: value });
      }

      return value;
    }
  };
  const updateSelection = event => {
    if (rangeSelection) {
      const { clientX, clientY } = event.touches
        ? event.touches[0] || event.changedTouches[0]
        : event;
      const element = document.elementFromPoint(clientX, clientY);

      if (element && element.classList.contains("DayPicker-Day")) {
        return updateRangeSelection(element);
      }
    }
  };

  const stopSelecting = event => {
    if (rangeSelection) {
      // If range is just 1 day, skip this and let handleClick take over,
      // but not if using touch. Touch can't fire clicks due to preventTouchScrolling().
      if (
        rangeSelection.range.from < rangeSelection.range.to ||
        event.type === "touchend"
      ) {
        event.preventDefault();
        onRangeSelectionDone(rangeSelection);
      }
      setRangeSelection();
    }
    isSelecting.current = false;
    setFocusedDay();
  };

  useEffect(() => {
    // Awkwardly guard against race condition where startSelecting happens,
    // but stopSelecting fires before React has had time to update rangeSelection.
    if (rangeSelection && !isSelecting.current) {
      onRangeSelectionDone(rangeSelection);
      setRangeSelection();
    }
  });

  useEffect(() => {
    // This is needed because react-day-picker binds touch events using
    // React's event system, which does not support unpassifying events.
    // A non-passive touchstart event is the only way to prevent scrolling in
    // current Mobile Safari.
    const preventTouchScrolling = event => {
      const classes = event.target.classList;
      if (
        classes.contains("DayPicker-Day") &&
        !classes.contains("DayPicker-Day--disabled")
      ) {
        event.preventDefault();
      }
    };

    document.addEventListener("touchstart", preventTouchScrolling, {
      passive: false
    });

    return () => {
      document.removeEventListener("touchstart", preventTouchScrolling);
    };
  }, []);

  useEffect(() => {
    document.addEventListener("mousemove", updateSelection);
    document.addEventListener("mouseup", stopSelecting);
    document.addEventListener("mouseleave", stopSelecting);

    document.addEventListener("touchmove", updateSelection);
    document.addEventListener("touchend", stopSelecting);
    document.addEventListener("touchcancel", stopSelecting);

    return () => {
      document.removeEventListener("mousemove", updateSelection);
      document.removeEventListener("mouseup", stopSelecting);
      document.removeEventListener("mouseleave", stopSelecting);

      document.removeEventListener("touchmove", updateSelection);
      document.removeEventListener("touchend", stopSelecting);
      document.removeEventListener("touchcancel", stopSelecting);
    };
  });

  return (
    <ReactDayPicker
      showWeekNumbers={showWeekNumbers}
      modifiers={modifiers}
      onDayMouseDown={startSelecting}
      onDayTouchStart={startSelecting}
      className={showWeekNumbers ? "DayPicker--hasWeekNumbers" : ""}
      {...props}
    />
  );
};

export const sortDates = (a, b) => (a > b ? 1 : -1);

export const rangeToDays = ({ from, to }) => {
  const results = [from];
  let current = from;

  while (current.toDateString() !== to.toDateString()) {
    const next = clone(current);
    next.setDate(current.getDate() + 1);
    results.push(next);
    current = next;
  }

  return results;
};

export const isDayReservable = (day, modifiers) => {
  const mods = getModifiersForDay(day, modifiers);
  return mods.includes("disabled") || mods.includes("reserved") ? false : true;
};

export const isDayEditable = (day, modifiers) => {
  const mods = getModifiersForDay(day, modifiers);
  return mods.includes("reserved") && !mods.includes("disabled");
};

const addRangeBasedOnInteractionModes = (
  range,
  startedFrom,
  days,
  modifiers
) => {
  const startedFromMods = getModifiersForDay(startedFrom, modifiers);
  const addInsteadOfDelete = !startedFromMods.includes("selected");
  const reserveInsteadOfEdit = !startedFromMods.includes("reserved");

  if (addInsteadOfDelete) {
    // If we're adding days, combine range + days
    const daysInRange = rangeToDays(range).filter(
      reserveInsteadOfEdit
        ? day => isDayReservable(day, modifiers)
        : day => isDayEditable(day, modifiers)
    );

    const combined = [
      ...days.filter(
        day =>
          !isDayInRange(day, range) &&
          (reserveInsteadOfEdit
            ? isDayReservable(day, modifiers)
            : isDayEditable(day, modifiers))
      ),
      ...daysInRange
    ];

    return combined.sort(sortDates);
    // .filter((day, index, list) => {
    //   // Remove duplicates
    //   const date = day.toDateString();
    //   return list.find(day => day.toDateString() === date) ? false : true;
    // })
  } else {
    // If we're removing days, remove range from days
    return [
      ...days.filter(
        day =>
          !isDayInRange(day, range) &&
          (reserveInsteadOfEdit
            ? isDayReservable(day, modifiers)
            : isDayEditable(day, modifiers))
      )
    ];
  }
};

export const reservationGroupColors = [
  // Originals:
  // "hsl(206.4, 63.1%, 64%)",
  // "hsl(29.3, 100%, 64.3%)",
  // "hsl(166, 100%, 30.2%)",
  // "hsl(45.3, 100%, 64.7%)",
  // "hsl(221, 15.3%, 58.8%)",
  // "hsl(6, 79.1%, 53.2%)",
  "hsl(236.3, 44.4%, 28.2%)",
  "hsl(206.4, 63%, 63.9%)",
  "hsl(13.8, 100%, 79.6%)",
  "hsl(29.3, 100%, 64.3%)",
  "hsl(145.5, 57.9%, 88.8%)",
  "hsl(45.3, 100%, 64.7%)"
];

export const generateGradient = (reservationNumbers, absenceNumbers) => {
  const length = reservationNumbers.length;
  const gradient = reservationNumbers
    .map((number, index) => {
      const isAbsent = absenceNumbers.includes(number);
      const color = isAbsent
        ? "#e63c29"
        : reservationGroupColors[number % reservationGroupColors.length];

      return (
        `${color} ${(index / length) * 100}%` +
        `, ${color} ${((index + 1) / length) * 100}%`
      );
    })
    .join(", ");

  return `linear-gradient(${gradient})`;
};

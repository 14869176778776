import React from "react";
import PlusIcon from "mdi-react/PlusIcon";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { isNativeApp } from "../../UserAgent/UserAgent";
import styles from "./ThreadList.module.scss";
import Button from "../../Button/Button";

const ThreadList = ({ children, buttonComponent, to }) => {
  const footerClassNames = classNames(styles.footer, {
    [styles.native]: isNativeApp(),
  });
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      {children}
      <div className={footerClassNames}>
        {to && (
          <Button round hasIcon fab wrapper={buttonComponent} to={to}>
            <PlusIcon />
            {t("Messages.ThreadList.new_message_button")}
          </Button>
        )}
      </div>
    </div>
  );
};

export default ThreadList;

import React from 'react'
import Icon from '../../../shared/components/Icons/Icons'
import styles from "./IconSelector.module.scss"
import Select, { components } from "react-select";
import CloseIcon from "mdi-react/CloseIcon";

const IconSelector = ({selectedIcon, setSelectedIcon, t, editMode, formMode}) => {

  const customStyles = {
    indicatorsContainer: provided => ({
      ...provided,
      height: "48px",
      borderRadius: '0px;'
    }),
    dropdownIndicator: provided => ({
      ...provided,
      padding: "5px",
      borderRadius: '0px;'
    }),
    control: (base, state) => ({
        ...base,
        height: '48px',
        'min-height': '48px',
        borderRadius: '0px;',
        maxWidth: '450px'
      }),
    placeholder: (base, state) => ({
      margin: '10px 0px 20px 10px'
      }),
    option: () => ({
      height: 80,
      display: 'inline-block',
      padding: 5
    })
  };

  const iconStyle={
    height: 75, width: 75
  }

  const options = [
        { value: "Angel", label: <Icon label={"ANGEL"} style={iconStyle}/> },
        { value: "Ball", label: <Icon label={"BALL"} style={iconStyle}/> },
        { value: "BackPack", label: <Icon label={"BACKPACK"} style={iconStyle}/> },
        { value: "Balloons", label: <Icon label={"BALLOONS"} style={iconStyle}/> },
        { value: "Beanie", label: <Icon label={"BEANIE"} style={iconStyle}/> },
        { value: "Boots", label: <Icon label={"BOOTS"} style={iconStyle}/> },
        { value: "Book", label: <Icon label={"BOOK"} style={iconStyle}/> },
        { value: "Party", label: <Icon label={"PARTY"} style={iconStyle}/> },
        { value: "Cake", label: <Icon label={"CAKE"} style={iconStyle}/> },
        { value: "Church", label: <Icon label={"CHURCH"} style={iconStyle}/> },
        { value: "Christmas_tree", label: <Icon label={"CHRISTMAS_TREE"} style={iconStyle}/> },
        { value: "Plate", label: <Icon label={"PLATE"} style={iconStyle}/> },
        { value: "Group", label: <Icon label={"GROUP"} style={iconStyle}/> },
        { value: "Flower", label: <Icon label={"FLOWER"} style={iconStyle}/> },
        { value: "OutDoors", label: <Icon label={"OUTDOORS"} style={iconStyle}/> },
        { value: "Skates", label: <Icon label={"SKATES"} style={iconStyle}/> },
        { value: "Leaf", label: <Icon label={"LEAF"} style={iconStyle}/> },
        { value: "Masks", label: <Icon label={"MASKS"} style={iconStyle}/> },
        { value: "Music", label: <Icon label={"MUSIC"} style={iconStyle}/> },
        { value: "PaintBrush", label: <Icon label={"PAINTBRUSH"} style={iconStyle}/> },
        { value: "Pin", label: <Icon label={"PIN"} style={iconStyle}/> },
        { value: "Coffee", label: <Icon label={"COFFEE"} style={iconStyle}/>},
        { value: "SnowFlake", label: <Icon label={"SNOWFLAKE"} style={iconStyle}/> },
        { value: "Sun", label: <Icon label={"SUN"} style={iconStyle}/> },
        { value: "Teddy_Bear", label: <Icon label={"TEDDY_BEAR"} style={iconStyle}/> },
        { value: "Skiing", label: <Icon label={"SKIING"} style={iconStyle}/> },
        { value: "Swimming", label: <Icon label={"SWIMMING"} style={iconStyle}/> },
        { value: "ToyHorse", label: <Icon label={"TOYHORSE"} style={iconStyle}/> },
        { value: "Umbrella", label: <Icon label={"UMBRELLA"} style={iconStyle}/> },
        { value: "Yoga", label: <Icon label={"YOGA"} style={iconStyle}/> }

  ];

  if(formMode){
    return(
    <div style={{flex: 1}}>
    <h3 className={styles.listTitle}>
      {t("Eventcalendar.IconTitle")}
    </h3>

    <Select
     onChange={(icon) => setSelectedIcon(icon.value.toUpperCase())}
     placeholder={t("Eventcalendar.AddIcon")}
     options={options}
     value={''}
     isSearchable={false}
     styles={customStyles}
     />

    {selectedIcon &&
      <div>
      <div className={styles.selectedIconContainer}>
        <div style={{padding: 27, position: 'absolute'}}>
          {options.map(option => {
            if(option.value.toUpperCase() === selectedIcon){
              return option.label
            }
          })}
        </div>

      </div>
      <CloseIcon className={styles.iconStyle} onClick={() => setSelectedIcon(null)}/>
      </div>
    }
    </div>)
  }

  if(!editMode){
    if(selectedIcon === null){
      return null
    } else {
      return(
        <>
        <h3 className={styles.listTitle} style={{paddingLeft: 0}}>
          {t("Eventcalendar.IconTitle")}
        </h3>
        <div>
        <div className={styles.selectedIconContainer}>
          <div style={{padding: 27, position: 'absolute'}}>
            {options.map(option => {
              if(option.value.toUpperCase() === selectedIcon){
                return option.label
              }
            })}
          </div>
        </div>
        </div>
        </>
      )
    }

  } else {
    return (
      <div style={{flex: 1}}>
      <h3 className={styles.listTitle}>
        {t("Eventcalendar.IconTitle")}
      </h3>

      <Select
       onChange={(icon) => setSelectedIcon(icon.value.toUpperCase())}
       placeholder={t("Eventcalendar.AddIcon")}
       options={options}
       value={''}
       isSearchable={false}
       styles={customStyles}
       />

      {selectedIcon &&
        <div>
        <div className={styles.selectedIconContainer}>
          <div style={{padding: 27, position: 'absolute'}}>
            {options.map(option => {
              if(option.value.toUpperCase() === selectedIcon){
                return option.label
              }
            })}
          </div>

        </div>
        <CloseIcon className={styles.iconStyle} onClick={() => setSelectedIcon(null)}/>
        </div>
      }
      </div>
    )
  }


}

export default IconSelector

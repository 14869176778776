import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./ArchivedIndicator.module.scss";
import CloseIcon from "mdi-react/CloseIcon";

const ArchivedIndicator = ({ label, setMessageArchived }) => {
  const [fadeout, setFadeout] = useState(false);
  const { t } = useTranslation();

  let text = t("Messages.archived_message");
  if (label === "archived") text = t("Messages.unarchived_message");

  let fadeoutTimer;

  useEffect(() => {
    if (!fadeout) {
      fadeoutTimer = window.setTimeout(() => {
        setFadeout(true);
      }, 3000);
    } else {
      fadeoutTimer = window.setTimeout(() => {
        setMessageArchived(null);
      }, 300);
    }

    return function cleanup() {
      window.clearTimeout(fadeoutTimer);
    };
  });

  let fadeoutClass = "";
  if (fadeout) fadeoutClass = styles.fadeout;

  return (
    <div className={styles.root}>
      <div className={fadeoutClass}>
        <div className={styles.container}>
          <div className={styles.text}>{text}</div>
          <button
            className={styles.close}
            tabIndex="0"
            onClick={() => setMessageArchived(null)}
          >
            <div className={styles.closeInner} tabIndex="-1">
              <CloseIcon />
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ArchivedIndicator;

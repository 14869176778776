import React, { useEffect } from "react";
import ReactGA from "react-ga";

if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
}

const withTracker = (WrappedComponent, options = {}) => {
  const trackPage = page => {
    ReactGA.set({
      page,
      ...options,
    });
    ReactGA.pageview(page);
  };

  const HOC = props => {

    const location = props.location.pathname

    const untrackedRoutes = [
      '/children/list'
    ]

    useEffect(() => {

      const denyTracking = untrackedRoutes.some(route => location.includes(route))

      if(!denyTracking){
        trackPage(location)
      }

    },[location]);

    return <WrappedComponent {...props} />;
  };

  return HOC;
};

export default withTracker;

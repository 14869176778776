import React from "react";
import classNames from "classnames";
import TextareaAutosize from "react-textarea-autosize";
import SendIcon from "mdi-react/SendIcon";
import AttachmentIcon from "mdi-react/AttachmentIcon";
import { useTranslation } from "react-i18next";
import { Formik, Form, ErrorMessage as ErrorHandler } from "formik";

import styles from "./ReplyInput.module.scss";

const ReplyInput = ({
  placeholder,
  onSubmit,
  apiError = false,
  apiLoading = false,
  hasAttachmentSupport,
  isUploading,
  handleInputChange
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={{
        body: ""
      }}
      onSubmit={(values, actions) => {
        onSubmit(values, actions);
      }}
    >
      {({ values, isSubmitting, errors, handleChange, handleBlur }) => {
        const isDisabled =
          Object.keys(errors).length > 0 ||
          (isSubmitting && !apiError) ||
          apiLoading ||
          values.body.length === 0;

        const containerClassNames = classNames(styles.container, {
          [styles.hasError]: apiError
        });
        return (
          <React.Fragment>
            {apiError && (
              <div className={styles.errorContainer}>
                {t("Messages.ReplyInput.message_error")}
              </div>
            )}
            <Form className={containerClassNames}>
              {hasAttachmentSupport && (
                <div className={styles.left}>
                  <input
                    type="file"
                    name="attachment"
                    id="fileSelectItem"
                    accept=".pdf"
                    className={styles.attachmentInput}
                    disabled={isUploading}
                    onChange={handleInputChange}
                  />
                  <label
                    htmlFor="fileSelectItem"
                    className={styles.attachmentSelector}
                  >
                    <div className={styles.iconButton}>
                      <AttachmentIcon className={styles.icon} />
                    </div>
                  </label>
                </div>
              )}
              <TextareaAutosize
                rows={1}
                maxRows={5}
                placeholder={placeholder}
                name="body"
                onChange={handleChange}
                onBlur={handleBlur}
                className={styles.textarea}
                value={values.body}
              />
              <div className={styles.right}>
                <button
                  type="submit"
                  className={styles.submitButton}
                  disabled={isDisabled || isUploading}
                >
                  <SendIcon className={styles.submitButtonIcon} />
                </button>
              </div>
            </Form>
          </React.Fragment>
        );
      }}
    </Formik>
  );
};

export default ReplyInput;

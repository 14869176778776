import React, {useState, useEffect} from 'react'
import styles from "./EventGroupTag.module.scss";
import { Query, Mutation, withApollo } from "react-apollo";

import {
  GET_DAYCARE_GROUPS
} from "../../../queries";

const EventGroupTag = ({eventItem, selectedDaycareCenter, daycareCenterData}) => {


  return(
    <Query query={GET_DAYCARE_GROUPS} variables={{id: selectedDaycareCenter}}>
      {({data, loading, error}) => {
        if(loading) return null
        if(error) return null
        if(data){

            let daycareGroupsLength = daycareCenterData.daycareCenter.daycareGroups.length
            let groups = data.daycareCenter.daycareGroups

            let attendingGroups = eventItem.daycareGroups

            let relatedGroups = groups.filter((group) => {
              return attendingGroups.some(attendingGroup => attendingGroup.id === group.id)
            })

            let allGroupsInvited = daycareGroupsLength === relatedGroups.length


            if(relatedGroups.length === 0 || allGroupsInvited){
              return <p  className={styles.receiverTag}>{data.daycareCenter.name}</p>
            } else {
              let grps = []
              if(relatedGroups.length > 0){
                relatedGroups.forEach(group => {
                    grps.push(group.name)
                })
              }
              return grps.map(group => <p key={group} className={styles.receiverTag}>{group}</p>)
            }



        }
      }}
    </Query>
  )
}
export default EventGroupTag

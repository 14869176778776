import React, { useState } from "react";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import { Formik, Form, Field, ErrorMessage as ErrorHandler } from "formik";

import { useTranslation } from "react-i18next";
import AccountChildIcon from "mdi-react/AccountChildIcon";

import { List, ListItem, ListForm, ListAction } from "../../ListItem/ListItem";
import ErrorMessage from "../../ErrorMessage/ErrorMessage";

const TrustedPeopleListing = ({ child, trustedPeople, refetchQueries }) => {
  const [editFormVisibleFor, setEditFormVisibleFor] = useState(null);
  const [addFormVisible, setAddFormVisible] = useState(false);
  const { t } = useTranslation();

  return (
    <List title={t("Profile.TrustedPersonListing.title")} icon={AccountChildIcon}>
      <TrustedPeople
        t={t}
        trustedPeople={trustedPeople}
        editFormVisibleFor={editFormVisibleFor}
        setEditFormVisibleFor={setEditFormVisibleFor}
        refetchQueries={refetchQueries}
      />
      {addFormVisible ? (
        <CreateTrustedPersonForm t={t} child={child} hideForm={setAddFormVisible} refetchQueries={refetchQueries} />
      ) : (
        <ListAction onClick={() => setAddFormVisible(true)}>
          {t("Profile.TrustedPersonListing.add_pick_up_person")}
        </ListAction>
      )}
    </List>
  );
};

const TrustedPeople = ({ trustedPeople, editFormVisibleFor, setEditFormVisibleFor, t, refetchQueries }) => {
  return trustedPeople
    .sort((a, b) => {
      return a.firstName.localeCompare(b.firstName);
    })
    .map(trustedPerson => {
      if (trustedPerson.id === editFormVisibleFor) {
        return (
          <EditTrustedPersonForm
            t={t}
            key={trustedPerson.id}
            trustedPerson={trustedPerson}
            hideForm={setEditFormVisibleFor}
            refetchQueries={refetchQueries}
          />
        );
      } else {
        let phoneNumber = trustedPerson.phoneNumber.replace(/^(.{6})(.{3})(.*)$/, "$1 $2 $3")
        return (
          <ListItem key={trustedPerson.id} editIcon onClick={() => setEditFormVisibleFor(trustedPerson.id)}>
            <div className="weight-500">
              {trustedPerson.firstName} {trustedPerson.lastName}
            </div>
            <div>{phoneNumber}</div>
          </ListItem>
        );
      }
    });
};

const TrustedPersonForm = ({ title, submitText, trustedPerson, hideForm, onClickSubmit, t }) => {
  const { firstName, lastName, phoneNumber } = trustedPerson || {};
  return (
    <Formik
      initialValues={{
        firstName: firstName || "",
        lastName: lastName || "",
        phoneNumber: phoneNumber || "",
      }}
      onSubmit={values => onClickSubmit(values)}
    >
      {({ values, isSubmitting, errors, setFieldValue }) => (
        <ListForm
          component={Form}
          title={title}
          cancelText={t("Profile.TrustedPersonListing.Form.cancel_button")}
          submitText={submitText}
          submitDisabled={values.phoneNumber.length < 1 || values.firstName.length < 1}
          onClickCancel={() => hideForm(false)}
        >
          <div className="child-margins-y-8">
            <Field
              type="text"
              placeholder={t("Profile.TrustedPersonListing.Form.first_name_placeholder")}
              name="firstName"
            />
            <Field
              type="text"
              placeholder={t("Profile.TrustedPersonListing.Form.last_name_placeholder")}
              name="lastName"
            />
            <Field
              type="tel"
              placeholder={t("Profile.TrustedPersonListing.Form.phonenumber_placeholder")}
              name="phoneNumber"
            />
          </div>
          <ErrorHandler name="name" component={ErrorMessage} />
          <ErrorHandler name="phoneNumber" component={ErrorMessage} />
        </ListForm>
      )}
    </Formik>
  );
};

const CreateTrustedPersonForm = ({ child, hideForm, t, refetchQueries }) => {
  return (
    <Mutation
      mutation={CREATE_TRUSTED_PERSON}
      refetchQueries={refetchQueries}
      onCompleted={() => {
        hideForm(false);
      }}
    >
      {(createTrustedPerson, { loading, error }) => {
        const addTrustedPerson = values => {
          createTrustedPerson({
            variables: {
              childId: child.id,
              firstName: values.firstName,
              lastName: values.lastName,
              phoneNumber: values.phoneNumber,
            },
          });
        };
        return (
          <TrustedPersonForm
            t={t}
            title={t("Profile.TrustedPersonListing.Form.add_pick_up_person")}
            hideForm={hideForm}
            onClickSubmit={addTrustedPerson}
            submitText={t("Profile.TrustedPersonListing.Form.add_button")}
          />
        );
      }}
    </Mutation>
  );
};

const EditTrustedPersonForm = ({ trustedPerson, hideForm, t, refetchQueries }) => {
  return (
    <Mutation
      mutation={UPDATE_TRUSTED_PERSON}
      refetchQueries={refetchQueries}
      onCompleted={() => {
        hideForm(false);
      }}
    >
      {(updateTrustedPerson, { loading, error }) => {
        const editTrustedPerson = values => {
          updateTrustedPerson({
            variables: {
              id: trustedPerson.id,
              firstName: values.firstName,
              lastName: values.lastName,
              phoneNumber: values.phoneNumber,
            },
          });
        };
        return (
          <TrustedPersonForm
            t={t}
            title={
              <React.Fragment>
                {t("Profile.TrustedPersonListing.Form.edit_pick_up_person")}{" "}
                <DeleteTrustedPerson
                  t={t}
                  trustedPerson={trustedPerson}
                  hideForm={hideForm}
                  refetchQueries={refetchQueries}
                />
              </React.Fragment>
            }
            hideForm={hideForm}
            onClickSubmit={editTrustedPerson}
            submitText={t("Profile.TrustedPersonListing.Form.save_button")}
            trustedPerson={trustedPerson}
          />
        );
      }}
    </Mutation>
  );
};

const DeleteTrustedPerson = ({ trustedPerson, hideForm, t, refetchQueries }) => {
  return (
    <Mutation
      t={t}
      mutation={DELETE_TRUSTED_PERSON}
      refetchQueries={refetchQueries}
      onCompleted={() => {
        hideForm(false);
      }}
    >
      {(deleteTrustedPerson, { loading, error }) => {
        const removeTrustedPerson = event => {
          event.preventDefault();
          deleteTrustedPerson({
            variables: {
              id: trustedPerson.id,
            },
          });
        };
        return (
          <span className="link link--red" onClick={event => removeTrustedPerson(event)}>
            {t("Profile.TrustedPersonListing.Form.remove_person")}
          </span>
        );
      }}
    </Mutation>
  );
};

export const CREATE_TRUSTED_PERSON = gql`
  mutation createTrustedPerson($childId: ID!, $firstName: String!, $lastName: String!, $phoneNumber: String!) {
    createTrustedPerson(childId: $childId, firstName: $firstName, lastName: $lastName, phoneNumber: $phoneNumber) {
      child {
        id
        trustedPeople {
          id
          firstName
          lastName
          phoneNumber
        }
      }
    }
  }
`;
export const DELETE_TRUSTED_PERSON = gql`
  mutation deleteTrustedPerson($id: ID!) {
    deleteTrustedPerson(id: $id) {
      child {
        id
        trustedPeople {
          id
          firstName
          lastName
          phoneNumber
        }
      }
    }
  }
`;
export const UPDATE_TRUSTED_PERSON = gql`
  mutation updateTrustedPerson($id: ID!, $firstName: String, $lastName: String, $phoneNumber: String) {
    updateTrustedPerson(id: $id, firstName: $firstName, lastName: $lastName, phoneNumber: $phoneNumber) {
      child {
        id
        trustedPeople {
          id
          firstName
          lastName
          phoneNumber
        }
      }
    }
  }
`;

export default TrustedPeopleListing;

import React from "react";
import styles from "./EmptyThread.module.scss";

const EmptyThread = ({ text, icon }) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {icon && <div className={styles.icon}>{icon}</div>}
        <div className={styles.text}>{text}</div>
      </div>
    </div>
  );
};

export default EmptyThread;

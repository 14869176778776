// Source: https://dev.to/goenning/how-to-retry-when-react-lazy-fails-mb5
export function retry(fn, retriesLeft = 5, interval = 1000) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(error => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // Uncomment to show a message to the user;
            // alert("This web page was updated and needs to be reloaded");

            // Change to `reject(error);` to throw an error instead of reloading
            window.location.reload(true);
            return;
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}

import React, { useEffect } from "react";
import gql from "graphql-tag";
import { withApollo } from "react-apollo";
import { useTranslation } from "react-i18next";

import MessageBox from "../MessageBox/MessageBox";
import { isCurrentUserMessageAuthor } from "../../../helpers/userHelpers";

const MessagesList = ({
  thread,
  client,
  refetch,
  refetchAnnouncements,
  guardian,
  isMassAnnouncement,
  staff,
  seen,
  seenSubcounts,
  seenCount,
  seenOutOf,
  workerRole,
  setLastMessageId,
  asd
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    if (
      thread.type !== "MESSAGE_THREAD_GROUP" &&
      !isMassAnnouncement &&
      thread.unreadCount > 0
    ) {
      client.mutate({
        mutation: MARK_AS_SEEN,
        refetchQueries: ["unreadMessageThreadsCount", "messageThreadGroups"],
        variables: {
          id: thread.id
        },
        optimisticResponse: {
          __typename: "Mutation",
          markAsSeenMessageThread: {
            id: thread.id,
            unreadCount: 0,
            __typename: "MessageThread"
          }
        }
      });
    } else if (isMassAnnouncement && thread.unreadCount > 0) {
      client.mutate({
        mutation: MARK_ANNOUNCEMENT_AS_SEEN,
        variables: {
          id: thread.id
        },
        refetchQueries: [
          "unreadMessageThreadsCount",
          "unreadAnnouncementsCount",
          refetchAnnouncements
        ]
      });
    }
  });

  if (
    !isMassAnnouncement ||
    (isMassAnnouncement &&
      workerRole &&
      (workerRole === "ADMINISTRATOR" || workerRole === "COMMUNICATION"))
  ) {
    for (let index = thread.messages.length - 1; index >= 0; index--) {
      if (
        staff &&
        thread.messages[index].author &&
        thread.messages[index].author.authorType === "WORKER"
      ) {
        thread.messages[index].checkmark = true;
        break;
      } else if (
        isCurrentUserMessageAuthor(thread.messages[index].author.id, staff)
      ) {
        thread.messages[index].checkmark = true;
        break;
      }
    }
  }

  return thread.messages.map((message, index) => {
    let positionRight = false;
    const ownMessage = isCurrentUserMessageAuthor(message.author.id, staff);
    positionRight = ownMessage;
    let author = `${message.author.firstName}`;

    if (isMassAnnouncement) {
      positionRight = true;
      author = `Touhula / ${message.author.firstName}`;
    } else {
      if (guardian && !ownMessage && thread.receivers) {
        const receivingChildren =
          thread.receivers &&
          thread.receivers.length > 0 &&
          thread.receivers.find(({ messagerType }) => messagerType === "CHILD");
        if (thread.sender.messagerType === "WORKER") {
          author = `${message.author.firstName}`;
        } else if (receivingChildren) {
          author = `${thread.sender.name} / ${message.author.firstName}`;
        } else {
          author = `${thread.receivers.length > 0 &&
            thread.receivers[0].name} / ${message.author.firstName}`;
        }
      }
      if (staff && message.author && message.author.authorType === "WORKER") {
        positionRight = true;
        const receivingChildren =
          thread.receivers &&
          thread.receivers.length > 0 &&
          thread.receivers.find(({ messagerType }) => messagerType === "CHILD");
        if (receivingChildren) {
          author = `${thread.sender.name} / ${message.author.firstName}`;
        } else {
          author = `${
            thread.receivers.length > 0
              ? thread.receivers.map(({ name }) => name).join(", ") + " /"
              : ""
          } ${message.author.firstName}`;
        }
      } else if (
        staff &&
        message.author &&
        message.author.authorType === "GUARDIAN"
      ) {
        author = `${message.author.firstName} ${message.author.lastName}`;
      }
    }

    let lastMessageId;
    if(index + 1 === thread.messages.length){
      lastMessageId = message.id
    }

    return (
      <MessageBox
        key={message.id}
        id={`msg_${message.id}`}
        text={
          message.deletedAt || (isMassAnnouncement && thread.deletedAt)
            ? t("Messages.MessageBox.deleted_message")
            : message.body
        }
        date={message.sentAt}
        file={message.document}
        deleted={isMassAnnouncement ? thread.deletedAt : message.deletedAt}
        author={author}
        positionRight={positionRight}
        removable={
          !message.deletedAt &&
          isCurrentUserMessageAuthor(message.author.id, staff)
        }
        onClickRemove={() => {
          if (isMassAnnouncement) {
            client.mutate({
              mutation: DELETE_ANNOUNCEMENT,
              variables: {
                id: thread.id
              },
              refetchQueries: [refetch]
            });
          } else {
            client.mutate({
              mutation: DELETE_MESSAGE,
              variables: {
                id: message.id
              },
              refetchQueries: [refetch]
            });
          }
        }}
        checkmark={message.checkmark ? message.checkmark : false}
        seen={seen}
        seenCount={seenCount}
        seenOutOf={seenOutOf}
        seenSubcounts={seenSubcounts}
        setLastMessageId={setLastMessageId}
        lastMessageId={lastMessageId}
      />
    );
  });
};

const MARK_AS_SEEN = gql`
  mutation markAsSeenMessageThread($id: ID!) {
    markAsSeenMessageThread(id: $id) {
      id
      unreadCount
    }
  }
`;

const MARK_ANNOUNCEMENT_AS_SEEN = gql`
  mutation markAsSeenAnnouncement($id: ID!) {
    markAsSeenAnnouncement(id: $id) {
      id
    }
  }
`;

const DELETE_MESSAGE = gql`
  mutation deleteMessage($id: ID!) {
    deleteMessage(id: $id) {
      id
    }
  }
`;
const DELETE_ANNOUNCEMENT = gql`
  mutation deleteAnnouncement($id: ID!) {
    deleteAnnouncement(id: $id) {
      id
    }
  }
`;

export default withApollo(MessagesList);

import React, { useState } from "react";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import { Formik, Form, Field } from "formik";
import { useTranslation } from "react-i18next";
import NoteOutlineIcon from "mdi-react/NoteOutlineIcon";

import { List, ListItem, ListAction, ListForm } from "../../ListItem/ListItem";

const CareNotes = ({ child, careNotes, staff, refetchQueries }) => {
  const [editFormVisible, setEditFormVisible] = useState(false);
  const { t } = useTranslation();
  return (
    <List
      title={
        staff ? t("Staff.Profile.CareNotes.notes_about_care_title") : t("Profile.CareNotes.notes_about_care_title")
      }
      icon={NoteOutlineIcon}
    >
      {editFormVisible && !staff && (
        <CareNotesEdit
          child={child}
          careNotes={careNotes}
          setEditFormVisible={setEditFormVisible}
          refetchQueries={refetchQueries}
        />
      )}
      {!editFormVisible && (
        <CareNotesItem careNotes={careNotes} setEditFormVisible={setEditFormVisible} staff={staff} />
      )}
    </List>
  );
};

const CareNotesItem = ({ careNotes, setEditFormVisible, staff }) => {
  const { t } = useTranslation();
  if (careNotes && careNotes.length > 0) {
    return (
      <ListItem editIcon={!staff} onClick={!staff ? () => setEditFormVisible(true) : undefined}>
        <div>{careNotes}</div>
      </ListItem>
    );
  } else if (!staff) {
    return (
      <ListAction onClick={() => setEditFormVisible(true)}>{t("Profile.CareNotes.add_note_about_care")}</ListAction>
    );
  } else {
    return (
      <ListItem>
        <div>{t("Profile.CareNotes.no_notes_about_care")}</div>
      </ListItem>
    );
  }
};
const CareNotesEdit = ({ child, careNotes, setEditFormVisible, refetchQueries }) => {
  const { t } = useTranslation();
  return (
    <Mutation
      mutation={UPDATE_CHILD}
      refetchQueries={refetchQueries}
      onCompleted={() => {
        setEditFormVisible(false);
      }}
    >
      {(updateChild, { loading, error }) => {
        const updateCareNotes = values => {
          updateChild({
            variables: {
              id: child.id,
              careNotes: values.careNotes,
            },
          });
        };
        return (
          <Formik onSubmit={values => updateCareNotes(values)} initialValues={{ careNotes: careNotes }}>
            {({ values, isSubmitting, errors, setFieldValue }) => (
              <ListForm
                component={Form}
                cancelText={t("Profile.CareNotes.cancel_note_about_care")}
                submitText={t("Profile.CareNotes.save_note_about_care")}
                onClickCancel={() => setEditFormVisible(false)}
              >
                <Field
                  component="textarea"
                  name="careNotes"
                  placeholder={t("Profile.CareNotes.placeholder_note_about_care")}
                />
              </ListForm>
            )}
          </Formik>
        );
      }}
    </Mutation>
  );
};

const UPDATE_CHILD = gql`
  mutation updateChild($id: ID!, $careNotes: String) {
    updateChild(id: $id, careNotes: $careNotes) {
      id
      name
      careNotes
    }
  }
`;

export default CareNotes;

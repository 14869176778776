import React from "react";
import SearchIcon from "mdi-react/SearchIcon";
import ArrowRightIcon from "mdi-react/ArrowRightIcon";
import { useTranslation } from "react-i18next";
// Styles
import styles from "./SearchInput.module.scss";

const SearchInput = props => {
  const { t } = useTranslation();

  let filteredProps = { ...props };
  // Remove props that shouldn't be included in the input field
  delete filteredProps["buttonclick"];

  return (
    <div className={styles.search}>
      <input className={styles.searchInput} placeholder={t("General.search_placeholder")} {...filteredProps} />
      <SearchIcon className={styles.searchIcon} />
      {props.value && props.buttonclick && (
        <button className={styles.enter} tabIndex="0" onClick={props.buttonclick}>
          <ArrowRightIcon tabIndex="-1" />
        </button>
      )}
    </div>
  );
};

export default SearchInput;

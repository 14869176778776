import React, { useState } from "react";
import { DateTime } from "luxon";
import gql from "graphql-tag";
import { Mutation } from "react-apollo";
import { useTranslation } from "react-i18next";

import styles from "./VasuOwnReservations.module.scss";
import { OutlineButton, Button, ListCheckbox, List, ListItem } from "../..";

const capitalizeFirstLetter = (word) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

const VasuOwnReservations = ({ slots, profile }) => {
  const { t } = useTranslation();
  const [promptOpen, setPromptOpen] = useState(null);
  const [showPastReservations, setShowPastReservations] = useState(false);

  const now = DateTime.local().toISO();
  const showPastReservationsToggle =
    slots.filter((slot) =>
      slot.endTime
        ? slot.endTime <= now
        : DateTime.fromISO(slot.startTime).plus({ hour: 2 }).toISO() <= now
    ).length > 0;

  let reservations = slots;
  if (!showPastReservations)
    reservations = slots.filter((slot) =>
      slot.endTime
        ? slot.endTime > now
        : DateTime.fromISO(slot.startTime).plus({ hour: 2 }).toISO() > now
    );

  if (!profile) {
    return (
      <div className={styles.container}>
        {showPastReservationsToggle && (
          <div className={styles.pastReservationsToggle}>
            <List noTitle noMargin>
              <ListCheckbox
                type="checkbox"
                name="pastReservationsToggle"
                value={showPastReservations}
                onChange={() => setShowPastReservations(!showPastReservations)}
                defaultChecked={showPastReservations}
              >
                {t("Vasu.past_reservations")}
              </ListCheckbox>
            </List>
          </div>
        )}

        {reservations && reservations.length > 0 ? (
          <div className={styles.reservations}>
            {reservations.map((slot) => {
              return (
                <div className={styles.reservation} key={slot.id}>
                  <Reservation
                    slot={slot}
                    promptOpen={promptOpen}
                    setPromptOpen={setPromptOpen}
                    t={t}
                  />
                </div>
              );
            })}
          </div>
        ) : (
          <div className={styles.empty}>{t("Vasu.no_reservations")}</div>
        )}
      </div>
    );
  } else {
    return (
      <>
        {reservations && reservations.length > 0 ? (
          <>
            {reservations.map((slot) => {
              return (
                <ListItem key={slot.id}>
                  <Reservation
                    slot={slot}
                    promptOpen={promptOpen}
                    setPromptOpen={setPromptOpen}
                    t={t}
                    profile
                  />
                </ListItem>
              );
            })}
          </>
        ) : (
          <ListItem>{t("Vasu.no_upcoming_reservations")}</ListItem>
        )}
      </>
    );
  }
};

const Reservation = ({ slot, promptOpen, setPromptOpen, t, profile }) => {
  let date = capitalizeFirstLetter(
    DateTime.fromISO(slot.startTime).toFormat("ccc d.L.")
  );
  let startTime = DateTime.fromISO(slot.startTime).toFormat("HH:mm");
  let endTime = slot.endTime
    ? DateTime.fromISO(slot.endTime).toFormat("HH:mm")
    : null;

  const canCancel =
    !profile && slot.startTime > DateTime.local().plus({ day: 2 }).toISO();
  return (
    <>
      <div className={styles.info}>
        <div className={styles.time}>
          {date} {startTime}
          {endTime && "–" + endTime}
        </div>
        <div className={styles.name}>
          {!profile &&
            (slot.child
              ? `${slot.child.firstName}, `
              : `${slot.forChild.name}, `)}
          {slot.daycareGroup.name}
        </div>
        <div className={styles.moreInfo}>{slot.notes}</div>
      </div>
      {canCancel && (
        <Mutation
          mutation={CANCEL_SLOT}
          refetchQueries={["reservedSlots", "openSlots"]}
        >
          {(
            cancelEarlyEducationPlanningSlotReservation,
            { loading, error }
          ) => {
            const cancelSlot = (id) => {
              cancelEarlyEducationPlanningSlotReservation({
                variables: {
                  earlyEducationPlanningSlotId: id,
                },
              }).then(() => setPromptOpen(null));
            };

            return (
              <div className={styles.cancelButton}>
                <OutlineButton
                  round
                  red
                  small
                  onClick={() =>
                    setPromptOpen(promptOpen === slot.id ? null : slot.id)
                  }
                >
                  {t("Vasu.cancel")}
                </OutlineButton>
                {promptOpen === slot.id && (
                  <div className={styles.cancelPrompt}>
                    <div>{t("Vasu.cancel_warning")}</div>
                    <div className={styles.cancelPromptButtons}>
                      <div className={styles.cancelPromptButton}>
                        <OutlineButton
                          fullWidth
                          onClick={() => setPromptOpen(null)}
                        >
                          {t("General.no")}
                        </OutlineButton>
                      </div>
                      <div className={styles.cancelPromptButton}>
                        <Button fullWidth onClick={() => cancelSlot(slot.id)}>
                          {t("General.yes")}
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            );
          }}
        </Mutation>
      )}
    </>
  );
};

const CANCEL_SLOT = gql`
  mutation cancelEarlyEducationPlanningSlotReservation(
    $earlyEducationPlanningSlotId: ID!
  ) {
    cancelEarlyEducationPlanningSlotReservation(
      earlyEducationPlanningSlotId: $earlyEducationPlanningSlotId
    ) {
      id
    }
  }
`;

export default VasuOwnReservations;

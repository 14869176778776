import { tougoCookies } from "../shared";

export function signOut(client) {
  const allCookies = tougoCookies.getAll();
  for (const key of Object.keys(allCookies)) {
    tougoCookies.remove(key);
  }

  client.resetStore();
}

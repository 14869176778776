import React from "react";
import MenuIcon from "mdi-react/MenuIcon";
import { withRouter } from "react-router-dom";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";

import styles from "./NavigationBar.module.scss";

const NavigationBar = props => {
  return (
    <ErrorBoundary>
      <div className={styles.navigationBar}>
        <button className={styles.left} onClick={props.toggleSideNav}>
          <MenuIcon className={styles.icon} />
        </button>
        <div className={styles.middle}>
          <div className={styles.title}>{props.title || "Tougo"}</div>
        </div>
        <button className={styles.right}> {props.right} </button>
      </div>
    </ErrorBoundary>
  );
};

export default withRouter(NavigationBar);

import { useCookies } from "react-cookie";
import Cookies from "universal-cookie";
import { useCallback } from "react";

const cookies = new Cookies();

const defaultOptions = {
  path: "/",
  secure: window.location.protocol === "https:" ? true : false,
  sameSite: "Lax",
};

export const tougoCookies = {
  set: (name, value, options) => {
    let newOptions = { ...defaultOptions, ...options };

    if (cookies.get("rememberMe")) {
      newOptions["maxAge"] = 365 * 24 * 60 * 60; // 365 days
    }

    cookies.set(name, value, newOptions);
  },
  get: (name, options) => cookies.get(name, options),
  getAll: options => cookies.getAll(options),
  remove: (name, options) => cookies.remove(name, options),
  addChangeListener: callback => cookies.addChangeListener(callback),
  removeChangeListener: callback => cookies.removeChangeListener(callback),
};

export function useTougoCookies() {
  const [cookies, setCookie, removeCookie] = useCookies();

  return [
    cookies,
    useCallback(
      (name, value, options) => {
        let newOptions = { ...defaultOptions, ...options };

        if (tougoCookies.get("rememberMe")) {
          newOptions["maxAge"] = 365 * 24 * 60 * 60; // 365 days
        }

        setCookie(name, value, newOptions);
      },
      [defaultOptions]
    ),
    useCallback((name, options) => removeCookie(name, options), [defaultOptions]),
  ];
}

import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import styles from "./MessageSearchBox.module.scss";

import MagnifyIcon from "mdi-react/MagnifyIcon";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import ChevronUpIcon from "mdi-react/ChevronUpIcon";
import CloseIcon from "mdi-react/CloseIcon";

const MessageSearchBox = ({
  messageType,
  searchTerm,
  setSearchTerm,
  showSearchResults,
  setShowSearchResults,
  childSearch = false,
}) => {
  const { t } = useTranslation();

  const [searchBarActive, setSearchBarActive] = useState(false);

  const searchBoxClassNames = classNames(styles.searchBox, {
    [styles.active]: searchBarActive,
    [styles.hasValue]: !searchBarActive && searchTerm,
  });

  let placeholder = t("MessageSearch.search_messages");
  if (messageType === "private")
    placeholder = t("MessageSearch.search_private");
  if (messageType === "group_announcements")
    placeholder = t("MessageSearch.search_announcements");
  if (messageType === "organization_announcements")
    placeholder = t("MessageSearch.search_mass_announcements");
  if (childSearch) placeholder = t("General.search_placeholder");

  useEffect(() => {
    const textInput = document.querySelector("#searchBox_textInput");

    const handleClick = (e) => {
      if (
        e.target &&
        e.target.classList &&
        e.target.classList.length &&
        e.target.classList[0].slice(0, 16) !== "MessageSearchBox"
      ) {
        setSearchBarActive(false);
        setSearchTerm(textInput.value);
        if (!childSearch && !textInput.value.length) {
          setShowSearchResults(false);
        }
      }
    };

    const handleKeydown = (e) => {
      if (e.key === "Escape") {
        setSearchBarActive(false);
      }
      if (e.key === "Enter") {
        setSearchTerm(textInput.value);
        setSearchBarActive(false);
        if (!childSearch && !showSearchResults && textInput.value.length)
          setShowSearchResults(true);
        if (!childSearch && showSearchResults && textInput.value === "")
          setShowSearchResults(false);
      }
    };

    if (searchBarActive) {
      window.addEventListener("keydown", handleKeydown);
      window.addEventListener("mousedown", handleClick);
    }

    return function cleanup() {
      window.removeEventListener("keydown", handleKeydown);
      window.removeEventListener("mousedown", handleClick);
    };
  });

  return (
    <div className={searchBoxClassNames}>
      <div className={styles.top}>
        <button
          className={styles.textBox}
          tabIndex="0"
          onClick={(e) => {
            e.preventDefault();
            if (!searchBarActive) setSearchBarActive(true);
          }}
        >
          <div className={styles.textBoxInner} tabIndex="-1">
            <MagnifyIcon className={styles.searchIcon} />
            {searchBarActive ? (
              <input
                id="searchBox_textInput"
                className={styles.textInput}
                type="text"
                autoFocus
                defaultValue={searchTerm ? searchTerm : ""}
              ></input>
            ) : (
              <span className={styles.staticText}>
                {searchTerm ? searchTerm : placeholder}
              </span>
            )}
          </div>
        </button>
        {searchTerm && (
          <button
            className={styles.removeSearch}
            onClick={() => {
              setSearchTerm("");
              if (!childSearch) {
                setShowSearchResults(false);
              }
            }}
            tabIndex="0"
          >
            <CloseIcon tabIndex="-1" />
          </button>
        )}
      </div>
      {!childSearch && searchTerm && (
        <div className={styles.bottom}>
          <button
            className={styles.toggleSearchDisplay}
            onClick={() => setShowSearchResults(!showSearchResults)}
            tabIndex="0"
          >
            <div className={styles.toggleSearchDisplayInner} tabIndex="-1">
              {showSearchResults ? (
                <>
                  <span>{t("MessageSearch.hide_results")}</span>
                  <ChevronUpIcon />
                </>
              ) : (
                <>
                  <span>{t("MessageSearch.show_results")}</span>
                  <ChevronDownIcon />
                </>
              )}
            </div>
          </button>
        </div>
      )}
    </div>
  );
};

export default MessageSearchBox;

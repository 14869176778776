import React from "react";
import styles from "./NoMatch.module.scss";
import { useTranslation } from "react-i18next";
import Logo from "../Logo/Logo";

const NoMatch = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <div className={styles.inner}>
        <Logo className={styles.logo} />
        <h1 className={styles.title}>{t("NoMatch.page_not_found_title")}</h1>
        <p className={styles.paragraph}>{t("NoMatch.page_not_found")}</p>
      </div>
    </div>
  );
};

export default NoMatch;

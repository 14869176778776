import React, { useEffect, useState } from "react";
import { withApollo } from "react-apollo";
import dayjs from "dayjs";
import styles from "./EditableEvent.module.scss";
import { useQuery } from "@apollo/react-hooks";
import filesize from "filesize";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";

import RSVPCheckBox from "../RSVPCheckBox/RSVPCheckBox";
import EventDatePicker from "../EventDatePicker/EventDatePicker";
import Receivers from "../Receivers/Receivers";
import { Formik, Form, Field } from "formik";
import RichTextEditor from "react-rte";
import ReactMarkdown from "react-markdown";
import IconSelector from "../IconSelector/IconSelector";
import AttachmentUpload from "../../Messages/AttachmentUpload";
import ErrorBoundary from "../../ErrorBoundary/ErrorBoundary";
import { NavLink } from "react-router-dom";

import { Button, Loader, ListCheckbox, ErrorMessage } from "../../../shared";
import IsImportant from "../../../shared/components/Events/IsImportantCheckBox/IsImportantCheckBox";
import Reminder from "../../../shared/components/Events/Reminder/Reminder";
import Participants from "../../../shared/components/Events/Participants/Participants";

import CloseIcon from "mdi-react/CloseIcon";
import AttachmentIcon from "mdi-react/AttachmentIcon";

import { GET_EVENT, DELETE_EVENT, UPDATE_EVENT, CREATE_NEW_THREAD } from "../../../queries";

const EditableEvent = withApollo(
  ({ client, id, selectedDaycareCenter, refetch, history, location, search, setId, daycareData }) => {
    const [editMode, setEditMode] = useState(false);
    const [eventName, setEventName] = useState("");
    const [eventDescription, setEventDescription] = useState("");
    const [eventLongDescription, setEventLongDescription] = useState("");
    const [editorState, setEditorState] = useState(null);
    const [isImportant, setIsImportant] = useState(false);
    const [startAndEndDates, setStartAndEndDates] = useState({
      startDate: dayjs().format("DD.MM.YYYY"),
      endTime: null,
      startTime: null,
      endDate: dayjs().format("DD.MM.YYYY"),
    });
    const [receivers, setReceivers] = useState([]);
    const [requireRSVP, setRequireRSVP] = useState(false);
    const [rsvpReplyDate, setRsvpReplyDate] = useState(null);
    const [parentsCanAttend, setParentsCanAttend] = useState(false);
    const [reminder, setReminder] = useState(false);
    const [selectedIcon, setSelectedIcon] = useState("");
    const [attachment, setAttachment] = useState(null);
    const [error, setError] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [confirmEditModalOpen, setConfirmEditModalOpenOpen] = useState(false);
    const [messageToParentsModalOpen, setMessageToParentsModalOpen] = useState(false);
    const [messageToParentsForm, setMessageToParentsForm] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const { t } = useTranslation();
    const { loading, data, refetch: refetchThis } = useQuery(GET_EVENT, { variables: { id: id } });

    const resetValues = (eventItem) => {
      let {
        title,
        summary,
        description,
        daycareGroups,
        icon,
        startDate,
        endDate,
        startTime,
        endTime,
        important,
        guardiansAllowed,
        reminderEnabled,
        responseRequired,
        responseDeadline,
        document,
      } = eventItem;
      if (editMode) {
        daycareGroups = daycareGroups.map((group) => group.id);
      }
      setEventName(title);
      setEventDescription(summary);
      setEventLongDescription(description);
      setEditorState(RichTextEditor.createValueFromString(description, "markdown"));
      setStartAndEndDates({ startDate, startTime, endDate, endTime });
      setReceivers(daycareGroups);
      setReminder(reminderEnabled);
      setParentsCanAttend(guardiansAllowed);
      setRequireRSVP(responseRequired);
      setSelectedIcon(icon);
      setRsvpReplyDate(responseDeadline);
      setIsImportant(important);
      setAttachment(document);
    };

    useEffect(() => {
      if (data) {
        resetValues(data.event);
      }
    }, [data, loading, editMode]);

    useEffect(() => {
      setEditMode(false);
    }, [id]);

    useEffect(() => {
      Modal.setAppElement("#modalArea");
    }, []);

    const deleteEvent = (sendMessage, subject, body) => {
      setSubmitting(true);
      let messageReceivers;
      const eventDaycareId = data.event.daycareCenter.id;
      const eventGroups = data.event.daycareGroups;
      const daycareGroups = daycareData.daycareGroups;

      if (eventGroups.length === 0 || daycareGroups.length === eventGroups.length) {
        messageReceivers = [{ id: selectedDaycareCenter, messagerType: "DAYCARE_CENTER" }];
      } else {
        messageReceivers = eventGroups.map((receiver) => {
          return { id: receiver.id, messagerType: "DAYCARE_GROUP" };
        });
      }

      client
        .mutate({
          variables: { id: id },
          mutation: DELETE_EVENT,
        })
        .then((data) => {
          if (sendMessage) {
            client
              .mutate({
                mutation: CREATE_NEW_THREAD,
                variables: {
                  sender: { id: eventDaycareId, messagerType: "DAYCARE_CENTER" },
                  receivers: messageReceivers,
                  body: body,
                  subject: subject,
                  replyable: false,
                  announcement: true, // new_message_type === "announcement",
                },
              })
              .then((data) => {
                setEditMode(false);

                setSubmitting(false);
                refetch();
                history.push(`/events/upcoming/${search}`, {
                  eventUpdated: true,
                  message: "Tapahtuma poistettu ja viesti lähetetty",
                });
              });
          } else {
            setEditMode(false);
            setSubmitting(false);
            refetch();
            history.push(`/events/upcoming/${search}`, { eventUpdated: true, message: "Tapahtuma poistettu" });
          }
        })
        .catch((error) => {
          setSubmitting(false);
          setError(true);
          console.error(error);
        });
    };

    const submitEditEvent = (sendMessage, subject, body) => {
      setSubmitting(true);
      const { startDate, startTime, endDate, endTime } = startAndEndDates;
      const eventDaycareId = data.event.daycareCenter.id;
      const daycareGroups = daycareData.daycareGroups;
      const eventGroups = data.event.daycareGroups;
      let daycareGroupIds;
      let messageReceivers;

      if (typeof receivers[0] === "string") {
        daycareGroupIds = receivers;
        messageReceivers = receivers.map((receiver) => {
          return { id: receiver, messagerType: "DAYCARE_GROUP" };
        });
      } else {
        if (receivers[0] && receivers[0].type === "DAYCARE_CENTER") {
          daycareGroupIds = daycareGroups.map((group) => group.id);
          messageReceivers = [{ id: selectedDaycareCenter, messagerType: "DAYCARE_CENTER" }];
        } else {
          if (eventGroups.length > receivers.length) {
            //some group got kicked out => message goes to the original groups
            daycareGroupIds = receivers.map((receiver) => receiver.value);
            messageReceivers = eventGroups.map((receiver) => {
              return { id: receiver.id, messagerType: "DAYCARE_GROUP" };
            });
          } else {
            daycareGroupIds = receivers.map((receiver) => receiver.value);
            messageReceivers = receivers.map((receiver) => {
              const { value, type } = receiver;
              return { id: value, messagerType: type };
            });
          }
        }
      }

      const variables = {
        id: id,
        daycareGroupIds: daycareGroupIds,
        title: eventName,
        summary: eventDescription,
        description: eventLongDescription,
        responseRequired: requireRSVP,
        responseDeadline: rsvpReplyDate,
        startDate: startDate,
        important: isImportant,
        icon: selectedIcon,
        endDate: endDate,
        startTime: startTime,
        endTime: endTime,
        guardiansAllowed: parentsCanAttend,
        reminderEnabled: reminder,
        document: attachment ? JSON.stringify(attachment) : null,
      };

      if (attachment && !attachment.id) {
        const attachmentInfo = {
          id: "",
          storage: "cache",
          metadata: {
            size: attachment.original.metadata.size,
            filename: attachment.original.metadata.filename,
            mime_type: "application/pdf",
          },
        };
        variables["document"] = JSON.stringify(attachmentInfo);
      } else {
        variables["document"] = attachment ? JSON.stringify(attachment) : null;
      }

      client
        .mutate({
          variables,
          mutation: UPDATE_EVENT,
        })
        .then((data) => {
          if (sendMessage) {
            client
              .mutate({
                mutation: CREATE_NEW_THREAD,
                variables: {
                  sender: { id: eventDaycareId, messagerType: "DAYCARE_CENTER" },
                  receivers: messageReceivers,
                  body: body,
                  subject: subject,
                  replyable: false,
                  announcement: true, // new_message_type === "announcement",
                },
              })
              .then((data) => {
                setConfirmEditModalOpenOpen(false);
                setEditMode(false);
                setSubmitting(false);
                refetch();
                history.push(`/events/upcoming/${id}${search}`, {
                  eventUpdated: true,
                  message: "Muutokset tallennettu ja ilmoitus lähetetty",
                });
              })
              .catch((error) => {
                console.error(error);
              });
          } else {
            setEditMode(false);
            setSubmitting(false);
            refetch();
            refetchThis();

            history.push(`/events/upcoming/${id}${search}`, { eventUpdated: true, message: "Muutokset tallennettu" });
          }
        })
        .catch((error) => {
          setSubmitting(false);
          setError(true);
          console.error(error);
        });
    };

    const sendMessageToParents = (subject, body) => {
      const eventDaycareId = data.event.daycareCenter.id;

      client
        .mutate({
          mutation: CREATE_NEW_THREAD,
          variables: {
            sender: { id: eventDaycareId, messagerType: "DAYCARE_CENTER" },
            receivers: receivers.map((receiver) => {
              const { value, type } = receiver;
              return { id: value, messagerType: type };
            }),
            body: body,
            subject: subject,
            replyable: false,
            announcement: true, // new_message_type === "announcement",
          },
        })
        .then((data) => {
          setEditMode(false);
          setSubmitting(false);
          history.push(`/events/upcoming/${id}${search}`, { eventUpdated: true, message: "Viesti lähetetty" });
        });
    };

    if (error) {
      return <ErrorMessage centered>{t("Messages.load_error")}</ErrorMessage>;
    }

    return (
      <>
        <ErrorBoundary>
          <EventTopBar
            t={t}
            client={client}
            setEditModeAndReset={() => {
              setEditMode(!editMode);
              resetValues(data.event);
            }}
            editMode={editMode}
            setEditMode={() => setEditMode(!editMode)}
            setDeleteModalOpen={() => setDeleteModalOpen(!deleteModalOpen)}
            setConfirmEditModalOpenOpen={() => setConfirmEditModalOpenOpen(!confirmEditModalOpen)}
            setMessageToParentsModalOpen={() => setMessageToParentsModalOpen(!messageToParentsModalOpen)}
            daycareData={daycareData}
            search={search}
            data={data ? data : {}}
            daycareData={daycareData ? daycareData : {}}
          />

          <div className={styles.beigeBackground}>
            <div className={styles.infoContainer}>
              {loading ? (
                <Loader centered />
              ) : (
                <Formik>
                  <Form
                    onSubmit={(values, actions, e) => {
                      e.preventDefault();
                    }}
                  >
                    <EventName
                      editMode={editMode}
                      eventName={eventName}
                      setEventName={(eventName) => setEventName(eventName)}
                    />
                    <div className={styles.groupedContainer}>
                      <DateAndTime
                        t={t}
                        editMode={editMode}
                        startAndEndDates={startAndEndDates}
                        setStartAndEndDates={(dates) => setStartAndEndDates(dates)}
                      />
                      <Receivers
                        t={t}
                        daycareData={daycareData}
                        selectedDaycareCenter={selectedDaycareCenter}
                        receivers={receivers}
                        setReceivers={(receivers) => setReceivers(receivers)}
                        editMode={editMode}
                        parentsCanAttend={parentsCanAttend}
                      />
                    </div>
                    <ShortDescription
                      t={t}
                      editMode={editMode}
                      eventDescription={eventDescription}
                      setEventDescription={(description) => setEventDescription(description)}
                    />
                    <LongDescription
                      t={t}
                      editMode={editMode}
                      editorState={editorState}
                      eventLongDescription={eventLongDescription}
                      onChange={(value) => {
                        setEventLongDescription(value.toString("markdown"));
                        setEditorState(value);
                      }}
                    />
                    <div style={{ display: "inline" }}>
                      <Attachment editMode={editMode} attachment={attachment} setAttachment={setAttachment} />
                    </div>

                    {/* data && data.event && <CopyEvent data={data} search={search} daycareData={daycareData}/> */}

                    <Important
                      t={t}
                      editMode={editMode}
                      name="isImportant"
                      isImportant={isImportant}
                      setIsImportant={() => setIsImportant(!isImportant)}
                    />
                    <ParentsCanAttend
                      t={t}
                      editMode={editMode}
                      parentsCanAttend={parentsCanAttend}
                      setParentsCanAttend={() => setParentsCanAttend(!parentsCanAttend)}
                    />
                    <RSVPCheckBox
                      t={t}
                      editMode={editMode}
                      setRequireRSVP={() => setRequireRSVP(!requireRSVP)}
                      rsvpReplyDate={rsvpReplyDate}
                      setRsvpReplyDate={(date) => setRsvpReplyDate(date)}
                      requireRSVP={requireRSVP}
                      startAndEndDates={startAndEndDates}
                      focusToDayBeforeStartDate={false}
                    />
                    <Reminder
                      t={t}
                      editMode={editMode}
                      reminder={reminder}
                      setReminder={() => setReminder(!reminder)}
                    />
                    <IconSelector
                      t={t}
                      name="icon"
                      editMode={editMode}
                      selectedIcon={selectedIcon}
                      setSelectedIcon={(icon) => {
                        setSelectedIcon(icon);
                      }}
                    />
                    {requireRSVP && (
                      <Participants
                        t={t}
                        receivers={receivers}
                        daycareData={daycareData}
                        parentsCanAttend={parentsCanAttend}
                        responses={data.event.responses}
                        data={data}
                      />
                    )}
                  </Form>
                </Formik>
              )}

              <Modal className={styles.modal} overlayClassName={styles.overlay} isOpen={deleteModalOpen}>
                <h1 style={{ marginBottom: 10, fontWeight: 500 }}>Vahvista tapahtuman poisto</h1>
                <div style={{ position: "absolute", right: 10, top: 10 }}>{submitting && <Loader />}</div>
                <div className={styles.border} style={{ marginBottom: 15, marginTop: 15 }}>
                  <ListCheckbox
                    type={"checkbox"}
                    checked={messageToParentsForm}
                    onClick={() => setMessageToParentsForm(!messageToParentsForm)}
                  >
                    {t("Eventcalendar.NotifyParentsOfCancellation")}
                  </ListCheckbox>
                </div>

                {messageToParentsForm && (
                  <Formik initialValues={{ title: `Peruutus tapahtumaan ${eventName}`, body: "" }}>
                    {({ handleChange, values }) => (
                      <Form>
                        <Field
                          required
                          className="textField"
                          onChange={handleChange}
                          type="text"
                          name="title"
                          placeholder={"Otsikko"}
                        />
                        <Field
                          required
                          component="textarea"
                          onChange={handleChange}
                          className={styles.editFormBody}
                          placeholder={t("NewThread.write_a_message")}
                          name="body"
                        />
                        <Button
                          style={{ flex: 1, marginBottom: 10, marginTop: 10 }}
                          red
                          disabled={submitting || values.body.length < 1}
                          onClick={() => deleteEvent(true, values.title, values.body)}
                          fullWidth
                        >
                          {t("Eventcalendar.SendMessageAndDelete")}
                        </Button>
                        <Button blue fullWidth onClick={() => setDeleteModalOpen(false)}>
                          {t("Eventcalendar.Cancel")}
                        </Button>
                      </Form>
                    )}
                  </Formik>
                )}

                {!messageToParentsForm && (
                  <div style={{ display: "flex" }}>
                    <Button
                      style={{ flex: 1, marginLeft: 5 }}
                      disabled={submitting}
                      fullWidth
                      red
                      onClick={() => deleteEvent(false)}
                    >
                      {t("Eventcalendar.Delete")}
                    </Button>
                    <Button style={{ flex: 1, marginLeft: 5 }} blue fullWidth onClick={() => setDeleteModalOpen(false)}>
                      {t("Eventcalendar.Cancel")}
                    </Button>
                  </div>
                )}
              </Modal>

              <Modal className={styles.editModal} overlayClassName={styles.overlay} isOpen={confirmEditModalOpen}>
                <h1 style={{ marginBottom: 10, fontWeight: 500 }}>{t("Eventcalendar.ConfirmSaveChanges")}</h1>
                <div style={{ position: "absolute", right: 10, top: 10 }}>{submitting && <Loader />}</div>
                <div className={styles.border} style={{ marginBottom: 15, marginTop: 15 }}>
                  <ListCheckbox
                    type={"checkbox"}
                    checked={messageToParentsForm}
                    onClick={() => setMessageToParentsForm(!messageToParentsForm)}
                  >
                    {"Ilmoita muutoksista kutsutuille"}
                  </ListCheckbox>
                </div>

                {messageToParentsForm && (
                  <Formik initialValues={{ title: `Muutoksia tapahtumaan ${eventName}`, body: "" }}>
                    {({ handleChange, values }) => (
                      <Form>
                        <Field
                          required
                          className="textField"
                          onChange={handleChange}
                          type="text"
                          name="title"
                          placeholder={"Otsikko"}
                        />
                        <Field
                          required
                          component="textarea"
                          onChange={handleChange}
                          className={styles.editFormBody}
                          placeholder={t("NewThread.write_a_message")}
                          name="body"
                        />
                        <Button
                          style={{ flex: 1, marginBottom: 10, marginTop: 10 }}
                          green
                          disabled={submitting || values.body.length < 1}
                          onClick={() => submitEditEvent(true, values.title, values.body)}
                          fullWidth
                        >
                          {t("Eventcalendar.SendMessageAndSave")}
                        </Button>
                        <Button blue fullWidth onClick={() => setConfirmEditModalOpenOpen(false)}>
                          {t("Eventcalendar.Cancel")}
                        </Button>
                      </Form>
                    )}
                  </Formik>
                )}
                {!messageToParentsForm && (
                  <div style={{ display: "flex" }}>
                    <Button
                      style={{ flex: 1, marginLeft: 5 }}
                      blue
                      fullWidth
                      green
                      disabled={submitting}
                      onClick={() => submitEditEvent(false)}
                    >
                      {t("Eventcalendar.Save")}
                    </Button>
                    <Button
                      style={{ flex: 1, marginLeft: 5 }}
                      blue
                      fullWidth
                      onClick={() => setConfirmEditModalOpenOpen(false)}
                    >
                      {t("Eventcalendar.Cancel")}
                    </Button>
                  </div>
                )}
              </Modal>

              <Modal className={styles.modal} overlayClassName={styles.overlay} isOpen={messageToParentsModalOpen}>
                <h1 style={{ marginBottom: 10, fontWeight: 500 }}>Lähetä viesti vieraille</h1>
                <div style={{ position: "absolute", right: 10, top: 10 }}>{submitting && <Loader />}</div>
                <Formik initialValues={{ body: "" }}>
                  {({ handleChange, values }) => (
                    <Form>
                      <Field
                        required
                        className="textField"
                        onChange={handleChange}
                        type="text"
                        name="title"
                        placeholder={"Otsikko"}
                      />
                      <Field
                        required
                        component="textarea"
                        onChange={handleChange}
                        className={styles.editFormBody}
                        placeholder={t("NewThread.write_a_message")}
                        name="body"
                      />
                      <div style={{ display: "flex", marginTop: 15 }}>
                        <Button
                          style={{ flex: 1, marginLeft: 5 }}
                          fullWidth
                          green
                          onClick={() => sendMessageToParents(values.title, values.body)}
                        >
                          {t("Eventcalendar.SendMessage")}
                        </Button>
                        <Button
                          style={{ flex: 1, marginLeft: 5 }}
                          blue
                          fullWidth
                          onClick={() => setConfirmEditModalOpenOpen(false)}
                        >
                          {t("Eventcalendar.Cancel")}
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </Modal>
            </div>
          </div>
        </ErrorBoundary>
      </>
    );
  }
);

const EventTopBar = ({
  editMode,
  setEditMode,
  setEditModeAndReset,
  setDeleteModalOpen,
  setConfirmEditModalOpenOpen,
  setMessageToParentsModalOpen,
  submitEditEvent,
  search,
  data,
  t,
  daycareData,
}) => {
  if (data && data.event && daycareData) {
    data.event.daycareData = daycareData;
  }

  return (
    <div className={styles.eventTopBar}>
      <div style={{ float: "right" }}>
        <NavLink
          to={{
            pathname: `/new-event/`,
            search: search,
            params: { eventData: data.event },
          }}
          style={{ textDecoration: "none" }}
          exact
        >
          <p className={styles.eventTopBarButton}>Kopioi tapahtuma</p>
        </NavLink>
        <p className={styles.eventTopBarButton} onClick={() => setEditMode()}>
          {t("Eventcalendar.Edit")}
        </p>
        <p className={styles.eventTopBarButton} onClick={() => setDeleteModalOpen()}>
          {t("Eventcalendar.Delete")}
        </p>
        {/* <p className={styles.eventTopBarButton} onClick={() => setMessageToParentsModalOpen()}>Viesti vieraille</p> */}
      </div>
      {editMode && (
        <div className={styles.editEventBlueBar}>
          <p className={styles.editEventBlueBarButton} onClick={() => setEditModeAndReset()}>
            <span>
              <CloseIcon style={{ position: "relative", top: 5 }} />
            </span>
            {t("Eventcalendar.Cancel")}
          </p>
          <p
            style={{ position: "relative", left: "80%" }}
            className={styles.editEventBlueBarButton}
            onClick={() => setConfirmEditModalOpenOpen()}
          >
            {t("Eventcalendar.Save")}
          </p>
        </div>
      )}
    </div>
  );
};

const EventName = ({ eventName, setEventName, editMode }) => {
  if (editMode) {
    return (
      <Field
        className="textField"
        required
        value={eventName}
        onChange={(e) => setEventName(e.target.value)}
        type="text"
        placeholder={"Nimi"}
      />
    );
  } else {
    return <h1 className={styles.eventName}>{eventName}</h1>;
  }
};
const ShortDescription = ({ editMode, eventDescription, setEventDescription, t }) => {
  return (
    <div className={styles.infoItem}>
      <p className={styles.labelText} style={{ flex: 1, minWidth: 120 }}>
        {t("Eventcalendar.Description")}
      </p>
      {editMode ? (
        <div style={{ flex: 5 }}>
          <Field
            className="textField"
            value={eventDescription}
            onChange={(e) => setEventDescription(e.target.value)}
            type="text"
            placeholder={t("Eventcalendar.Description")}
          />
        </div>
      ) : (
        <p className={styles.descriptionText} style={{ flex: 5 }}>
          {eventDescription}
        </p>
      )}
    </div>
  );
};
const LongDescription = ({ editMode, onChange, editorState, eventLongDescription, t }) => {
  const toolbarConfig = {
    display: ["INLINE_STYLE_BUTTONS", "BLOCK_TYPE_BUTTONS", "HISTORY_BUTTONS"],
    INLINE_STYLE_BUTTONS: [
      { label: "Bold", style: "BOLD" },
      { label: "Italic", style: "ITALIC" },
      { label: "Strikethrough", style: "STRIKETHROUGH" },
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: "UL", style: "unordered-list-item" },
      { label: "OL", style: "ordered-list-item" },
    ],
  };
  if (editMode) {
    return (
      <div className={styles.infoItem}>
        <p className={styles.labelText}>{t("Eventcalendar.AdditionalInfo")}</p>
        <div style={{ flex: 5 }}>
          <div className={styles.editorWrapper}>
            {editorState && (
              <RichTextEditor value={editorState} onChange={onChange} toolbarConfig={toolbarConfig} placeholder={""} />
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className={styles.infoItem}>
        <p className={styles.labelText}>{t("Eventcalendar.AdditionalInfo")}</p>
        <div style={{ flex: 5, fontSize: 16 }}>
          <ReactMarkdown escapeHtml={false} className={styles.descriptionText} children={eventLongDescription} />
        </div>
      </div>
    );
  }
};

const CopyEvent = ({ search, data, daycareData }) => {
  let eventItem = data.event;
  eventItem.daycareData = daycareData;

  return (
    <NavLink
      to={{
        pathname: `/new-event/`,
        search: search,
        params: { eventData: data.event },
      }}
      style={{ textDecoration: "none" }}
      exact
    >
      <Button small blue fullWidth style={{ marginTop: 20 }}>
        Kopioi tapahtuma
      </Button>
    </NavLink>
  );
};

const DateAndTime = ({ editMode, startAndEndDates, setStartAndEndDates, t }) => {
  return (
    <div style={{ maxHeight: 100 }} className={styles.infoItemGroupedBorder}>
      <p className={styles.labelText}>{t("Eventcalendar.Time")}</p>
      {editMode ? (
        <div style={{ flex: 5 }}>
          <EventDatePicker
            t={t}
            editMode={editMode}
            showLabel={false}
            startAndEndDates={startAndEndDates}
            setStartAndEndDates={(dates) => setStartAndEndDates(dates)}
          />
        </div>
      ) : (
        <div style={{ flex: 5 }}>
          <Time startAndEndDates={startAndEndDates} t={t} />
        </div>
      )}
    </div>
  );
};

const Time = ({ startAndEndDates, t }) => {
  let { startDate, startTime, endDate, endTime } = startAndEndDates;

  const startDayOfWeek = dayjs(startDate).format("d");
  let endDayOfWeek = "";
  let endWeekday = "";

  if (endDate && endDate !== startDate) {
    endDayOfWeek = dayjs(endDate).format("d");
    switch (endDayOfWeek) {
      case "1":
        endWeekday = t("Eventcalendar.Monday");
        break;
      case "2":
        endWeekday = t("Eventcalendar.Tuesday");
        break;
      case "3":
        endWeekday = t("Eventcalendar.Wednesday");
        break;
      case "4":
        endWeekday = t("Eventcalendar.Thursday");
        break;
      case "5":
        endWeekday = t("Eventcalendar.Friday");
        break;
      case "6":
        endWeekday = t("Eventcalendar.Saturday");
        break;
      case "0":
        endWeekday = t("Eventcalendar.Sunday");
        break;
      default:
    }
  }

  endDate = startDate !== endDate ? +dayjs(endDate).format("DD.MM") + "." : "";
  startDate = dayjs(startDate).format("DD.MM") + ".";
  startTime = startTime + "." ? startTime : "";

  let startWeekday = "";
  switch (startDayOfWeek) {
    case "1":
      startWeekday = t("Eventcalendar.Monday");
      break;
    case "2":
      startWeekday = t("Eventcalendar.Tuesday");
      break;
    case "3":
      startWeekday = t("Eventcalendar.Wednesday");
      break;
    case "4":
      startWeekday = t("Eventcalendar.Thursday");
      break;
    case "5":
      startWeekday = t("Eventcalendar.Friday");
      break;
    case "6":
      startWeekday = t("Eventcalendar.Saturday");
      break;
    case "0":
      startWeekday = t("Eventcalendar.Sunday");
    default:
  }

  if (startTime && endTime) {
    if (startDate === endDate) {
      return <p className={styles.descriptionText}>{`${startWeekday} ${startDate} ${startTime}-${endTime}`}</p>;
    } else {
      return (
        <p
          className={styles.descriptionText}
        >{`${startWeekday} ${startDate} ${startTime} - ${endWeekday} ${endDate} ${endTime}`}</p>
      );
    }
  } else {
    if (startDate === endDate) {
      return <p className={styles.descriptionText}>{`${startWeekday} ${startDate}`}</p>;
    } else {
      if (endDate === "") {
        return <p className={styles.descriptionText}>{`${startWeekday} ${startDate} `}</p>;
      }
      return <p className={styles.descriptionText}>{`${startWeekday} ${startDate} - ${endWeekday} ${endDate} `}</p>;
    }
  }
};

const Attachment = ({ attachment, setAttachment, editMode }) => {
  const [isUploading, setIsUploading] = useState(false);

  if (attachment === null && !editMode) {
    return null;
  }

  if (editMode || !attachment || editMode) {
    return (
      <div style={{ display: "inline-block", marginTop: 20 }}>
        <AttachmentUpload
          populatedAttachment={attachment}
          editMode={editMode}
          setAttachment={setAttachment}
          isUploading={isUploading}
          setIsUploading={setIsUploading}
        />
      </div>
    );
  } else {
    const { id } = attachment;
    if (id) {
      return null;
    }

    return (
      <a
        href={attachment.original.url}
        rel="noopener noreferrer"
        target="_blank"
        title={attachment.original.metadata.filename}
        className={styles.attachment}
      >
        <AttachmentIcon className={styles.attachmentIcon} />
        <span className={styles.attachmentName}>{attachment.original.metadata.filename}</span>
        <span className={styles.attachmentSize}>{filesize(attachment.original.metadata.size, { round: 1 })}</span>
      </a>
    );
  }
};
const EditModeTextField = ({ value, placeholder, setter }) => {
  return (
    <div style={{ flex: 5 }}>
      <Field
        className="textField"
        value={value}
        onChange={(e) => setter(e.target.value)}
        type="text"
        placeholder={placeholder}
      />
    </div>
  );
};
const ParentsCanAttend = ({ parentsCanAttend, setParentsCanAttend, editMode, t }) => {
  return (
    <div className={styles.border}>
      <ListCheckbox
        type={"checkbox"}
        checked={parentsCanAttend}
        disabled={!editMode}
        onClick={() => setParentsCanAttend()}
      >
        {t("Eventcalendar.GuardiansCanAttend")}
      </ListCheckbox>
    </div>
  );
};
const Important = ({ editMode, isImportant, setIsImportant }) => {
  return (
    <div className={styles.border} style={{ marginTop: 25 }}>
      <IsImportant disabled={!editMode} isImportant={isImportant} setIsImportant={() => setIsImportant()} />
    </div>
  );
};

export default EditableEvent;

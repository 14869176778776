import React, {useEffect, useState, useRef} from 'react'
import styles from "./EventCalendar.module.scss";
import MessageSuccess from "../../components/Messages/MessageSuccess";
import { Query } from "react-apollo";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
import EditableEvent from "../../components/Events/EditableEvent/EditableEvent";
import RSVPSituation from "../../components/Events/RSVPSituation/RSVPSituation";
import EventGroupTag from "../../components/Events/EventGroupTag/EventGroupTag";
import EventListItem from "../../shared/components/Events/EventListItem/EventListItem";
import useClientState from "../../hooks/useClientState";
import { CenteredContent } from "../../shared";
import { GET_DAYCARE_CENTER_EVENTS } from '../../queries'
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import CalendarStarIcon from "mdi-react/CalendarStarIcon";
import { useTranslation } from "react-i18next";
import dayjs from 'dayjs'

import {
  Avatar,
  ContainerWithSideContent,
  ThreadList,
  EmptyThread,
  Thread,
  Loader,
  ErrorMessage,
  MessagesList,
  ReplyInput,
  FullHeightContainer,
  MessageTab,
  MessageTabs
} from "../../shared";

const EventCalendar = ({ history, create, match, location, backupSearch }) => {

  const { params } = match;
  let { id } = params
  let { search } = location;
  const { time } = params
  const { t } = useTranslation();
  const { selectedDaycareCenter } = useClientState();
  const didMountRef = useRef(selectedDaycareCenter)

  const [activeTab, setActiveTab] = useState("upcoming");
  const [past, setPast] = useState(false)

  useEffect(() => {
    if(time === "upcoming"){
      setActiveTab("upcoming")
      setPast(false)
    } else {
      setActiveTab("past")
      setPast(true)
    }
  }, [time])

  if(search === ""){
    search = backupSearch
  }

  if(search !== backupSearch && backupSearch.length > 0){
    search = backupSearch
  }

  let showMessage;
  if (location && location.state && location.state.eventCreated ||
      location && location.state && location.state.eventUpdated) {
    showMessage  = true;
  }

  const containerClassNames = classNames(styles.container, {});

  return (
    <>
    {!selectedDaycareCenter ? (
      <CenteredContent>
        <div className="color-light size-medium">Valitse päiväkoti</div>
      </CenteredContent>
    ) : (
    <ErrorBoundary>
    <FullHeightContainer>
    <Query
      query={GET_DAYCARE_CENTER_EVENTS}
      pollInterval={1000 * 60 * 5}
      variables={{id: selectedDaycareCenter, past: past}}
      fetchPolicy={showMessage ? "cache-and-network" : "network-only"}
      >
      {({ data, loading, error, refetch }) => {
        if(loading){
          return(
            <ContainerWithSideContent
              noLeftSide={true}
              rightSideActiveOnMobile={true}
              left={<Loader centered large text={"Ladataan tapahtumia"}/>}
              right={<EmptyThread icon={<CalendarStarIcon/>} text={"valitse tapahtuma"} />}
            />
          )
        }
        if(error){
          return(
            <ContainerWithSideContent
              noLeftSide={true}
              rightSideActiveOnMobile={true}
              left={<ErrorMessage centered>{t("Messages.load_error")}</ErrorMessage>}
              right={<EmptyThread icon={<CalendarStarIcon/>} text={"valitse tapahtuma"} />}
            />
          )
        }
        if(data){
          let events = data.daycareCenter.events
          let daycareData = data.daycareCenter

          return(
            <ContainerWithSideContent
              noLeftSide={false}
              rightSideActiveOnMobile={id}
              left={
                <>
                {showMessage && <MessageSuccess text={location.state.message}/>}
                <MessageTabs>
                  <MessageTab
                    to={{ pathname: `/events/upcoming`, search: search }}
                    icon={null}
                    label={t('Eventcalendar.UpcomingEvents')}
                    active={activeTab === "upcoming"}
                  />
                  <MessageTab
                    to={{ pathname: `/events/past`, search: search }}
                    icon={null}
                    label={t('Eventcalendar.PastEvents')}
                    active={activeTab === "past"}
                  />
                </MessageTabs>
                {events.length < 0 ? <EmptyThread icon={<CalendarStarIcon/>} text={t("Eventcalendar.NoUpcomingEvents")} /> :
                events.map((eventItem, i) => {
                const {responseRequired, startDate, endDate} = eventItem
                const isPastEvent = dayjs(endDate).add(1, 'day').isBefore(dayjs(new Date()));

                if(isPastEvent && activeTab === "past"){
                  return(
                    <NavLink
                      to={{
                        pathname: `/events/past/${eventItem.id}`,
                        search: search
                      }}
                      style={{textDecoration: 'none'}}
                      key={eventItem.id}
                      exact
                    >
                    <EventListItem
                      key={eventItem.id}
                      id={id}
                      eventItem={eventItem}
                      activeTab={activeTab}
                      data={data}
                      t={t}
                      search={search}
                      id={id}>
                       {responseRequired &&
                        <RSVPSituation
                          t={t}
                          eventItem={eventItem}
                          data={data}/>
                       }
                        <EventGroupTag
                          daycareCenterData={data}
                          eventItem={eventItem}
                          selectedDaycareCenter={selectedDaycareCenter}
                        />
                    </EventListItem>
                    </NavLink>
                  )
                } else if(!isPastEvent && activeTab === "upcoming"){
                  return(
                    <NavLink
                      to={{
                        pathname: `/events/upcoming/${eventItem.id}`,
                        search: search
                      }}
                      style={{textDecoration: 'none'}}
                      key={eventItem.id}
                      exact
                    >
                    <EventListItem
                      key={eventItem.id}
                      id={id}
                      eventItem={eventItem}
                      activeTab={activeTab}
                      data={data}
                      t={t}
                      search={search}
                      id={id}>
                       {responseRequired &&
                        <RSVPSituation
                          t={t}
                          eventItem={eventItem}
                          data={data}/>
                       }
                        <EventGroupTag
                          daycareCenterData={data}
                          eventItem={eventItem}
                          selectedDaycareCenter={selectedDaycareCenter}
                        />
                    </EventListItem>
                  </NavLink>
                  )
                } else {
                  return null
                }

                 })}
                </>
              }
              right={
              <>
                {id ?
                <div id="modalArea">
                  <EditableEvent
                    daycareData={daycareData}
                    search={search}
                    history={history}
                    id={id}
                    loading={loading}
                    search={search}
                    selectedDaycareCenter={selectedDaycareCenter}
                    refetch={refetch}/>
                </div>
                :
                <EmptyThread icon={<CalendarStarIcon/>} text={"valitse tapahtuma"} />
                }
              </>

              }
            />
          )
        }
      }}
    </Query>
    </FullHeightContainer>
  </ErrorBoundary>
  )}
  </>
  )
}

const DashboardEvents = ({location, search}) => {
  const { selectedDaycareCenter } = useClientState();
  const { t } = useTranslation();

  return(
    <Query
      query={GET_DAYCARE_CENTER_EVENTS}
      pollInterval={1000 * 60 * 5}
      variables={{id: selectedDaycareCenter, past: false}}
      fetchPolicy={"network-only"}
      >
      {({ data, loading, error, refetch }) => {
        if(loading){
          return (
            <CenteredContent>
              <Loader small/>
            </CenteredContent>
          )
        }
        if(error){
          return(
            <>
              {error}
            </>
          )
        }
        if(data){
          const events = data.daycareCenter.events

          if(events.length === 0){
            return(
              <div style={{margin: 20}}>
                <EmptyThread icon={null} text={t("Eventcalendar.NoUpcomingEvents")} />
              </div>
            )
          }

          return events.map((eventItem, i) => {
            const {responseRequired} = eventItem
            if(i > 9){
              return null
            }
            return(
              <NavLink
                to={{
                  pathname: `/events/upcoming/${eventItem.id}`,
                  search: search
                }}
                style={{textDecoration: 'none'}}
                key={eventItem.id}
                exact
              >
              <EventListItem
                key={eventItem.id}
                id={null}
                eventItem={eventItem}
                data={data}
                t={t}
                search={search}
                >
                 {responseRequired &&
                  <RSVPSituation
                    t={t}
                    eventItem={eventItem}
                    data={data}/>
                 }
                  <EventGroupTag
                    daycareCenterData={data}
                    eventItem={eventItem}
                    selectedDaycareCenter={selectedDaycareCenter}
                  />
              </EventListItem>
              </NavLink>
            )
          })
        }
      }}
      </Query>
  )
}

export {DashboardEvents, EventCalendar}

import React from "react";
import InformationOutlineIcon from "mdi-react/InformationOutlineIcon";

import styles from "./TemporaryAnnouncement.module.scss";

const TemporaryAnnouncement = ({ title, staff, children }) => {
  return (
    <div className={staff ? styles.staffContainer : styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>
          <InformationOutlineIcon className={styles.icon} />
          {title}
        </div>
        <div className={styles.toggle}></div>
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default TemporaryAnnouncement;

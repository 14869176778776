import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import { Route, BrowserRouter } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import "./index.css";
import App from "./App";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import * as serviceWorker from "./serviceWorker";
import withTracker from "./hooks/withTracker.js";
import { userAgentPlatform } from "./shared";

ReactDOM.render(
  <ErrorBoundary>
    <CookiesProvider>
      <BrowserRouter>
        <Route component={withTracker(App, { dimension1: userAgentPlatform() })} />
      </BrowserRouter>
    </CookiesProvider>
  </ErrorBoundary>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

import React from 'react'
import {ReactComponent as Angel} from '../../assets/images/angel.svg';
import {ReactComponent as Ball} from '../../assets/images/ball.svg';
import {ReactComponent as BackPack} from '../../assets/images/backpack.svg';
import {ReactComponent as Balloons} from '../../assets/images/balloons.svg';
import {ReactComponent as Beanie} from '../../assets/images/beanie.svg';
import {ReactComponent as Book} from '../../assets/images/book.svg';
import {ReactComponent as Boots} from '../../assets/images/boots.svg';
import {ReactComponent as Church} from '../../assets/images/church.svg';
import {ReactComponent as Cake} from '../../assets/images/cake.svg';
import {ReactComponent as Cup} from '../../assets/images/cup.svg';
import {ReactComponent as Group} from '../../assets/images/group.svg';
import {ReactComponent as Sun} from '../../assets/images/sun.svg';
import {ReactComponent as Party} from '../../assets/images/party.svg';
import {ReactComponent as ChristmasTree} from '../../assets/images/christmastree.svg';
import {ReactComponent as Flower} from '../../assets/images/flower.svg';
import {ReactComponent as Eating} from '../../assets/images/eating.svg';
import {ReactComponent as OutDoors} from '../../assets/images/outdoors.svg';
import {ReactComponent as Skates} from '../../assets/images/skates.svg';
import {ReactComponent as Leaf} from '../../assets/images/leaf.svg';
import {ReactComponent as Masks} from '../../assets/images/masks.svg';
import {ReactComponent as Music} from '../../assets/images/music.svg';
import {ReactComponent as PaintBrush} from '../../assets/images/paintbrush.svg';
import {ReactComponent as SnowFlake} from '../../assets/images/snowflake.svg';
import {ReactComponent as TeddyBear} from '../../assets/images/teddybear.svg';
import {ReactComponent as Pin} from '../../assets/images/pin.svg';
import {ReactComponent as Skiing} from '../../assets/images/skiing.svg';
import {ReactComponent as Swimming} from '../../assets/images/swimming.svg';
import {ReactComponent as ToyHorse} from '../../assets/images/toyhorse.svg';
import {ReactComponent as Umbrella} from '../../assets/images/umbrella.svg';
import {ReactComponent as Yoga} from '../../assets/images/yoga.svg';

//PIN?, GROUP?

const Icon = ({label = null, iconStyle = {height: 50, width: 50}}) => {

  let icon;

  switch (label) {
    case "ANGEL":
      icon = <Angel style={iconStyle}/>
      break;
    case "BALL":
      icon = <Ball style={iconStyle}/>
      break;
    case "BACKPACK":
      icon = <BackPack style={iconStyle}/>
      break;
    case "BALLOONS":
      icon = <Balloons style={iconStyle}/>
      break;
    case "BEANIE":
      icon = <Beanie style={iconStyle}/>
      break;
    case "BOOTS":
      icon = <Boots style={iconStyle}/>
      break;
    case "BOOK":
      icon = <Book style={iconStyle}/>
      break;
    case "CAKE":
      icon = <Cake style={iconStyle}/>
      break;
    case "CHURCH":
      icon = <Church style={iconStyle}/>
      break;
    case "COFFEE":
      icon = <Cup style={iconStyle}/>
      break;
    case "GROUP":
      icon = <Group style={iconStyle}/>
      break;
    case "PARTY":
      icon = <Party style={iconStyle}/>
      break;
    case "CHRISTMAS_TREE":
      icon = <ChristmasTree style={iconStyle}/>
      break;
    case "FLOWER":
      icon = <Flower style={iconStyle}/>
      break;
    case "PLATE":
      icon = <Eating style={iconStyle}/>
      break;
    case "OUTDOORS":
      icon = <OutDoors style={iconStyle}/>
      break;
    case "SKATES":
      icon = <Skates style={iconStyle}/>
      break;
    case "LEAF":
      icon = <Leaf style={iconStyle}/>
      break;
    case "MASKS":
      icon = <Masks style={iconStyle}/>
      break;
    case "MUSIC":
      icon = <Music style={iconStyle}/>
      break;
    case "PAINTBRUSH":
      icon = <PaintBrush style={iconStyle}/>
      break;
    case "SNOWFLAKE":
      icon = <SnowFlake style={iconStyle}/>
      break;
    case "PIN":
      icon = <Pin style={iconStyle}/>
      break;
    case "SUN":
      icon = <Sun style={iconStyle}/>
      break;
    case "TEDDY_BEAR":
      icon = <TeddyBear style={iconStyle}/>
      break;
    case "SKIING":
      icon = <Skiing style={iconStyle}/>
      break;
    case "SWIMMING":
      icon = <Swimming style={iconStyle}/>
      break;
    case "TOYHORSE":
      icon = <ToyHorse style={iconStyle}/>
      break;
    case "UMBRELLA":
      icon = <Umbrella style={iconStyle}/>
      break;
    case "YOGA":
      icon = <Yoga style={iconStyle}/>
      break;
  }

  return (
    <>
      {icon}
    </>
  )
}

export default Icon

const pushQueryToHistory = query => {
  if (query instanceof URLSearchParams) {
    query = query.toString();
  }
  if (query) {
    query = '?' + query;
  }

  window.history.pushState({}, document.title, window.location.origin + window.location.pathname + query);
}

export { pushQueryToHistory };

import React from "react";
import styles from "./ListItemNotifier.module.scss";
import InformationOutlineIcon from "mdi-react/InformationOutlineIcon";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

const ListItemNotifier = ({ text, childSearch }) => {
  const { t } = useTranslation();

  const listItemNotifierClassNames = classNames(styles.listItemNotifier, {
    [styles.childSearch]: childSearch,
  });

  return (
    <div className={listItemNotifierClassNames}>
      <InformationOutlineIcon />
      <span>
        {text ? text : t("Profile.ReservationStats.preschool_notifier")}
      </span>
    </div>
  );
};

export default ListItemNotifier;

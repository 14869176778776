import React, { useState } from "react";
import classNames from "classnames";
import DeleteIcon from "mdi-react/DeleteIcon";
import CheckIcon from "mdi-react/CheckIcon";
import CheckAllIcon from "mdi-react/CheckAllIcon";
import AttachmentIcon from "mdi-react/AttachmentIcon";
import ReactMarkdown from "react-markdown";
import filesize from "filesize";
import { useTranslation } from "react-i18next";
import { formatDateToTimeToday } from "../../../helpers/dateHelpers";

import styles from "./MessageBox.module.scss";

const MessageBox = ({
  id,
  text,
  date,
  author,
  deleted,
  positionRight,
  removable,
  onClickRemove,
  file,
  checkmark,
  seen,
  seenCount,
  seenOutOf,
  seenSubcounts,
  setLastMessageId,
  lastMessageId,
}) => {
  const { t } = useTranslation();
  const [showRemoveConfirmDialog, toggleRemoveConfirmDialog] = useState(false);
  const containerClassNames = classNames(styles.container, {
    [styles.right]: positionRight,
    [styles.left]: !positionRight,
    [styles.file]: file,
  });

  const boxClassNames = classNames(styles.box, {
    [styles.confirmRemove]: showRemoveConfirmDialog,
    [styles.deleted]: deleted,
  });
  return (
    <div id={id} className={containerClassNames}>
      <div className={styles.inner}>
        <div className={styles.top}>
          <div className={styles.author}>{author}</div>
          {removable && (
            <button
              className={styles.removeButton}
              onClick={() =>
                toggleRemoveConfirmDialog(!showRemoveConfirmDialog)
              }
            >
              <DeleteIcon className={styles.removeIcon} />
            </button>
          )}
        </div>
        <div className={boxClassNames}>
          {!deleted && showRemoveConfirmDialog && (
            <ConfirmRemove
              toggleRemoveConfirmDialog={toggleRemoveConfirmDialog}
              onClickRemove={onClickRemove}
            />
          )}
          <div className={styles.text}>
            <ReactMarkdown children={text} />
          </div>
          {file && !deleted && (
            <a
              href={file.original.url}
              rel="noopener noreferrer"
              target="_blank"
              title={file.original.metadata.filename}
              className={styles.attachment}
            >
              <AttachmentIcon className={styles.attachmentIcon} />
              <span className={styles.attachmentName}>
                {file.original.metadata.filename}
              </span>
              <span className={styles.attachmentSize}>
                {filesize(file.original.metadata.size, { round: 1 })}
              </span>
            </a>
          )}
        </div>
        <div className={styles.bottom}>
          <div className={styles.date}>{formatDateToTimeToday(date)}</div>
          {positionRight && checkmark && (
            <div className={styles.readIndicator}>
              {setLastMessageId && (
                <div
                  className={styles.unreadLink}
                  onClick={() => setLastMessageId(lastMessageId)}
                >
                  {t("Messages.MessageBox.show_children_who_have_not_read")}
                </div>
              )}
              <CheckMark
                seen={seen}
                seenCount={seenCount}
                seenOutOf={seenOutOf}
                seenSubcounts={seenSubcounts}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const ConfirmRemove = ({ onClickRemove, toggleRemoveConfirmDialog }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.confirmDialog}>
      <div className={styles.confirmTitle}>
        {t("Messages.MessageBox.confirm_remove_title")}
      </div>
      <div className={styles.confirmButtons}>
        <button
          onClick={() => toggleRemoveConfirmDialog(false)}
          className={styles.confirmCancelButton}
        >
          {t("Messages.MessageBox.cancel_button")}
        </button>
        <button onClick={onClickRemove} className={styles.confirmAcceptButton}>
          {t("Messages.MessageBox.remove_button")}
        </button>
      </div>
    </div>
  );
};

const CheckMark = ({ seen, seenCount, seenOutOf, seenSubcounts }) => {
  const { t } = useTranslation();
  let labelClassName = styles.checkMarkLabel;
  if (seenSubcounts) labelClassName += ` ${styles.widerLabel}`;

  return (
    <div className={styles.checkMarkContainer}>
      {seen === "single" ? (
        <div className={`${styles.checkMark} ${styles.checkMarkSeen}`}>
          <div className={styles.checkMarkLabel}>
            {t("Messages.MessageBox.seen")}
          </div>
          <CheckAllIcon />
        </div>
      ) : seen === "multiple" ? (
        <div className={`${styles.checkMark} ${styles.checkMarkSeen}`}>
          <div className={labelClassName}>
            {seenCount} / {seenOutOf} {t("Messages.MessageBox.seen_some")}
            {seenSubcounts &&
              `, ${t("Messages.MessageBox.of_whom")} ${
                seenSubcounts.workerReadCount
              } / ${seenSubcounts.workerRecipientCount} ${t(
                "Messages.MessageBox.workers"
              )} ${seenSubcounts.guardianReadCount} / ${
                seenSubcounts.guardianRecipientCount
              } ${t("Messages.MessageBox.guardians")}`}
          </div>
          <span className={styles.checkCount}>
            {seenCount}/{seenOutOf}
          </span>
          {seenCount === seenOutOf ? <CheckAllIcon /> : <CheckIcon />}
        </div>
      ) : (
        <div className={`${styles.checkMark} ${styles.checkMarkSent}`}>
          <div className={styles.checkMarkLabel}>
            {t("Messages.MessageBox.sent")}
          </div>
          <CheckIcon />
        </div>
      )}
    </div>
  );
};

export default MessageBox;

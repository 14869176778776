import React from "react";
import styles from "./Toggle.module.scss";

const Toggle = ({ name, checked, onChange }) => (
  <label className={styles.toggle}>
    <input
      name={name}
      type="checkbox"
      className={styles.input}
      checked={checked}
      onChange={onChange}
    />
    <span className={styles.slider} />
  </label>
);

export default Toggle;

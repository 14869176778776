import React from "react";
import styles from "./OverlappingAvatars.module.scss";

const OverlappingAvatars = ({ avatarData }) => {
  let plusRecipients = null;
  let avatars = avatarData;

  if (avatarData.length > 3) {
    avatars = avatarData.slice(0, 3);
    plusRecipients = avatarData.length - 3;
  }

  return (
    <div className={styles.container}>
      {avatars.map((avatar, index) => {
        let className = styles.avatar;
        if (avatars.length === 3 && index === 1) {
          className += ` ${styles.secondAvatar}`;
        }

        return (
          <div
            key={index}
            className={className}
            style={
              avatar.url && {
                backgroundImage: `url('${avatar.url}')`,
                borderRadius: 256
              }
            }
          >
            {!avatar.url &&
              avatar.initials &&
              avatar.initials.length > 0 &&
              avatar.initials}
          </div>
        );
      })}
      {plusRecipients && (
        <span className={styles.plusRecipients}>+{plusRecipients}</span>
      )}
    </div>
  );
};

export default OverlappingAvatars;

import React from "react";
import cx from "classnames";
import styles from "./Thumbnail.module.scss";
import { useTranslation } from "react-i18next";
import PlayIcon from "mdi-react/PlayCircleIcon";
import ShareIcon from "mdi-react/ShareIcon";
import CheckIcon from "mdi-react/CheckIcon";

const Thumbnail = ({
  id,
  video,
  thumbnailUrl,
  extraLargeUrl,
  description,
  backUrl,
  onClick,
  shared,
  checked,
  selectMode,
  selectModeOnClick
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={cx(styles.Thumbnail, { [styles.checkedThumbnail]: checked })}
      style={{ backgroundImage: `url('${thumbnailUrl}')` }}
      onClick={selectMode ? () => selectModeOnClick(id) : onClick}
      to={{
        pathname: `photo/${id}`,
        state: {
          modal: true,
          url: extraLargeUrl,
          description: description,
          backUrl: backUrl
        }
      }}
    > {video && <PlayIcon className={styles.icon}/>}
      {shared && (
        <div className={styles.sharedBadge}>
          <ShareIcon className={styles.sharedIcon} />
          {t("Staff.Gallery.shared_thumbnail")}
        </div>
      )}
      {checked && (
        <div className={styles.checkedBadge}>
          <CheckIcon className={styles.checkedIcon} />
        </div>
      )}
    </div>
  );
};

export default Thumbnail;

import React from "react";
import ShieldAccountIcon from "mdi-react/ShieldAccountIcon";
import { useTranslation } from "react-i18next";

import { List, ListItem, ListCheckbox, ListFootnote } from "../../ListItem/ListItem";

const PhotoUsageRights = ({ child, staff, ProfileToggle }) => {
  const { t } = useTranslation();
  const {
    profilePictureAllowed,
    tougoPhotosAllowed,
    tougoGroupPhotosAllowed,
    marketingPhotosAllowed,
    marketingArtAllowed,
  } = child;

  let ListComponent = ListItem;

  if (staff) {
    ListComponent = ListCheckbox;
  }

  return (
    <React.Fragment>
      <List title={t("Profile.PhotoUsageRights.title")} icon={ShieldAccountIcon}>
        <ListComponent
          type={staff ? "checkbox" : undefined}
          checked={staff ? profilePictureAllowed : undefined}
          disabled={staff ? true : undefined}
          onClick={staff ? () => {} : undefined}
          right={
            !staff ? (
              <ProfileToggle staff={staff} child={child} name="profilePictureAllowed" value={profilePictureAllowed} />
            ) : (
              undefined
            )
          }
        >
          {t("Profile.PhotoUsageRights.show_to_staff")}
        </ListComponent>
        <ListComponent
          type={staff ? "checkbox" : undefined}
          checked={staff ? tougoPhotosAllowed : undefined}
          disabled={staff ? true : undefined}
          onClick={staff ? () => {} : undefined}
          right={
            !staff ? (
              <ProfileToggle staff={staff} child={child} name="tougoPhotosAllowed" value={tougoPhotosAllowed} />
            ) : (
              undefined
            )
          }
        >
          {t("Profile.PhotoUsageRights.send_home")}
        </ListComponent>
        <ListComponent
          type={staff ? "checkbox" : undefined}
          checked={staff ? tougoGroupPhotosAllowed : undefined}
          disabled={staff ? true : undefined}
          onClick={staff ? () => {} : undefined}
          right={
            !staff ? (
              <ProfileToggle
                staff={staff}
                child={child}
                name="tougoGroupPhotosAllowed"
                value={tougoGroupPhotosAllowed}
              />
            ) : (
              undefined
            )
          }
        >
          {t("Profile.PhotoUsageRights.group_pictures")}
        </ListComponent>
      </List>

      <ListFootnote title={t("Profile.PhotoUsageRights.photo_permit_information_title")}>
        {t("Profile.PhotoUsageRights.photo_permit_information")}
      </ListFootnote>

      <List title={t("Profile.PhotoUsageRights.photo_publishing_title")} icon={ShieldAccountIcon}>
        <ListComponent
          type={staff ? "checkbox" : undefined}
          checked={staff ? marketingPhotosAllowed : undefined}
          disabled={staff ? true : undefined}
          onClick={staff ? () => {} : undefined}
          right={
            !staff ? (
              <ProfileToggle staff={staff} child={child} name="marketingPhotosAllowed" value={marketingPhotosAllowed} />
            ) : (
              undefined
            )
          }
        >
          {t("Profile.PhotoUsageRights.photo_publishing")}
        </ListComponent>

        <ListComponent
          type={staff ? "checkbox" : undefined}
          checked={staff ? marketingArtAllowed : undefined}
          disabled={staff ? true : undefined}
          onClick={staff ? () => {} : undefined}
          right={
            !staff ? (
              <ProfileToggle staff={staff} child={child} name="marketingArtAllowed" value={marketingArtAllowed} />
            ) : (
              undefined
            )
          }
        >
          {t("Profile.PhotoUsageRights.marketing_art")}
        </ListComponent>
      </List>

      <ListFootnote title={t("Profile.PhotoUsageRights.photo_permit_info_title")}>
        <p>{t("Profile.PhotoUsageRights.photo_permit_info")}</p>
      </ListFootnote>
    </React.Fragment>
  );
};

export default PhotoUsageRights;

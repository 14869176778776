import React, { useState } from "react";
import { Query, Mutation } from "react-apollo";
import gql from "graphql-tag";
import { Formik, Form, Field } from "formik";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";

import ErrorMessage from "../../ErrorMessage/ErrorMessage";

import DoNotDisturbIcon from "mdi-react/DoNotDisturbIcon";
import { List, ListItem, ListAction, ListForm } from "../../ListItem/ListItem";

const AllergyListing = ({ child, allergies, staff, refetchQueries }) => {
  const { t } = useTranslation();
  const [allergyFormVisible, setAllergyFormVisible] = useState(false);
  return (
    <List title={t("Profile.AllergyListing.title")} icon={DoNotDisturbIcon}>
      <Allergies
        child={child}
        allergies={allergies}
        staff={staff}
        t={t}
        refetchQueries={refetchQueries}
      />
      {allergyFormVisible && !staff && (
        <AllergyForm
          child={child}
          selectedAllergies={allergies}
          onClickCancel={() => setAllergyFormVisible(false)}
          refetchQueries={refetchQueries}
        />
      )}
      {!allergyFormVisible && !staff && (
        <ListAction onClick={() => setAllergyFormVisible(true)}>
          {t("Profile.AllergyListing.add_allergy")}
        </ListAction>
      )}
    </List>
  );
};

const Allergies = ({ child, allergies, staff, t, refetchQueries }) => {
  if (allergies && allergies.length > 0) {
    return allergies.map((allergy) => (
      <Allergy
        key={allergy.id}
        allergy={allergy}
        selectedAllergies={allergies}
        child={child}
        staff={staff}
        refetchQueries={refetchQueries}
        t={t}
      />
    ));
  } else {
    return (
      <ListItem>
        <div>{t("Profile.AllergyListing.no_allergies")}</div>
      </ListItem>
    );
  }
};

const Allergy = ({
  allergy,
  selectedAllergies,
  child,
  staff,
  refetchQueries,
  t,
}) => (
  <Mutation mutation={DELETE_CHILD_ALLERGY} refetchQueries={refetchQueries}>
    {(deleteChildAllergy, { loading, error }) => {
      const removeAllergy = (allergy, oldAllergies) => {
        const newAllergies = oldAllergies.filter(
          (oldAllergy) => oldAllergy.id !== allergy.id
        );
        deleteChildAllergy({
          variables: {
            id: allergy.id,
          },
          optimisticResponse: {
            __typename: "Mutation",
            deleteChildAllergy: {
              child: {
                ...child,
                allergies: newAllergies,
                __typename: "Child",
              },
              __typename: "ChildAllergy",
            },
          },
        });
      };
      return (
        <ListItem
          deleteIcon={!staff}
          onClick={
            !staff
              ? () => {
                  removeAllergy(allergy, selectedAllergies);
                }
              : undefined
          }
        >
          <div className="semibold">{allergy.name}</div>
          <div>{allergy.notes}</div>
          {staff && (
            <div className={"size-small"}>
              {t("General.updated")}{" "}
              {DateTime.fromISO(allergy.updatedAt).toFormat("d.L.yyyy, HH:mm")}
            </div>
          )}
        </ListItem>
      );
    }}
  </Mutation>
);

const AllergyForm = ({
  child,
  selectedAllergies,
  onClickCancel,
  refetchQueries,
}) => {
  const { t } = useTranslation();
  return (
    <Mutation
      mutation={CREATE_CHILD_ALLERGY}
      refetchQueries={refetchQueries}
      onCompleted={() => {
        onClickCancel();
      }}
    >
      {(createChildAllergy, { loading, error }) => {
        const addAllergy = (values, oldAllergies) => {
          createChildAllergy({
            variables: {
              childId: child.id,
              allergyId: values.allergyId === "OTHER" ? null : values.allergyId,
              name: values.allergyId === "OTHER" ? values.name : null,
              notes: values.notes && values.notes.length ? values.notes : null,
            },
          });
        };

        return (
          <Formik
            onSubmit={(values) => addAllergy(values, selectedAllergies)}
            initialValues={{ allergyId: "", name: "", notes: "" }}
          >
            {({ values, isSubmitting, errors, setFieldValue }) => (
              <ListForm
                component={Form}
                title={t("Profile.AllergyListing.Form.title")}
                cancelText={t("Profile.AllergyListing.Form.cancel")}
                submitText={t("Profile.AllergyListing.Form.add_new")}
                submitDisabled={isSubmitting || values.allergyId.length === 0}
                onClickCancel={onClickCancel}
              >
                <Query query={GET_ALLERGY_LIST} fetchPolicy="cache-and-network">
                  {({ data, loading, error }) => {
                    if (loading)
                      return <Field component="select" name="allergyId" />;
                    if (error)
                      return (
                        <ErrorMessage>
                          {t("Profile.AllergyListing.Form.error")}
                        </ErrorMessage>
                      );
                    const { allergies } = data;

                    const selectedAllergyNames = selectedAllergies.map(
                      (allergy) => allergy.name
                    );

                    const allergyOptions = allergies
                      .filter(
                        (allergy) =>
                          !selectedAllergyNames.includes(allergy.name)
                      )
                      .sort((a, b) => {
                        return a.name.localeCompare(b.name);
                      });

                    return (
                      <div className="child-margins-y-8">
                        <Field component="select" name="allergyId">
                          <option disabled defaultValue value="">
                            {t("Profile.AllergyListing.Form.choose_allergy")}
                          </option>
                          {allergyOptions.map((allergy) => (
                            <option value={allergy.id} key={allergy.id}>
                              {allergy.name}
                            </option>
                          ))}
                          <option value="OTHER">Muu</option>
                        </Field>
                        {values.allergyId === "OTHER" && (
                          <Field
                            name="name"
                            type="text"
                            placeholder={t(
                              "Profile.AllergyListing.Form.placeholder"
                            )}
                          />
                        )}
                        <Field
                          name="notes"
                          component="textarea"
                          placeholder={t(
                            "Profile.AllergyListing.Form.additional_information"
                          )}
                          rows={1}
                        />
                      </div>
                    );
                  }}
                </Query>
              </ListForm>
            )}
          </Formik>
        );
      }}
    </Mutation>
  );
};

const GET_ALLERGY_LIST = gql`
  query allergies {
    allergies {
      id
      name
    }
  }
`;

const CREATE_CHILD_ALLERGY = gql`
  mutation createChildAllergy(
    $childId: ID!
    $allergyId: ID
    $name: String
    $notes: String
  ) {
    createChildAllergy(
      childId: $childId
      allergyId: $allergyId
      name: $name
      notes: $notes
    ) {
      child {
        id
        allergies {
          id
          name
          notes
        }
      }
    }
  }
`;
const DELETE_CHILD_ALLERGY = gql`
  mutation deleteChildAllergy($id: ID!) {
    deleteChildAllergy(id: $id) {
      child {
        id
        allergies {
          id
          name
          notes
        }
      }
    }
  }
`;

export default AllergyListing;

import React, { useState } from "react";

import { Query, Mutation } from "react-apollo";
import gql from "graphql-tag";
import { Formik, Form, Field } from "formik";
import { DateTime } from "luxon";

import AlertCircleIcon from "mdi-react/AlertCircleIcon";
import { useTranslation } from "react-i18next";

import Loader from "../../Loader/Loader";
import ErrorMessage from "../../ErrorMessage/ErrorMessage";

import { List, ListItem, ListAction, ListForm } from "../../ListItem/ListItem";

const SpecialCareListing = ({ child, specialCares, staff, refetchQueries }) => {
  const [specialCareFormVisible, setSpecialCareFormVisible] = useState(false);
  const { t } = useTranslation();

  return (
    <List
      title={t(
        "Profile.SpecialCareListing.illnesses_and_special_remarks_title"
      )}
      icon={AlertCircleIcon}
    >
      <SpecialCares
        child={child}
        specialCares={specialCares}
        staff={staff}
        t={t}
        refetchQueries={refetchQueries}
      />
      {specialCareFormVisible && !staff && (
        <SpecialCareForm
          child={child}
          selectedSpecialCares={specialCares}
          onClickCancel={() => setSpecialCareFormVisible(false)}
          refetchQueries={refetchQueries}
        />
      )}
      {!specialCareFormVisible && !staff && (
        <ListAction onClick={() => setSpecialCareFormVisible(true)}>
          {t("Profile.SpecialCareListing.add_illnesses_or_special_remark")}
        </ListAction>
      )}
    </List>
  );
};

const SpecialCares = ({ child, specialCares, staff, t, refetchQueries }) => {
  if (specialCares && specialCares.length > 0) {
    return specialCares.map((specialCare) => (
      <SpecialCare
        key={specialCare.id}
        specialCare={specialCare}
        selectedSpecialCares={specialCares}
        child={child}
        staff={staff}
        t={t}
      />
    ));
  } else {
    return (
      <ListItem>
        <div>
          {t("Profile.SpecialCareListing.no_illnesses_or_special_remarks")}
        </div>
      </ListItem>
    );
  }
};

const SpecialCare = ({
  specialCare,
  selectedSpecialCares,
  child,
  staff,
  refetchQueries,
  t,
}) => (
  <Mutation
    mutation={DELETE_CHILD_SPECIAL_CARE}
    refetchQueries={refetchQueries}
  >
    {(deleteChildSpecialCare, { loading, error }) => {
      const deleteChildSpecialCareSubmit = (specialCare, oldSpecialCares) => {
        const newSpecialCares = oldSpecialCares.filter(
          (oldSpecialCare) => oldSpecialCare.id !== specialCare.id
        );
        deleteChildSpecialCare({
          variables: {
            id: specialCare.id,
          },
          optimisticResponse: {
            __typename: "Mutation",
            deleteChildSpecialCare: {
              child: {
                ...child,
                specialCares: newSpecialCares,
                __typename: "Child",
              },
              __typename: "ChildSpecialCares",
            },
          },
        });
      };

      return (
        <ListItem
          deleteIcon={!staff}
          onClick={
            !staff
              ? () => {
                  deleteChildSpecialCareSubmit(
                    specialCare,
                    selectedSpecialCares
                  );
                }
              : undefined
          }
        >
          <div className="semibold">{specialCare.name}</div>
          <div>{specialCare.notes}</div>
          {staff && (
            <div className={"size-small"}>
              {t("General.updated")}{" "}
              {DateTime.fromISO(specialCare.updatedAt).toFormat(
                "d.L.yyyy, HH:mm"
              )}
            </div>
          )}
        </ListItem>
      );
    }}
  </Mutation>
);

const SpecialCareForm = ({
  child,
  selectedSpecialCares,
  onClickCancel,
  refetchQueries,
}) => {
  const { t } = useTranslation();

  return (
    <Mutation
      mutation={CREATE_CHILD_SPECIAL_CARE}
      refetchQueries={refetchQueries}
      onCompleted={() => {
        onClickCancel();
      }}
    >
      {(createChildSpecialCare, { loading, error }) => {
        const addSpecialCare = (values, oldSpecialCares) => {
          createChildSpecialCare({
            variables: {
              childId: child.id,
              specialCareId:
                values.specialCareId === "OTHER" ? null : values.specialCareId,
              name: values.specialCareId === "OTHER" ? values.name : null,
              notes:
                values.notes && values.notes.length > 0 ? values.notes : null,
            },
          });
        };

        return (
          <Formik
            onSubmit={(values) => addSpecialCare(values, selectedSpecialCares)}
            initialValues={{ specialCareId: "", name: "", notes: "" }}
          >
            {({ values, isSubmitting, errors, setFieldValue }) => (
              <ListForm
                component={Form}
                title={t("Profile.SpecialCareListing.Form.title")}
                cancelText={t("Profile.SpecialCareListing.Form.cancel")}
                submitText={t("Profile.SpecialCareListing.Form.add_new")}
                submitDisabled={
                  isSubmitting ||
                  values.specialCareId.length === 0 ||
                  (values.specialCareId === "OTHER" &&
                    values.name &&
                    values.name.length === 0)
                }
                onClickCancel={onClickCancel}
              >
                <Query
                  query={GET_SPECIAL_CARE_LIST}
                  fetchPolicy="cache-and-network"
                >
                  {({ data, loading, error }) => {
                    if (loading)
                      return <Field component="select" name="specialCareId" />;
                    if (error)
                      return (
                        <ErrorMessage>
                          {t(
                            "ErorrMessagerofile.SpecialCareListing.Form.load_error"
                          )}
                        </ErrorMessage>
                      );
                    const { specialCares } = data;

                    const selectedSpecialCareNames = selectedSpecialCares.map(
                      (specialCare) => specialCare.name
                    );

                    const specialCareOptions = specialCares
                      .filter(
                        (specialCare) =>
                          !selectedSpecialCareNames.includes(specialCare.name)
                      )
                      .sort((a, b) => {
                        return a.name.localeCompare(b.name);
                      });

                    return (
                      <div className="child-margins-y-8">
                        <Field component="select" name="specialCareId">
                          <option disabled defaultValue value="">
                            {t(
                              "Profile.SpecialCareListing.Form.choose_illness_or_add_remark"
                            )}
                          </option>
                          {specialCareOptions.map((specialCare) => (
                            <option value={specialCare.id} key={specialCare.id}>
                              {specialCare.name}
                            </option>
                          ))}
                          <option value="OTHER">
                            {t(
                              "Profile.SpecialCareListing.Form.choose_illness_or_add_remark_value_other"
                            )}
                          </option>
                        </Field>
                        {values.specialCareId === "OTHER" && (
                          <Field
                            name="name"
                            type="text"
                            placeholder={t(
                              "Profile.SpecialCareListing.Form.illness_or_remark_name"
                            )}
                          />
                        )}
                        <Field
                          name="notes"
                          component="textarea"
                          placeholder={t(
                            "Profile.SpecialCareListing.Form.additional_information"
                          )}
                          rows={1}
                        />
                      </div>
                    );
                  }}
                </Query>
              </ListForm>
            )}
          </Formik>
        );
      }}
    </Mutation>
  );
};

const GET_SPECIAL_CARE_LIST = gql`
  query specialCares {
    specialCares {
      id
      name
    }
  }
`;

const CREATE_CHILD_SPECIAL_CARE = gql`
  mutation createChildSpecialCare(
    $childId: ID!
    $specialCareId: ID
    $name: String
    $notes: String
  ) {
    createChildSpecialCare(
      childId: $childId
      specialCareId: $specialCareId
      name: $name
      notes: $notes
    ) {
      child {
        id
        specialCares {
          id
          name
          notes
        }
      }
    }
  }
`;

const DELETE_CHILD_SPECIAL_CARE = gql`
  mutation deleteChildSpecialCare($id: ID!) {
    deleteChildSpecialCare(id: $id) {
      child {
        id
        specialCares {
          id
          name
          notes
        }
      }
    }
  }
`;

export default SpecialCareListing;

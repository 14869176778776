import React, { useState } from "react";

import styles from "./InlineHelp.module.scss";
import InformationOutlineIcon from "mdi-react/InformationOutlineIcon";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import classNames from "classnames";

const InlineHelp = ({ title, children, list, red }) => {
  const [open, toggleOpen] = useState(false);
  const inlineHelpClassNames = classNames(styles.inlineHelp, {
    [styles.list]: list,
  });
  const headerClassNames = classNames(styles.inlineHelpHeader, {
    [styles.hasChildren]: children,
    [styles.red]: red,
  });
  const ChevronClassNames = classNames(styles.inlineHelpChevron, {
    [styles.show]: children,
    [styles.flip]: open,
  });
  const HeaderComponent = children > 0 ? "button" : "div";
  return (
    <div className={inlineHelpClassNames}>
      <HeaderComponent className={headerClassNames} onClick={children ? () => toggleOpen(!open) : () => {}}>
        <InformationOutlineIcon className={styles.inlineHelpIcon} />
        <div className={styles.inlineHelpTitle}>{title}</div>
        <ChevronDownIcon className={ChevronClassNames} />
      </HeaderComponent>
      {open && children && <div className={styles.inlineHelpContent}>{children}</div>}
    </div>
  );
};

export default InlineHelp;

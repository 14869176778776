import React, {useState, useEffect} from 'react'
import StarIcon from "mdi-react/StarIcon";
import {ListCheckbox, List, ListItem, Button} from "../../ListItem/ListItem";
import styles from "./IsImportantCheckBox.module.scss";

import { useTranslation } from "react-i18next";

const IsImportant = ({isImportant = false, disabled, setIsImportant}) => {
  const { t } = useTranslation();

  return (
    <div>
      <ListCheckbox
        type={"checkbox"}
        checked={isImportant}
        disabled={disabled}
        onClick={() => setIsImportant()}
        >
        {t("Eventcalendar.MarkEventImportant")}
        <StarIcon className={styles.starIcon} />
      </ListCheckbox>
    </div>
    )
}

export default IsImportant

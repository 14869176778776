import React, { useState, useEffect } from "react";
import ErrorBoundary from "../../ErrorBoundary/ErrorBoundary";
import { Field } from "formik";
import CalendarPopover from "../../Calendar/CalendarPopover/CalendarPopover";
import dayjs from "dayjs";
import CloseCircleOutlineIcon from "mdi-react/CloseCircleOutlineIcon";
import styles from "./EventDatePicker.module.scss";

const EventDatePicker = ({
  startAndEndDates,
  editMode,
  setStartAndEndDates,
  setRsvpReplyDate,
  showLabel,
  t,
  children,
}) => {
  const TIME_STEP = 60 * 5;
  const TIME_FORMAT = "HH:mm";
  const minTime = dayjs().hour("09:00").minute("00:00");
  const maxTime = dayjs().hour("22:00").minute("00:00");

  const [showClock, setShowClock] = useState(false);
  const [startDayPickerOpen, setStartDayPickerOpen] = useState(false);
  const [endDayPickerOpen, setEndDayPickerOpen] = useState(false);

  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [startDate, setStartDate] = useState(dayjs().format("DD.MM.YYYY"));
  const [endDate, setEndDate] = useState(dayjs().format("DD.MM.YYYY"));

  useEffect(() => {
    const { startTime, endTime } = startAndEndDates;
    if (startTime && endTime && !showClock) {
      setShowClock(true);
    }
  }, [startAndEndDates]);

  useEffect(() => {
    if (showClock) {
      const { startTime, endTime } = startAndEndDates;
      if (startTime && endTime) {
        setStartTime(startTime);
        setEndTime(endTime);
      } else {
        setStartTime("10:00");
        setEndTime("12:00");
      }
    } else {
      setStartTime(null);
      setEndTime(null);
    }
  }, [showClock]);

  const onBlurStartTime = () => {
    if (startTime > endTime) {
      // ends before it starts
      setEndTime(startTime);
      setStartAndEndDates({
        startDate: startAndEndDates.startDate,
        startTime: startTime,
        endDate: startAndEndDates.endDate,
        endTime: startTime,
      });
    }
  };

  const onBlurEndTime = () => {
    if (endTime < startTime) {
      // ends before it starts
      setStartAndEndDates({
        startDate: startAndEndDates.startDate,
        endTime,
        endDate: startAndEndDates.endDate,
        endTime,
      });
      setStartTime(endTime);
    } else {
      setStartAndEndDates({
        startDate: startAndEndDates.startDate,
        startTime,
        endDate: startAndEndDates.endDate,
        endTime,
      });
    }
  };

  const startDateChange = (date) => {
    setStartDate(dayjs(date).format("DD.MM.YYYY"));
    setEndDate(dayjs(date).format("DD.MM.YYYY"));
    setStartAndEndDates({
      startDate: date,
      startTime,
      endDate: date,
      endTime,
    });
    setStartDayPickerOpen(false);
  };

  const endDateChange = (date) => {
    if (dayjs(date).isBefore(dayjs(startAndEndDates.startDate))) {
      setEndDate(dayjs(date).format("DD.MM.YYYY"));
      setStartAndEndDates({
        startDate: date,
        startTime,
        endDate: date,
        endTime,
      });
    } else {
      setEndDate(dayjs(date).format("DD.MM.YYYY"));
      setStartAndEndDates({
        startDate: startAndEndDates.startDate,
        startTime,
        endDate: date,
        endTime,
      });
    }

    setEndDayPickerOpen(false);
  };

  return (
    <ErrorBoundary>
      <div className={styles.root} style={{ flex: 2 }}>
        <table className={styles.table}>
          <tr>
            <td>
              <p className={styles.timePickerText}>{`${t("Eventcalendar.Starts")}*`}</p>
            </td>
            <td style={{ cursor: "pointer" }} onClick={() => setStartDayPickerOpen(!startDayPickerOpen)}>
              <CalendarPopover
                disablePast={true}
                propsOpen={startDayPickerOpen}
                propsSetDate={(arg) => startDateChange(arg)}
              >
                <p className={styles.dateText}>{dayjs(startAndEndDates.startDate).format("DD.MM.")}</p>
              </CalendarPopover>
            </td>
            {showClock && (
              <td className={styles.tableCellBorderLeft}>
                <div className={styles.timeInput}>
                  <Field
                    type="time"
                    id="startTime"
                    value={startTime}
                    onChange={(e) => {
                      setStartTime(e.target.value);
                    }}
                    onBlur={onBlurStartTime}
                    name="startTime"
                    min={minTime && minTime.format(TIME_FORMAT)}
                    max={maxTime && maxTime.format(TIME_FORMAT)}
                    step={TIME_STEP}
                  />
                </div>
              </td>
            )}
          </tr>
          <tr>
            <td>
              <p className={styles.timePickerText}>{`${t("Eventcalendar.Ends")}*`}</p>
            </td>
            <td style={{ cursor: "pointer" }} onClick={() => setEndDayPickerOpen(!endDayPickerOpen)}>
              <CalendarPopover
                disablePast={true}
                propsOpen={endDayPickerOpen}
                propsSetDate={(arg) => endDateChange(arg)}
              >
                <p className={styles.dateText}>{dayjs(startAndEndDates.endDate).format("DD.MM.")}</p>
              </CalendarPopover>
            </td>
            {showClock && (
              <td className={styles.tableCellBorderLeft}>
                <div className={styles.timeInput}>
                  <Field
                    type="time"
                    id="endTime"
                    name="endTime"
                    value={endTime}
                    onBlur={onBlurEndTime}
                    onChange={(e) => setEndTime(e.target.value)}
                    min={minTime && minTime.format(TIME_FORMAT)}
                    max={maxTime && maxTime.format(TIME_FORMAT)}
                    step={TIME_STEP}
                  />
                </div>
              </td>
            )}
          </tr>
        </table>
        {showClock && (
          <div className={styles.removeClockTimeButton} onClick={() => setShowClock(false)}>
            <CloseCircleOutlineIcon style={{ color: "gray", backgroundColor: "white", borderRadius: 100 }} />
          </div>
        )}

        {!showClock && (
          <span onClick={() => setShowClock(true)} className={styles.addClockTimeButton}>
            {t("Eventcalendar.AddTime")}
          </span>
        )}
        {children}
      </div>
    </ErrorBoundary>
  );
};
export default EventDatePicker;

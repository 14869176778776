import React, { useState } from "react";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import { formatDateToTimeToday } from "../../../helpers/dateHelpers";
import StarIcon from "mdi-react/StarIcon";
import ArrowRightIcon from "mdi-react/ArrowRightIcon";

import styles from "./ThreadGroup.module.scss";
import ExpandAllIcon from "mdi-react/ExpandAllIcon";
import CollapseAllIcon from "mdi-react/CollapseAllIcon";

import Avatar from "../../Avatar/Avatar";
import UnreadBadge from "../UnreadBadge/UnreadBadge";

const ThreadGroup = ({
  to,
  from,
  receiver,
  title,
  date,
  count,
  unread,
  children,
  isCollapsed = false,
  pinned = false,
}) => {
  const [expanded, toggleExpanded] = useState(false);
  const containerClassNames = classNames(styles.container, styles.flexColumn, { [styles.inset]: expanded });
  return (
    <React.Fragment>
      <NavLink className={containerClassNames} activeClassName={styles.active} to={to} exact>
        <div className={styles.flexRow}>
          <div className={styles.left}>
            <Avatar group />
            {isCollapsed && <UnreadBadge unread={unread} />}
            {pinned && (
              <div className={styles.pinIcon}>
                <StarIcon size={16} />
              </div>
            )}
          </div>
          <div className={styles.right}>
            <div className={styles.top}>
              <div className={styles.topLeft}>
                <div className={styles.from}>
                  {from}
                  {receiver && (
                    <React.Fragment>
                      <ArrowRightIcon className={styles.receiverIcon} />
                      {receiver}
                    </React.Fragment>
                  )}
                </div>
                <div className={styles.title}>{title}</div>
              </div>
              <div className={styles.topRight}>
                <div className={styles.date}>{formatDateToTimeToday(date)}</div>
                <div className="flex child-margins-x-4">
                  {!isCollapsed && <UnreadBadge inline unread={unread} />}
                  {count > 0 && (
                    <button
                      className={styles.expandCollapse}
                      onClick={event => {
                        event.preventDefault();
                        toggleExpanded(!expanded);
                      }}
                    >
                      {count || null} {expanded ? <CollapseAllIcon size={16} /> : <ExpandAllIcon size={16} />}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </NavLink>
      {expanded && <div className={classNames(styles.inset, styles.flexColumn)}>{children}</div>}
    </React.Fragment>
  );
};

export default ThreadGroup;

import React from "react";
import { Query, Mutation } from "react-apollo";
import { useTranslation } from "react-i18next";

import Select from "react-select";
import { GET_GLOBAL_FILTERS, GET_DAYCARE_GROUPS, SET_SELECTED_DAYCARE_GROUP } from "../../queries";
import { Loader, pushQueryToHistory, ErrorMessage } from "../../shared";

import { sidebarSelectStyles } from "../DaycareSelector/DaycareSelector";

const GroupSelector = ({setGroupId}) => {
  const { t } = useTranslation();
  return (
    <Query query={GET_GLOBAL_FILTERS}>
      {({ data, error, loading }) => {
        if (error) return <p>{t("General.loading")}</p>;
        if (loading) return <Loader text={t("General.loading_error")} />;
        const { selectedDaycareCenter } = data;
        if (selectedDaycareCenter) {
          return (
            <Query query={GET_DAYCARE_GROUPS} variables={{ id: selectedDaycareCenter }}>
              {({ data, loading, error }) => {
                if (error)
                  return (
                    <ErrorMessage className="margin-16 margin-top">
                      {t("Staff.GroupSelector.loading_error")}
                    </ErrorMessage>
                  );
                if (loading)
                  return <Loader text={t("Staff.GroupSelector.loading")} inline className="margin-16 margin-top" />;

                const {
                  daycareCenter: { daycareGroups },
                } = data;

                return (
                  <Mutation mutation={SET_SELECTED_DAYCARE_GROUP} refetchQueries={[{ query: GET_GLOBAL_FILTERS }]}>
                    {setSelectedDaycareGroup => {
                      const options = (daycareGroups || []).map(daycareGroup => ({
                        value: daycareGroup.id,
                        label: daycareGroup.name,
                      }));
                      options.splice(0, 0, { value: "", label: t("Staff.GroupSelector.all_daycare_groups") });
                      return (
                        <Select
                          name="selectedDaycareGroup"
                          value={options.find(option => option.value === data.selectedDaycareGroup) || ""}
                          options={options}
                          styles={sidebarSelectStyles}
                          onChange={option =>
                            setSelectedDaycareGroup({ variables: { id: option.value } }).then(() => {
                              const searchQuery = new URLSearchParams(window.location.search);
                              if (!option.value || (option.value && option.value.length === 0)) {
                                searchQuery.delete("groupId");
                                pushQueryToHistory(searchQuery);
                                setGroupId("")
                              } else if (option.value !== data.selectedDaycareGroup) {
                                // Mutate the url to include the change
                                searchQuery.set("groupId", option.value);
                                pushQueryToHistory(searchQuery);
                                setGroupId("&groupId=" + option.value)
                              }
                            })
                          }
                        />
                      );
                    }}
                  </Mutation>
                );
              }}
            </Query>
          );
        } else {
          return [];
        }
      }}
    </Query>
  );
};

export default GroupSelector;

import React from "react";
import classNames from "classnames";
import styles from "./SidebarPicker.module.scss";

export const SidebarPicker = ({ children, primary, ...props }) => {
  const selectClassNames = classNames(styles.picker, {
    [styles.primary]: primary,
    [styles.secondary]: !primary
  });
  return (
    <div className={selectClassNames}>
      <select className={styles.select} {...props}>
        {children}
      </select>
    </div>
  );
};

export const SidebarPickerContainer = ({ children }) => {
  return <div className={styles.container}>{children}</div>;
};

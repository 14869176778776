import React, { useState } from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import styles from "./MessageSearch.module.scss";

import MessageSearchBox from "../MessageSearchBox/MessageSearchBox";
import MessageSearchResult from "../MessageSearchResult/MessageSearchResult";

import Loader from "../../Loader/Loader";
import ErrorMessage from "../../ErrorMessage/ErrorMessage";
import LoadMoreButton from "../LoadMoreButton/LoadMoreButton";

const MessageSearch = ({
  messageType = null,
  threadType,
  search,
  searchTerm,
  setSearchTerm,
  showSearchResults,
  setShowSearchResults,
  children,
  staff = false
}) => {
  const { t } = useTranslation();
  const messageSearchClassNames = classNames(styles.messageSearch, {
    [styles.showSearchResults]: showSearchResults && searchTerm
  });

  return (
    <div className={messageSearchClassNames}>
      <div className={styles.searchBox}>
        <MessageSearchBox
          messageType={messageType}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          showSearchResults={showSearchResults}
          setShowSearchResults={setShowSearchResults}
        />
        {children && <div className="margin-12 margin-left">{children}</div>}
      </div>
      {searchTerm && (
        <div className={styles.searchResults}>
          {(!messageType || messageType !== "organization_announcements") && (
            <>
              <ResultCategory
                t={t}
                category="MessageThread"
                searchTerm={searchTerm}
                messageType={messageType}
                search={search}
                label={t("MessageSearch.message_threads")}
                staff={staff}
              />
              <ResultCategory
                t={t}
                category="Message"
                searchTerm={searchTerm}
                messageType={messageType}
                search={search}
                label={t("MessageSearch.messages")}
                staff={staff}
              />
            </>
          )}
          {(!messageType || messageType === "organization_announcements") && (
            <ResultCategory
              t={t}
              category="Announcement"
              searchTerm={searchTerm}
              messageType={messageType}
              search={search}
              label={t("MessageSearch.mass_announcements")}
              staff={staff}
            />
          )}
        </div>
      )}
    </div>
  );
};

const ResultCategory = ({
  t,
  category,
  searchTerm,
  messageType,
  threadType,
  activeTab,
  search,
  label,
  staff
}) => {
  const [canFetchMore, setCanFetchMore] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const LIMIT = 15;

  let variables = {
    limit: LIMIT,
    offset: 0,
    entity: category,
    query: searchTerm
  };

  const location = window.location.pathname

  if (location.includes("private")) {
    variables["isPrivate"] = true;
  }
  if (location.includes("group_discussion")) {
    variables["isReplyable"] = true;
    variables["isPrivate"] = false;
  }
  if (location.includes("group_announcements")) {
    variables["isReplyable"] = false;
    variables["isPrivate"] = false;
  }

  return (
    <Query query={MESSAGE_SEARCH} variables={variables}>
      {({ data, loading, error, fetchMore }) => {
        if (loading) {
          return (
            <Loader
              className="padding-48 padding-y"
              centered
              large
              text={t("Messages.loader")}
            />
          );
        }
        if (error) {
          return (
            <ErrorMessage
              className="padding-48 padding-y"
              large
              centered
              error={error}
            >
              {t("Messages.load_error")}
            </ErrorMessage>
          );
        }

        const { messagingSearchGroups } = data;

        const handleLoadMore = () => {
          setLoadingMore(true);
          fetchMore({
            variables: {
              LIMIT,
              offset: messagingSearchGroups.length
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              setLoadingMore(false);
              if (
                fetchMoreResult &&
                fetchMoreResult.messagingSearchGroups &&
                fetchMoreResult.messagingSearchGroups.length === 0
              ) {
                setCanFetchMore(false);
                return prev;
              }
              return Object.assign({}, prev, {
                messagingSearchGroups: [
                  ...prev.messagingSearchGroups,
                  ...fetchMoreResult.messagingSearchGroups
                ]
              });
            }
          });
        };

        return (
          <div className={styles.resultCategory}>
            <div className={styles.categoryTitle}>
              <span>{label}</span>
            </div>
            <div className={styles.categoryResults}>
              {messagingSearchGroups.length > 0 ? (
                <>
                  {messagingSearchGroups.map((result, index) => (
                    <MessageSearchResult
                      key={index}
                      category={category}
                      result={result}
                      search={search}
                      threadType={threadType}
                      messageType={messageType}
                      searchTerm={searchTerm}
                      staff={staff}
                    />
                  ))}
                  {messagingSearchGroups.length >= LIMIT && canFetchMore && (
                    <LoadMoreButton
                      search
                      onClick={() => handleLoadMore()}
                      text={t("MessageSearch.show_more")}
                      loading={loadingMore}
                      loadingText={t("Messages.loading_more_messages")}
                    ></LoadMoreButton>
                  )}
                </>
              ) : (
                <div className={styles.noResults}>
                  {t("MessageSearch.no_results")}
                </div>
              )}
            </div>
          </div>
        );
      }}
    </Query>
  );
};

const MESSAGE_SEARCH = gql`
  query messagingSearchGroups(
    $limit: Int
    $offset: Int
    $entity: String!
    $query: String!
    $isPrivate: Boolean
    $isReplyable: Boolean
  ) {
    messagingSearchGroups(
      limit: $limit
      offset: $offset
      entity: $entity
      query: $query
      isPrivate: $isPrivate
      isReplyable: $isReplyable
    ) {
      groupId
      groupRowsCount
      groupThreadsWithGuardianRepliesCount
      groupFirstRow {
        id
        author {
          id
          authorType
          firstName
          lastName
        }
        highlight
        rowSentAt
        rowBody
        rowSubject
        rowChildren
        rowType
        rowMessageThreadId
        rowMessageThreadGroupId
        rowMessageGroupId
        rowIsReplyable
        rowMessageThreadGroup {
          id
          receivers {
            id
            name
          }
        }
        rowMessageThread {
          id
          children {
            id
            name
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export default MessageSearch;

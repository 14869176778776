import React from "react";
import styles from "./SidebarButton.module.scss";
import { withRouter, matchPath } from "react-router-dom";
import Link from "../Link/Link";
import classNames from "classnames";
import ChevronRightIcon from "mdi-react/ChevronRightIcon";

export const SidebarButton = ({ to, icon, label, badge, href, ...props }) => {
  if (href) {
    return (
      <a className={styles.sidebarButton} href={href} {...props}>
        <div className={styles.icon}>{icon}</div>
        <div className={styles.label}>{label}</div>
      </a>
    );
  }
  return (
    <Link
      to={to}
      className={styles.sidebarButton}
      activeClassName={styles.active}
      {...props}
    >
      <div className={styles.icon}>{icon}</div>
      <div className={styles.label}>{label}</div>
      {badge && <div className={styles.badge}>{badge > 99 ? "99" : badge}</div>}
    </Link>
  );
};

const DropdownButton = ({
  matchTo,
  location,
  to,
  icon,
  label,
  children,
  ...props
}) => {
  const isAdminPath = matchPath(location.pathname, {
    path: matchTo,
    exact: false,
    strict: false
  });

  const dropdownButtonClassNames = classNames(styles.dropdownButton, {
    [styles.dropdownButtonOpen]: isAdminPath
  });

  return (
    <div className={styles.dropdownContainer}>
      <Link
        isActive={() => isAdminPath}
        to={to}
        className={dropdownButtonClassNames}
        activeClassName={styles.active}
        {...props}
      >
        <div className={styles.icon}>{icon}</div>
        <div className={styles.label}>{label}</div>
        <ChevronRightIcon className={styles.dropdownChevron} />
      </Link>
      {isAdminPath && <div className={styles.dropdownLinks}>{children}</div>}
    </div>
  );
};

export const SidebarDropdownButton = withRouter(DropdownButton);

export const SidebarSubButton = ({ to, label, ...props }) => {
  return (
    <Link
      to={to}
      className={styles.sidebarSubButton}
      activeClassName={styles.sidebarSubButtonActive}
      {...props}
    >
      <div className={styles.label}>{label}</div>
    </Link>
  );
};

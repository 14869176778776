import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { useTranslation } from "react-i18next";

import styles from "./MessageReceivers.module.scss";

const DESKTOP_BREAKPOINT = parseInt(styles.desktopBreakpoint.replace(/\D/g, ""), 10);

const colourStyles = {
  control: styles => {
    return {
      ...styles,
      minHeight: "64px",
      borderRadius: "0",
      boxShadow: "none",
      border: "none",
    };
  },
  indicatorSeparator: styles => ({
    ...styles,
    background: "transparent",
  }),
  dropdownIndicator: styles => ({
    ...styles,
    paddingRight: "16px",
    [`@media (min-width: ${DESKTOP_BREAKPOINT}px)`]: {
      paddingRight: "24px",
    },
  }),
  valueContainer: styles => ({
    ...styles,
    padding: "0 14px",
    [`@media (min-width: ${DESKTOP_BREAKPOINT}px)`]: {
      padding: "0 22px",
    },
  }),
  placeholder: styles => ({
    ...styles,
    opacity: "1",
    color: "#5f6a82",
  }),
  menu: styles => ({
    ...styles,
    zIndex: "11",
  }),
  option: styles => ({
    ...styles,
    padding: "10px 14px",
    [`@media (min-width: ${DESKTOP_BREAKPOINT}px)`]: {
      padding: "10px 22px",
    },
  }),
  group: styles => ({
    ...styles,
    margin: "0",
    padding: "0",
  }),
  groupHeading: styles => ({
    ...styles,
    marginTop: "3px",
    padding: "10px 14px",
    [`@media (min-width: ${DESKTOP_BREAKPOINT}px)`]: {
      padding: "10px 22px",
    },
    background: "#fafbfd",
    borderTop: "1px solid #ebeef5",
    borderBottom: "1px solid #ebeef5",
  }),
};

const MessageReceivers = ({ values, sender, setFieldValue, resultFilters, populatedReceivers, daycareData }) => {
  const { t } = useTranslation();



  return (
    <Query query={GET_MESSAGE_RECEIVERS} variables={{ sender: sender }}>
      {({ data, loading, error }) => {
        if (loading) return [];
        if (error) return [];

        const { messageReceivers } = data;

        messageReceivers.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));

        const centerLabel = t("Receivers.daycare_centers");
        const groupLabel = t("Receivers.daycare_groups");
        const childLabel = t("Receivers.children");
        const workerLabel = t("Receivers.workers");

        let groupedOptions = [
          { type: "DAYCARE_CENTER", label: centerLabel, options: [] },
          { type: "DAYCARE_GROUP", label: groupLabel, options: [] },
          { type: "CHILD", label: childLabel, options: [] },
          { type: "WORKER", label: workerLabel, options: [] },
        ];

        if(resultFilters){
          let filteredGroupedOptions = groupedOptions.filter((option) =>
            resultFilters.some((filter) => filter === option.type)
          )
          groupedOptions = filteredGroupedOptions
        }

        const receivers = messageReceivers.map(receiver => {
          const { id, name, messagerType } = receiver;
          return { value: id, label: name, type: messagerType };
        });

        for (let i = 0; i < receivers.length; i++) {
          for (let n = 0; n < groupedOptions.length; n++) {
            if (groupedOptions[n].type === receivers[i].type) {
              groupedOptions[n].options.push(receivers[i]);
            }
          }
        }

        //deafult values thing is used only in event calendar
        let defaultValues = []

        if(populatedReceivers){
          const groups = daycareData.daycareGroups

          //receiver array is empty or all groups are invited = daycare is invited
          if(populatedReceivers.length === 0 || groups.length === populatedReceivers.length){
            groupedOptions.forEach(option => {
              if(option.type === "DAYCARE_CENTER"){
                defaultValues.push(option.options[0])
              }
            })
          } else {
            let optionGroups = groupedOptions.filter((option) => {
              return option.type === "DAYCARE_GROUP"
            })

            defaultValues = optionGroups[0].options.filter((option) => {
              return populatedReceivers.some(value => value === option.value)
            })

          }
        }

        return <StatefulSelect defaultValues={defaultValues} groupedOptions={groupedOptions} values={values} t={t} setFieldValue={setFieldValue} />;
      }}
    </Query>
  );
};

const StatefulSelect = ({defaultValues, groupedOptions, values, t, setFieldValue, populatedReceivers }) => {

    const [firstSelectedType, setFilter] = useState();
    const [value, setValue] = useState(false)

    if(defaultValues && defaultValues.length > 0 && !value){
      setValue(defaultValues)
      return(
        null
      )
    } else {
      return (
        <Select
          value={value}
          options={groupedOptions.filter(
            receiver => receiver.type === (firstSelectedType ? firstSelectedType : receiver.type)
          )}
          isMulti
          styles={colourStyles}
          placeholder={t("NewThread.select_recipients")}
          onChange={selected => {
            // TODO: the next line is old code that incorrectly sets the state, did not dare to remove
            // it since something depends on it.
            values.receivers = selected;

            // This is the correct way to set the state:
            if (setFieldValue) {
              setFieldValue("receivers", selected);
            }
            setValue(selected)
            setFilter(selected && selected[0] ? selected[0].type : null);
          }}
        />
      );
    }




};

const GET_MESSAGE_RECEIVERS = gql`
  query messageReceivers($sender: MessagerAttributes!) {
    messageReceivers(sender: $sender) {
      id
      messagerType
      name
    }
  }
`;

export default MessageReceivers;

import React from "react";

import styles from "./UnreadBadge.module.scss";

const UnreadBadge = ({ unread, inline = false }) => {
  return unread && unread > 0 ? (
    <div className={`${styles.unreadBadge} ${inline ? "" : styles.absolute}`}>
      {unread > 9 ? <React.Fragment>9+</React.Fragment> : unread}
    </div>
  ) : null;
};

export default UnreadBadge;

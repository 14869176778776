import React, { useState, useEffect } from "react";

import Overlay from "../Overlay/Overlay";

import styles from "./MainLayout.module.scss";

const SIDEBAR_WIDTH = parseInt(styles.sideBarWidth.replace(/\D/g, ""), 10);

const MainLayout = ({ aside, top, bottom, children }) => {
  const [sideNavOpen, toggleSideNav] = useState(false);

  const [sideNavWidthIsInPercentages, setSideNavWidthToPercentages] = useState(
    false
  );

  const handleResize = windowWidth =>
    setSideNavWidthToPercentages(windowWidth * 0.75 < SIDEBAR_WIDTH);
  useWindowWidth(handleResize);

  const mainOpenClass = sideNavWidthIsInPercentages
    ? styles.mainOpenPercentages
    : styles.mainOpen;

  return (
    <div
      className={[styles.wrapper, sideNavOpen ? styles.wrapperOpen : ""].join(
        " "
      )}
    >
      {aside && sideNavOpen && <Overlay onClick={() => toggleSideNav(false)} />}
      {aside && (
        <div className={sideNavOpen ? styles.asideOpen : styles.aside}>
          {aside(sideNavOpen)}
        </div>
      )}
      <div
        className={[styles.main, sideNavOpen ? mainOpenClass : ""].join(" ")}
      >
        {top && top(() => toggleSideNav(!sideNavOpen))}
        <div className={styles.inner}>{children}</div>
        {bottom && bottom}
      </div>
    </div>
  );
};

const useWindowWidth = callback => {
  const handleResize = () => callback(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
};

export default MainLayout;

import React from 'react';
import {ListCheckbox, List, ListItem, Button} from "../../ListItem/ListItem";
import BellRingIcon from "mdi-react/BellRingIcon";
import BellIcon from "mdi-react/BellIcon";
import styles from "./Reminder.module.scss";


const Reminder = ({reminder, setReminder, editMode = null, disabled, t}) => {
  return (
    <div className={styles.border} style={{marginTop: 25}}>
      <ListCheckbox
        type={"checkbox"}
        checked={reminder}
        disabled={editMode != null ? !editMode : false}
        onChange={() => setReminder()}
      >
        {t("Eventcalendar.EventReminder")}

        {reminder ?
          <BellRingIcon className={styles.bellRingIcon}/>
           :
          <BellIcon className={styles.bellRingIcon}/>
        }
        <p className={styles.reminderText}>{t("Eventcalendar.PreviousDay")}</p>
      </ListCheckbox>
      </div>

  )
}

export default Reminder

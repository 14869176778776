import React from "react";

import styles from "./LoadMoreButton.module.scss";
import Loader from "../../Loader/Loader";

const LoadMoreButton = ({ search, text, loadingText, loading, onClick }) => {
  let buttonClassNames = styles.button;
  if (search) buttonClassNames += ` ${styles.search}`;

  if (loading) {
    return (
      <div className={styles.container}>
        <div className={styles.loading}>
          <Loader inline text={loadingText}></Loader>
        </div>
      </div>
    );
  } else {
    return (
      <div className={search ? "" : styles.container}>
        <button className={buttonClassNames} onClick={onClick}>
          {text}
        </button>
      </div>
    );
  }
};
export default LoadMoreButton;

import React, { useEffect, useState } from "react";
import { Query, Mutation, withApollo } from "react-apollo";
import ErrorBoundary from "../../ErrorBoundary/ErrorBoundary";
import styles from './Receivers.module.scss'
import MessageReceivers from "../../Messages/MessageReceivers";

import {
  GET_GLOBAL_FILTERS, GET_DAYCARE_CENTER, GET_DAYCARE_GROUPS
} from "../../../queries";

const Receivers = ({editMode, formMode, setReceivers, receivers, selectedDaycareCenter, t, daycareData, parentsCanAttend}) => {
  let values = {receivers: [], replyable: false, subject: ""}


  const setReceiversHandler = (receivers) => {
    /*
    if(receivers){
      let idArray = []

      receivers.forEach(receiver => {
        if(receiver.type === "DAYCARE_GROUP"){
          idArray.push(receiver.value)
        } else {
          idArray.push("DAYCARE")
        }
      })
      setReceivers(idArray)

    }
    */
    setReceivers(receivers)
  }

  return(
    <ErrorBoundary>
      <Query query={GET_GLOBAL_FILTERS}>
        {({data, loading, error}) => {

          if (loading) return null
          if (error) return null
          const { selectedDaycareCenter, selectedDaycareGroup, currentWorker } = data;
          let sender = null;
          let resultFilters = ['DAYCARE_GROUP', 'DAYCARE_CENTER']

          sender = { id: selectedDaycareCenter, messagerType: "DAYCARE_CENTER" };
          if(formMode){
            if(daycareData !== false){
              return(
                  <div className={styles.border}>
                    <MessageReceivers
                      values={values}
                      sender={sender}
                      daycareData={daycareData}
                      populatedReceivers={receivers}
                      setFieldValue={(tag, receivers) => setReceiversHandler(receivers)}
                      resultFilters={resultFilters}
                    />
                  </div>
              )
            } else {
              return(
                  <div className={styles.border}>
                    <MessageReceivers
                      values={values}
                      sender={sender}
                      setFieldValue={(tag, receivers) => setReceiversHandler(receivers)}
                      resultFilters={resultFilters}
                    />
                  </div>
              )
            }

          }
          else{
            return(
               <div className={styles.infoItemGrouped}>
                 <p className={styles.grayText}  style={{flex: 1, minWidth: 120}}>{t("Eventcalendar.Participants")}</p>
                 {editMode ?
                   (<div className={styles.borderLeft} style={{flex: 5}}>
                     <MessageReceivers
                       daycareData={daycareData}
                       populatedReceivers={receivers}
                       values={values}
                       sender={sender}
                       setFieldValue={(tag, receivers) => setReceiversHandler(receivers)}
                       resultFilters={resultFilters}
                     />
                   </div>)
                 :
                  (
                    <Query query={GET_DAYCARE_GROUPS} variables={{id: selectedDaycareCenter}}>
                      {({data, loading, error}) => {
                        if(loading) return null
                        if(error) return null
                        if(data){
                          let string = ""

                            let groups = data.daycareCenter.daycareGroups
                            let groupNames = []
                            let allGroupsInvited = data.daycareCenter.daycareGroups.length == receivers.length

                            let relatedGroups = groups.filter((group) => {
                              return receivers.some(receiver => receiver.id === group.id)
                            })

                            if(allGroupsInvited || relatedGroups.length === 0){
                              return (
                                <p className={styles.descriptionText} style={{flex: 5}}>
                                  {data.daycareCenter.name}
                                </p>
                              )
                            } else {
                              //if related groups is [], then event is for whole daycare


                              if(relatedGroups.length > 0){
                                relatedGroups.forEach((group, i)=> {
                                    if(i === relatedGroups.length - 1){
                                      string = string + group.name
                                    } else {
                                      string = string + group.name + ", "
                                    }
                                })
                              }

                            if(parentsCanAttend){
                              string = string + ", " + t("Eventcalendar.GuardiansCanAttend")
                            }

                            return(
                              <p className={styles.descriptionText} style={{flex: 5}}>
                                {string.length === 0 ? data.daycareCenter.name : string}
                              </p>
                            )
                            }

                        }
                      }}
                    </Query>
                  )
               }
               </div>
             )
          }
        }}

      </Query>
    </ErrorBoundary>
  )
}

export default Receivers

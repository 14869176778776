import React from "react";
import { NavLink } from "react-router-dom";

const Link = ({
  to = {},
  getSearch,
  pathname,
  className,
  activeClassName,
  children,
  staticContext,
  ...props
}) => {
  if (pathname) {
    to.pathname = pathname;
  }

  if (
    getSearch &&
    (!to.search ||
      to.search.length === 0 ||
      to.search !== window.location.search)
  ) {
    to.search = window.location.search;
  }

  return (
    <NavLink
      className={className}
      activeClassName={activeClassName}
      to={to}
      {...props}
    >
      {children}
    </NavLink>
  );
};

export default Link;
